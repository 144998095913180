import React, { Component } from 'react'

export default class List extends Component {
  render() {
    console.log(this.props)
    return (
        this.props.allFiles.sort((a, b) => this.props.isSortEnable && a.title > b.title ? 1 : -1).map((child, index) => {
            console.log(this.props.isSortEnable)
            let fileData = {
                title: child.title,
                filepath: child.path.replace("C:/htdocs/Lawcrux/Lawcrux/Web/", ''),
                userid: this.props.userid
            }
            return (
                <div className="fileRow" style={{ marginBottom: index == this.props.allFiles.length - 1 ? '30px' : "0px", backgroundColor: this.props.selectedFile == child.path ? 'rgba(25, 118, 210, 0.08)' : "transparent", borderBottom: "1px solid rgba(0,0,0,0.1)" }} onClick={() => this.props.storeHistory(fileData)}>
                    {/* <span className="foundCount">{child.foundCount}</span> */}
                    <div className="fileName">{child.title}</div>
                </div>
            )
        })
    )
  }
}
