import React, { Component } from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Hexapi from "../../usefulcomps/HexAPI/Hexapi";
import "../style.css";
import { Spinner, Modal } from "react-bootstrap";
import CaselawExclamation from "./CaselawExclamation2";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOutlinedIcon from "@mui/icons-material/HighlightOutlined";
import HighlightIcon from "@mui/icons-material/Highlight";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
// import AutoFixOffIcon from '@mui/icons-material/AutoFixOff';
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderRoundedIcon from "@mui/icons-material/BookmarkBorderRounded";
import { Input } from "../../usefulcomps/HexInput/InputBox";
import Hsnsearch from "./Hsnsearch";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import HistoryIcon from "@mui/icons-material/History";
import HistoryKeeping from "./HistoryKeeping";
import NotesIcon from "@mui/icons-material/Notes";
import Notes from "./Notes";
import SummarizeIcon from "@mui/icons-material/Summarize";
import Summary from "./Summary";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Lawasondate from "./Lawasondate";
import PublicIcon from "@mui/icons-material/Public";
import Comparelaws from "./Comparelaws";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Countdown from "react-countdown";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import BookmarkKeeping from "./BookmarkKeeping";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import HomeIcon from "@mui/icons-material/Home";
import List from "./List";
import VisibilitySensor from "react-visibility-sensor";
import Captcha from "../../usefulcomps/Captcha/CaptchaModal";
import IIADVCL from "../../usefulcomps/table/IIADVCL";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import UtilityFrame from "./UtilityFrame";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import PsychologySharpIcon from "@mui/icons-material/PsychologySharp";
import SummarizeSharpIcon from "@mui/icons-material/SummarizeSharp";
import AccountTreeSharpIcon from "@mui/icons-material/AccountTreeSharp";
import CalculateIcon from "@mui/icons-material/Calculate";

// import AbcIcon from '@mui/icons-material/Abc';
// import Marker from './Marker/Marker'
// import Data from './Marker/Data'
// import { rangeToData } from './Marker/Helper'
// import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
// import Highlighter from 'web-highlighter';
import Mark from "mark.js";
import { Aesdecryption, Aesencryption } from "../../usefulcomps/Aes/Aes";
// import File from '../../113113.htm'
import $ from "jquery";
import axios from "axios";
import Parametericsearch from "./ParametericsearchV2";
import Dictionary from "./Dictionary";
import Magicwond from "./Magicwond";
import Swal from "sweetalert2";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness4OutlinedIcon from "@mui/icons-material/Brightness4Outlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PrintIcon from "@mui/icons-material/Print";
import Hexcountdown from "./Hexcountdown";
import Notificationtable from "../../usefulcomps/table/Notificationtable";
import Circular from "../../usefulcomps/table/Circular";
import { FamilyRestroomTwoTone } from "@mui/icons-material";
import ContentFrame from "./ContentFrame2";
import { fabClasses } from "@mui/material";

var markInstance = "";
var mappedFiles = [];

// let Range {commonAncestorContainer: div#customIframe, startContainer: text, startOffset: 1, endContainer: text, endOffset: 22, …}
const min = (a, b) => {
  if (a < b) {
    return a;
  }
  return b;
};
const max = (a, b) => {
  if (a > b) {
    return a;
  }
  return b;
};

const rename = (name) => {
  let arr = [
    { key: "bu", replace: "Budget" },
    { key: "sp", replace: "specimen" },
    { key: "ce", replace: "central excise" },
    { key: "cl", replace: "case law" },
    { key: "cu", replace: "custom" },
    { key: "di", replace: "dictionary" },
    { key: "fl", replace: "flowchart" },
    { key: "ft", replace: "foreign trade policy" },
    { key: "gt", replace: "GST" },
    { key: "st", replace: "service tax" },
    { key: "sz", replace: "SEZ" },
    { key: "rb", replace: "Fema" },
    { key: "hn", replace: "Headnote (Summary of Case Law)" },
  ];
  let newName = -1;
  arr.forEach((list) => {
    if (list.key == name) {
      newName = list.replace;
    }
  });
  if (newName != -1) {
    return newName;
  } else {
    return name;
  }
};

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

export default class Searched extends Component {
  constructor(props) {
    super(props);
    this.history = [];
    this.currentPagePosition = 0;
    this.state = {
      ParametericsearchUrl: sessionStorage.getItem("paramsearchurl"),
      Combinesearchurl: sessionStorage.getItem("combinesearchurl"),
      Refinedsearchurl: sessionStorage.getItem("refinedsearchurl"),
      firstTimeSearchedData: [],
      targetField: "",
      targetSubField: "",
      newTreeData: [],
      notificationtype: "",
      circulartype: "",
      notificationfield: "",
      openInitialModal: false,
      allData: [],
      treeData: [],
      selectedFile: "",
      allcodeno: "",
      search: "",
      isApiHitComplete: false,
      isDataFound: false,
      isFirstSearchComplete: false,
      highlight: "",
      searchType: "2",
      totalFilesCount: 0,
      firstTimeClick: true,
      ReadWith: "Read with current law",
      isFetchFileApiHitComplete: false,
      foldpath: "",
      isOpenCustomFrame: false,
      htmlFileShowContent: "",
      htmlFileOriginalContent: "",
      filetype: "adv",
      circular: false,
      subFolder: "",
      isBookmarkAdded: false,
      selectedFileDetails: [],
      isBookmarkAddComplete: true,
      targetFile: "",
      mainFolder: "",
      savedSelection: null,
      allStarts: "",
      allEnds: "",
      highlightColor: "lightgreen",
      startEndArray: [],
      htmlFileShowContentBackup: null,
      isShowRightBox: false,
      isShowLeftBox: true,
      allFiles: [],
      isOpenHSNSearch: false,
      isOpenParamSearch: false,
      isOpenDictModal: false,
      isOpenHistoryModal: false,
      isHistoryOpen: false,
      isOpenNotesModal: false,
      usernotes: "",
      paramSearchObj: null,
      circNotiObj: null,
      isOnlyParamSearch: false,
      summaryData: null,
      isRefinedSearch: false,
      isOpenLawAsOnDateModal: false,
      isOpenCompareLaws: false,
      isMagicWondOpen: false,
      isFileTitleFetched: true,
      isFileTitleFound: false,
      searchPath: "Assets/Data5t/",
      isDarkMode: false,
      zoomFrame: 1,
      isTimerModal: false,
      isOpenBookmarkModal: false,
      selectedJudge: "",
      isOpenInIframe: false,
      isOpenInHouseDevelopment: false,
      isSortEnable: true,
      isIframeDarkMode: false,
      prevnodeid: "",
      curnodeid: "",
      isUpdation: false,
      showdisplayicons: true,
      searchtypeValue: [],
      showHighlightttext: false,
      internalsearch: [],
      internalfilepath: [],
      searchcount: 0,
      targetfieldarray: [],
      targetsubfieldarray: [],
      firstsearchresult: [],
      setsearchcount: 0,
      arrowsearch: false,
      openutilities: false,
      utilityOpen: false,
      // Online state
      isOnline: navigator.onLine,
      isHistoryTrackBtn: false,
      caseLawAdvObj: null,
      selectedType: null,
      advorcl: false,
      listOfAllFilePaths: [],
      isLastList: false,
      titlesLength: 0,
      skipCount: 0,
      selectedField: null,
      selectedSubField: null,
      searchCategories: [
        {
          value: "2",
          label: "Phrase Search",
          tooltip:
            "Phrase : This selection brings you the files in which the searched keywords are found AS IT IS.To illustrate, if you search 'input tax credit' as a phrase, then you will find all the files which contain the phrase 'input tax credit'.",
        },
        {
          value: "1",
          label: "All Word Search",
          tooltip:
            "All Word: Use this preference to find those files in which ALL OF THE searched keywords are present but not particularly in that order.To illustrate, if you search 'ITC disallowed motor vehicle ' will bring you the files where all these words are contained anywhere in the file. Therefore it can be used to find the law which relates to disallowance of ITC on motor vehicles",
        },
        {
          value: "3",
          label: "Any Word Search",
          tooltip:
            "Any word : This mode fetches those files in which ANY ONE OF THE  multiple searched keywords.To illustrate, if you have to search ITC which might be restricted you may search 'restricted blocked disallowed' , this will bring you files that contain either the word 'restricted' or 'blocked ' or 'disallowed",
        },
      ],
      pageData: [],
    };
    this.createTree = this.createTree.bind(this);
    this.searchKeyword = this.searchKeyword.bind(this);
    this.storeSearch = this.storeSearch.bind(this);
    this.setLeftBoxTree = this.setLeftBoxTree.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.getSelectedText = this.getSelectedText.bind(this);
    this.removeSelectedText = this.removeSelectedText.bind(this);
    this.createIframe = this.createIframe.bind(this);
    this.getpagedata = this.getpagedata.bind(this);
    this.storeHistory = this.storeHistory.bind(this);
    this.xWithRef = this.xWithRef.bind(this);
    this.xWithoutRef = this.xWithoutRef.bind(this);
    this.removeBookmark = this.removeBookmark.bind(this);
    this.autoHighlight = this.autoHighlight.bind(this);
    this.addBookmark = this.addBookmark.bind(this);
    this.checkClash = this.checkClash.bind(this);
    this.correctHighlight = this.correctHighlight.bind(this);
    this.setRightBoxContent = this.setRightBoxContent.bind(this);
    this.openHistoryFile = this.openHistoryFile.bind(this);
    this.setLegalDictLinks = this.setLegalDictLinks.bind(this);
  }

  trackHistory(filepath) {
    this.history.push(filepath);
    this.currentPagePosition++;
  }

  goBackward_CustomHistory() {
    console.log(this.currentPagePosition);
    if (this.currentPagePosition > 1) {
      console.log(this.currentPagePosition, this.history);
      this.currentPagePosition--;
      let fpath = this.history[this.currentPagePosition - 1];
      console.log("==========================================");
      console.log(this.currentPagePosition, this.history);
      console.log("==========================================");

      let url = sessionStorage.getItem("url") + "fetchfile.php";
      this.setState({ isHistoryTrackBtn: true });
      var fd = new FormData();
      fd.append(Aesencryption("filename"), Aesencryption(fpath));
      axios({
        mode: "cors",
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        url: `${url}`,
        data: fd,
      }).then((resp) => {
        let respData = resp.data;
        // if (respData.toLowerCase().includes('<object')) {
        //     respData = respData.replaceAll(`data="../../`, `data="${sessionStorage.getItem('url')}Assets/Data5t/`)
        // }

        let pageTitle = respData.substring(
          respData.indexOf("<title>") + 7,
          respData.indexOf("</title>")
        );

        const obj = {
          filepath: fpath,
          description: pageTitle,
          userid: this.state.selectedFileDetails.userid,
        };
        console.log(obj);
        // this.setState({ search: '', totalFilesCount: 0, allFiles: [], isFirstSearchComplete: true, isApiHitComplete: true, isDataFound: true, isOpenHistoryModal: false, isOpenBookmarkModal: false, isHistoryOpen: true })
        this.createIframe(obj);
      });
    }
  }

  goForward_CustomHistory() {
    console.log(this.currentPagePosition);
    if (this.currentPagePosition < this.history.length) {
      let fpath = this.history[this.currentPagePosition];
      this.currentPagePosition++;
      console.log("==========================================");
      console.log(this.currentPagePosition, this.history);
      console.log("==========================================");

      let url = sessionStorage.getItem("url") + "fetchfile.php";
      this.setState({ isHistoryTrackBtn: true });
      var fd = new FormData();
      fd.append(Aesencryption("filename"), Aesencryption(fpath));
      axios({
        mode: "cors",
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        url: `${url}`,
        data: fd,
      }).then((resp) => {
        let respData = resp.data;
        // if (respData.toLowerCase().includes('<object')) {
        //     respData = respData.replaceAll(`data="../../`, `data="${sessionStorage.getItem('url')}Assets/Data5t/`)
        // }

        let pageTitle = respData.substring(
          respData.indexOf("<title>") + 7,
          respData.indexOf("</title>")
        );

        const obj = {
          filepath: fpath,
          description: pageTitle,
          userid: this.state.selectedFileDetails.userid,
        };
        console.log(obj);
        // this.setState({ search: '', totalFilesCount: 0, allFiles: [], isFirstSearchComplete: true, isApiHitComplete: true, isDataFound: true, isOpenHistoryModal: false, isOpenBookmarkModal: false, isHistoryOpen: true })
        this.createIframe(obj);
      });
    }
  }

  checkClash(obj1, obj2) {
    // console.log(obj1,obj2)
    if (obj1.start <= obj2.start) {
      if (obj1.end >= obj2.end || obj1.end >= obj2.start) {
        console.log("here");
        return true;
      } else {
        return false;
      }
    } else {
      return this.checkClash(obj2, obj1);
    }
  }
  correctHighlight(array) {
    // console.log(array)
    let newArr = array;
    for (let i = 0; i < array.length; i++) {
      // let clash = false
      for (let j = 0; j < array.length; j++) {
        if (i != j && this.checkClash(array[i], array[j])) {
          let newHi = {
            start: min(array[i].start, array[j].start),
            end: max(array[i].end, array[j].end),
          };
          newArr.push(newHi);
          if (i < j) {
            newArr.splice(j, 1);
            newArr.splice(i, 1);
          } else {
            newArr.splice(i, 1);
            newArr.splice(j, 1);
          }

          newArr = this.correctHighlight(newArr);
          // console.log(newArr)
          return newArr;
        }
      }
    }

    return newArr;
  }

  createTree(input) {
    console.log(input);
    // // utility function
    // const call = (fn, ...args) =>
    //     fn(...args)

    // // main functions
    // const nest = (files) => files.reduce((tree, file) => {
    //     file.filepath.split('/').reduce((node, name, i, a) => {
    //         return (node[name] || (node[name] = {
    //             name,
    //             path: i == a.length - 1 ? file.filepath : null,
    //             title: i == a.length - 1 ? file.fileTitle : null,
    //             foundCount: i == a.length - 1 ? file.count : null,
    //             type: i == a.length - 1 ? 'file' : 'folder',
    //             children: {}
    //         })).children
    //     }, tree)
    //     return tree
    // }, {})

    // const flattenChildren = (tree) =>
    //     Object.values(tree)
    //         .map(({ children, ...rest }) => call(
    //             (kids) => ({
    //                 ...rest,
    //                 ... (kids.length ? { children: kids } : {})
    //             }),
    //             children ? flattenChildren(children) : []
    //         ))

    // // public function
    // return flattenChildren(nest(files))
    var newtree = [
      {
        nodeid: "1",
        name: "GST",
        children: [
          {
            nodeid: "1.1",
            name: "CGST",
            children: [
              {
                nodeid: "1.1.1",
                name: "Act",
                subfolder: "gtcgst",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.1.2",
                name: "Rules",
                subfolder: "gtstrl",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.1.3",
                name: "Notification(Non-Rate)",
                subfolder: "ctntnr",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.1.4",
                name: "Notification(Rate)",
                subfolder: "ctntra",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.1.5",
                name: "Circular",
                subfolder: "ctcirc",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.1.6",
                name: "Instructions",
                subfolder: "gtcgcu",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.1.7",
                name: "Guidelines",
                subfolder: "gtcggu",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.1.8",
                name: "Order",
                subfolder: "ctordr",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.1.9",
                name: "ROD",
                subfolder: "ctorod",
                mainfolder: "gt",
                children: [],
              },
              // {
              //     nodeid: '1.1.8',
              //     name: 'Order',
              //     children: [
              //         {
              //             nodeid: '1.1.8.1',
              //             name: 'Order',
              //             subfolder: 'ctordr',
              //             mainfolder: 'gt',
              //             children: []
              //         },
              //         {
              //             nodeid: '1.1.8.2',
              //             name: 'ROD',
              //             subfolder: 'ctorod',
              //             mainfolder: 'gt',
              //             children: []
              //         },
              //     ]
              // },
            ],
          },
          {
            nodeid: "1.2",
            name: "IGST",
            children: [
              {
                nodeid: "1.2.1",
                name: "Act",
                subfolder: "gtigst",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.2.2",
                name: "Rules",
                subfolder: "gtistr",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.2.3",
                name: "Notification(Non-Rate)",
                subfolder: "igntnr",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.2.4",
                name: "Notification(Rate)",
                subfolder: "igntra",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.2.5",
                name: "Circular",
                subfolder: "gtigcu",
                mainfolder: "gt",
                children: [],
              },
            ],
          },
          // {
          //     nodeid: '1.3',
          //     name: 'SGST',
          //     children: [
          //         {
          //             nodeid: '1.3.1',
          //             name: 'Act',
          //             children: []
          //         },
          //         {
          //             nodeid: '1.3.2',
          //             name: 'Rules',
          //             children: []
          //         },
          //         {
          //             nodeid: '1.3.3',
          //             name: 'Notification(Non-Rate)',
          //             children: []
          //         },
          //         {
          //             nodeid: '1.3.4',
          //             name: 'Notification(Rate)',
          //             children: []
          //         },
          //         {
          //             nodeid: '1.3.5',
          //             name: 'Circular',
          //             children: []
          //         },
          //         {
          //             nodeid: '1.3.6',
          //             name: 'Order',
          //             children: [
          //                 {
          //                     nodeid: '1.3.6.1',
          //                     name: 'Order',
          //                     children: []
          //                 },
          //                 {
          //                     nodeid: '1.3.6.2',
          //                     name: 'ROD',
          //                     children: []
          //                 },
          //             ]
          //         },
          //     ]
          // },
          {
            nodeid: "1.4",
            name: "UGST",
            children: [
              {
                nodeid: "1.4.1",
                name: "Act",
                subfolder: "gtugst",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.4.2",
                name: "Rules",
                subfolder: "gtugru",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.4.3",
                name: "Notification(Non-Rate)",
                subfolder: "ugntnr",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.4.4",
                name: "Notification(Rate)",
                subfolder: "ugntra",
                mainfolder: "gt",
                children: [],
              },
              // {
              //     nodeid: '1.4.5',
              //     name: 'Circular',
              //     subfolder: 'gtnoti',
              //     mainfolder: 'gt',
              //     children: []
              // },
              // {
              //     nodeid: '1.4.5',
              //     name: 'Order',
              //     children: [
              //         // {
              //         //     nodeid: '1.4.5.1',
              //         //     name: 'Order',
              //         //     subfolder: 'gtnoti',
              //         //     mainfolder: 'gt',
              //         //     children: []
              //         // },
              //         {
              //             nodeid: '1.4.5.1',
              //             name: 'ROD',
              //             subfolder: 'gtnoti',
              //             mainfolder: 'gt',
              //             children: []
              //         },
              //     ]
              // },
              {
                nodeid: "1.4.5",
                name: "Order/ROD",
                subfolder: "ugordr",
                mainfolder: "gt",
                children: [],
              },
            ],
          },
          {
            nodeid: "1.5",
            name: "GST(Compensation to the States) Act",
            children: [
              {
                nodeid: "1.5.1",
                name: "Act",
                subfolder: "gtcomp",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.5.2",
                name: "Rules",
                subfolder: "gtcoru",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.5.3",
                name: "Notification(Non-Rate)",
                subfolder: "compnr",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.5.4",
                name: "Notification(Rate)",
                subfolder: "compra",
                mainfolder: "gt",
                children: [],
              },
              {
                nodeid: "1.5.5",
                name: "Circular",
                subfolder: "gtcoci",
                mainfolder: "gt",
                children: [],
              },
            ],
          },
          {
            nodeid: "1.6",
            name: "Press Release",
            subfolder: "gtpres",
            mainfolder: "gt",
            children: [],
          },
          {
            nodeid: "1.7",
            name: "Portal Related Updates",
            subfolder: "gstpru",
            mainfolder: "gt",
            children: [],
          },
          // {
          //     nodeid: '1.8',
          //     name: 'GST News',
          //     children: []
          // },
          {
            nodeid: "1.8",
            name: "Council Meeting Updates",
            subfolder: "gtport",
            mainfolder: "gt",
            children: [],
          },
          {
            nodeid: "1.9",
            name: "Form",
            subfolder: "gtcfrm",
            mainfolder: "gt",
            children: [],
          },
          // {
          //     nodeid: '1.10',
          //     name: 'Case Laws',
          //     children: []
          // },
          // {
          //     nodeid: '1.11',
          //     name: 'Advance Ruling',
          //     children: []
          // },
          {
            nodeid: "1.12",
            name: "CBIC FAQ's",
            subfolder: "gtfaqb",
            mainfolder: "gt",
            children: [],
          },
        ],
      },
      {
        nodeid: "2",
        name: "Custom",
        children: [
          {
            nodeid: "2.1",
            name: "Act",
            subfolder: "cuacts",
            mainfolder: "cu",
            children: [],
          },
          {
            nodeid: "2.2",
            name: "Rules",
            subfolder: "curule",
            mainfolder: "cu",
            children: [],
          },
          {
            nodeid: "2.3",
            name: "Circular/Instructions/Guidelines",
            subfolder: "cuncuc",
            mainfolder: "cu",
            children: [],
          },
          {
            nodeid: "2.4",
            name: "Notification",
            children: [
              {
                nodeid: "2.3.1",
                name: "Notification(Tariff)",
                children: [
                  {
                    nodeid: "2.3.1.1",
                    name: "ADD",
                    subfolder: "cuncuv",
                    mainfolder: "cu",
                    children: [],
                  },
                  {
                    nodeid: "2.3.1.2",
                    name: "Safeguard",
                    subfolder: "cuncuv",
                    mainfolder: "cu",
                    children: [],
                  },
                  {
                    nodeid: "2.3.1.3",
                    name: "CVD",
                    subfolder: "cuncuv",
                    mainfolder: "cu",
                    children: [],
                  },
                  {
                    nodeid: "2.3.1.4",
                    name: "Others",
                    subfolder: "cuncuv",
                    mainfolder: "cu",
                    children: [],
                  },
                ],
              },
              {
                nodeid: "2.3.2",
                name: "Notification(Non-Tariff)",
                subfolder: "cuncuv",
                mainfolder: "cu",
                children: [],
              },
            ],
          },
          {
            nodeid: "2.5",
            name: "Regulations",
            subfolder: "curegu",
            mainfolder: "cu",
            children: [],
          },
          {
            nodeid: "2.6",
            name: "Manual",
            subfolder: "cumanu",
            mainfolder: "cu",
            children: [],
          },
          {
            nodeid: "2.7",
            name: "Form",
            subfolder: "cuform",
            mainfolder: "cu",
            children: [],
          },
          // {
          //     nodeid: '2.8',
          //     name: 'Case Laws',
          //     children: []
          // },
          {
            nodeid: "2.9",
            name: "Tariff",
            subfolder: "cutar2",
            mainfolder: "cu",
            children: [],
          },
          // {
          //     nodeid: '2.10',
          //     name: 'Advance Ruling',
          //     children: []
          // },
          {
            nodeid: "2.11",
            name: "CBIC FAQ's",
            subfolder: "cufaqb",
            mainfolder: "cu",
            children: [],
          },
        ],
      },
      {
        nodeid: "3",
        name: "Excise",
        children: [
          {
            nodeid: "3.1",
            name: "Act",
            subfolder: "ceacts",
            mainfolder: "ce",
            children: [],
          },
          {
            nodeid: "3.2",
            name: "Rules",
            subfolder: "cerule",
            mainfolder: "ce",
            children: [],
          },
          {
            nodeid: "3.3",
            name: "Circular/Instruction/Guidelines",
            subfolder: "cencec",
            mainfolder: "ce",
            children: [],
          },
          {
            nodeid: "3.4",
            name: "Notification",
            children: [
              {
                nodeid: "3.4.1",
                name: "Notification(Tariff)",
                subfolder: "cencev",
                mainfolder: "ce",
                children: [],
              },
              {
                nodeid: "3.4.2",
                name: "Notification(Non-Tariff)",
                subfolder: "cencev",
                mainfolder: "ce",
                children: [],
              },
            ],
          },
          {
            nodeid: "3.6",
            name: "Manual",
            subfolder: "cemanu",
            mainfolder: "ce",
            children: [],
          },
          {
            nodeid: "3.7",
            name: "Form",
            subfolder: "ceform",
            mainfolder: "ce",
            children: [],
          },
          {
            nodeid: "3.8",
            name: "Tariff",
            subfolder: "cetari",
            mainfolder: "ce",
            children: [],
          },
          // {
          //     nodeid: '3.9',
          //     name: 'Case Laws',
          //     children: []
          // },
        ],
      },
      {
        nodeid: "4",
        name: "Service Tax",
        children: [
          {
            nodeid: "4.1",
            name: "Act",
            subfolder: "stacts",
            mainfolder: "st",
            children: [],
          },
          {
            nodeid: "4.2",
            name: "Rules",
            subfolder: "strule",
            mainfolder: "st",
            children: [],
          },
          {
            nodeid: "4.3",
            name: "Circular/Instruction/Guidelines",
            subfolder: "stncsc",
            mainfolder: "st",
            children: [],
          },
          {
            nodeid: "4.4",
            name: "Notification",
            subfolder: "stncsv",
            mainfolder: "st",
            children: [],
          },
          {
            nodeid: "4.5",
            name: "Scheme",
            subfolder: "stsche",
            mainfolder: "st",
            children: [],
          },
          // {
          //     nodeid: '4.6',
          //     name: 'Case Laws',
          //     children: []
          // },
          {
            nodeid: "4.7",
            name: "Forms",
            subfolder: "stform",
            mainfolder: "st",
            children: [],
          },
        ],
      },
      {
        nodeid: "5",
        name: "FTP",
        children: [
          {
            nodeid: "5.1",
            name: "Act",
            subfolder: "ftacts",
            mainfolder: "ft",
            children: [],
          },
          {
            nodeid: "5.2",
            name: "Rules",
            subfolder: "ftrule",
            mainfolder: "ft",
            children: [],
          },
          {
            nodeid: "5.3",
            name: "Circular",
            subfolder: "ftcirc",
            mainfolder: "ft",
            children: [],
          },
          {
            nodeid: "5.4",
            name: "Notification",
            subfolder: "ftnoti",
            mainfolder: "ft",
            children: [],
          },
          {
            nodeid: "5.5",
            name: "Appendix",
            subfolder: "ftappe",
            mainfolder: "ft",
            children: [],
          },
          {
            nodeid: "5.6",
            name: "ITC (HS)",
            subfolder: "ftitch",
            mainfolder: "ft",
            children: [],
          },
          {
            nodeid: "5.7",
            name: "DEPB DrawBack",
            subfolder: "ftrate",
            mainfolder: "ft",
            children: [],
          },
          {
            nodeid: "5.8",
            name: "Policy",
            subfolder: "ftpoli",
            mainfolder: "ft",
            children: [],
          },
          {
            nodeid: "5.9",
            name: "Trade Notice",
            subfolder: "fttrad",
            mainfolder: "ft",
            children: [],
          },
          {
            nodeid: "5.10",
            name: "Public Notice",
            subfolder: "ftpubl",
            mainfolder: "ft",
            children: [],
          },
          {
            nodeid: "5.11",
            name: "HB Procedure",
            subfolder: "ftproc",
            mainfolder: "ft",
            children: [],
          },
          {
            nodeid: "5.12",
            name: "SION",
            subfolder: "ftsion",
            mainfolder: "ft",
            children: [],
          },
          {
            nodeid: "5.13",
            name: "DGFT FAQ's",
            subfolder: "ftfaqb",
            mainfolder: "ft",
            children: [],
          },
          // {
          //     nodeid: '5.14',
          //     name: "Case Laws",
          //     children: []
          // },
        ],
      },
      {
        nodeid: "6",
        name: "FEMA",
        children: [
          {
            nodeid: "6.1",
            name: "Act",
            subfolder: "rbacts",
            mainfolder: "rb",
            children: [],
          },
          {
            nodeid: "6.2",
            name: "Rules",
            subfolder: "rbrule",
            mainfolder: "rb",
            children: [],
          },
          {
            nodeid: "6.3",
            name: "Circular",
            subfolder: "rbcirc",
            mainfolder: "rb",
            children: [],
          },
          {
            nodeid: "6.4",
            name: "Notification",
            subfolder: "rbnoti",
            mainfolder: "rb",
            children: [],
          },
          {
            nodeid: "6.5",
            name: "Regulations",
            subfolder: "rbregu",
            mainfolder: "rb",
            children: [],
          },
          {
            nodeid: "6.6",
            name: "Citizen Charter",
            subfolder: "rbciti",
            mainfolder: "rb",
            children: [],
          },
          {
            nodeid: "6.7",
            name: "Form",
            subfolder: "rbform",
            mainfolder: "rb",
            children: [],
          },
          {
            nodeid: "6.8",
            name: "RBI FAQ",
            subfolder: "rbfaqb",
            mainfolder: "ft",
            children: [],
          },
        ],
      },
      {
        nodeid: "7",
        name: "SEZ",
        children: [
          {
            nodeid: "7.1",
            name: "Act",
            subfolder: "szacts",
            mainfolder: "sz",
            children: [],
          },
          {
            nodeid: "7.2",
            name: "Rules",
            subfolder: "szrule",
            mainfolder: "sz",
            children: [],
          },
          {
            nodeid: "7.3",
            name: "Policy",
            subfolder: "szpoli",
            mainfolder: "sz",
            children: [],
          },
          {
            nodeid: "7.4",
            name: "Appendix",
            subfolder: "szappe",
            mainfolder: "sz",
            children: [],
          },
          // {
          //     nodeid: '7.5',
          //     name: 'Fema on SEZ',
          //     subfolder: 'szfema',
          //     mainfolder: 'sz',
          //     children: []
          // },
          {
            nodeid: "7.6",
            name: "Notification",
            subfolder: "sznoti",
            mainfolder: "sz",
            children: [],
          },
          {
            nodeid: "7.7",
            name: "Circular",
            subfolder: "szcirc",
            mainfolder: "sz",
            children: [],
          },
        ],
      },
      // {
      //     nodeid: '8',
      //     name: 'EOU',
      //     children: []
      // },
      {
        nodeid: "9",
        name: "Budget",
        children: [],
      },
      // {
      //     nodeid: '10',
      //     name: 'Question Bank',
      //     children: [
      //         {
      //             nodeid: '10.1',
      //             name: 'CBIC FAQ',
      //             subfolder: 'gtfaqb',
      //             mainfolder: 'gt',
      //             children: []
      //         },
      //         {
      //             nodeid: '10.2',
      //             name: 'Ask-A-Question',
      //             subfolder: 'gtillu',
      //             mainfolder: 'gt',
      //             children: []
      //         },
      //     ]
      // },
      {
        nodeid: "10",
        name: "Ask-a-Question",
        children: [],
      },
      {
        nodeid: "11",
        name: "Allied Act",
        children: [],
      },
      {
        nodeid: "12",
        name: "Case Law",
        children: [
          // {
          //     nodeid: '12.1',
          //     name: 'Supreme Court',
          //     subfolder: 'clsupr',
          //     mainfolder: 'cl',
          //     children: []
          // },
          // {
          //     nodeid: '12.2',
          //     name: 'High Court',
          //     subfolder: 'clhigh',
          //     mainfolder: 'cl',
          //     children: []
          // },
          // {
          //     nodeid: '12.3',
          //     name: 'Tribunal',
          //     children: [
          //         {
          //             nodeid: '12.3.1',
          //             name: 'Bangalore',
          //             subfolder: 'cltban',
          //             mainfolder: 'cl',
          //             children: []
          //         },
          //         {
          //             nodeid: '12.3.2',
          //             name: 'Chennai',
          //             subfolder: 'cltche',
          //             mainfolder: 'cl',
          //             children: []
          //         },
          //         {
          //             nodeid: '12.3.3',
          //             name: 'Delhi',
          //             subfolder: 'cltdel',
          //             mainfolder: 'cl',
          //             children: []
          //         },
          //         {
          //             nodeid: '12.3.4',
          //             name: 'Kolkata',
          //             subfolder: 'cltkol',
          //             mainfolder: 'cl',
          //             children: []
          //         },
          //         {
          //             nodeid: '12.3.5',
          //             name: 'Mumbai',
          //             subfolder: 'cltmum',
          //             mainfolder: 'cl',
          //             children: []
          //         },
          //         {
          //             nodeid: '12.3.6',
          //             name: 'Hyderabad',
          //             subfolder: 'clthyd',
          //             mainfolder: 'cl',
          //             children: []
          //         },
          //         // {
          //         //     nodeid: '12.10',
          //         //     name: 'Ahmedabad Tribunal',
          //         //     subfolder: 'cltahm',
          //         //     mainfolder: 'cl',
          //         //     children: []
          //         // },
          //         // {
          //         //     nodeid: '12.11',
          //         //     name: 'Tribunal Others',
          //         //     subfolder: 'cltrib',
          //         //     mainfolder: 'cl',
          //         //     children: []
          //         // },
          //         {
          //             nodeid: '12.3.7',
          //             name: 'Others',
          //             subfolder: 'cltrib',
          //             mainfolder: 'cl',
          //             children: []
          //         },
          //         {
          //             nodeid: '12.3.8',
          //             name: 'Larger Bench',
          //             subfolder: 'cltlar',
          //             mainfolder: 'cl',
          //             children: []
          //         },
          //     ]
          // },
          // {
          //     nodeid: '12.3',
          //     name: 'Tribunal',
          //     children: []
          // },
          // {
          //     nodeid: '12.4',
          //     name: 'NAPA',
          //     subfolder: 'clnapa',
          //     mainfolder: 'cl',
          //     children: []
          // },
          // {
          //     nodeid: '12.5',
          //     name: 'Others',
          //     subfolder: 'cloths',
          //     mainfolder: 'cl',
          //     children: []
          // }
          // ,
          // {
          //     nodeid: '12.14',
          //     name: 'AAR',
          //     subfolder: 'claarc',
          //     mainfolder: 'cl',
          //     children: []
          // },
          // {
          //     nodeid: '12.15',
          //     name: 'AAAR',
          //     subfolder: 'claaar',
          //     mainfolder: 'cl',
          //     children: []
          // },
        ],
      },
      {
        nodeid: "13",
        name: "Advance Ruling",
        children: [
          // {
          //     nodeid: '13.1',
          //     name: 'AAR',
          //     subfolder: 'claarc',
          //     mainfolder: 'cl',
          //     children: []
          // },
          // {
          //     nodeid: '13.2',
          //     name: 'AAAR',
          //     subfolder: 'claaar',
          //     mainfolder: 'cl',
          //     children: []
          // },
        ],
      },
    ];
    if (sessionStorage.getItem("username").includes("LCX_")) {
      newtree.push({
        nodeid: "16",
        name: "Unmapped Files",
        subfolder: "",
        mainfolder: "",
        children: [],
      });
    }
    if (sessionStorage.getItem("username").includes("LCX_")) {
      newtree.push({
        nodeid: "17",
        name: "NIS Files",
        subfolder: "",
        mainfolder: "",
        children: [],
      });
    }

    var map = new Map();

    let showFiles = [
      "gtcgst",
      "gtstrl",
      "gtigst",
      "gtistr",
      "gtugst",
      "gtcomp",
      "gtnoti",
      "gtcgcu",
      "gtpres",
      "gtport",
      "gtcfrm",
      "gtfaqb",
      "gtillu",
      "gstpru",
      "ctordr",
      "ctorod",
      "cuacts",
      "curule",
      "curegu",
      "cuncuv",
      "szrule",
      "cumanu",
      "cuform",
      ,
      "cutari",
      "cuncuc",
      "cufaqb",
      "cualar",
      "cutar2",
      "ftacts",
      "ftrule",
      "ftcirc",
      "ftnoti",
      "ftappe",
      "ftitch",
      "ftrate",
      "ftpoli",
      "fttrad",
      "ftpubl",
      "ftproc",
      "ftsion",
      "ftfaqb",
      "szacts",
      "szpoli",
      "szappe",
      "szfema",
      "sznoti",
      "szcirc",
      "ctcirc",
      "gtigcu",
      "gtcoci",
      "bu",
      "ctntnr",
      "ctntra",
      "igntra",
      "igntnr",
      "ugntra",
      "ugntnr",
      "compra",
      "compnr",
      "gtcoru",
      "gtugru",
      "ugordr",
      "gtcggu",
      "claaar",
      "claarc",
      "clhigh",
      "clnapa",
      "cloths",
      "clsupr",
      "cltahm",
      "cltban",
      "cltche",
      "cltdel",
      "clthyd",
      "cltkol",
      "cltmum",
      "cltrib",
      "cltlar",
      "cltald",
      "cltchd",
      "cuaarc",
      "ceacts",
      "cerule",
      "cencec",
      "cencev",
      "cemanu",
      "cetari",
      "ceform",
      "rbacts",
      "rbrule",
      "rbcirc",
      "rbnoti",
      "rbregu",
      "rbciti",
      "rbform",
      "rbfaqb",
      "stacts",
      "strule",
      "stncsc",
      "stncsv",
      "stsche",
      "stform",
    ];
    showFiles.forEach((subfolder) => {
      let arr = [];
      input.forEach((el) => {
        // console.log(el)
        if (el.filepath.includes("/" + subfolder + "/")) {
          arr.push(el.filepath);
        }
      });
      map.set(subfolder, arr);
    });
    console.log("=================", map, "=================");
    // function checks remarks of gtnoti search excel files
    // And filter the files that are required
    const checkRemarks = (subfolder, splitArr, escapeChar) => {
      var splitTest = function (str) {
        return str.split("\\").pop().split("/").pop();
      };
      // console.log(escapeChar)
      if (splitArr.length > 0) {
        let arr = [];
        if (escapeChar != undefined) {
          subfolder.forEach((folder) => {
            arr.push(
              ...map
                .get(folder)
                .filter((filepath) =>
                  splitArr.some(
                    (spliter) =>
                      splitTest(filepath)
                        .toLowerCase()
                        .includes(spliter.toLowerCase()) &&
                      !splitTest(filepath)
                        .toLowerCase()
                        .includes(escapeChar.toLowerCase())
                  )
                )
            );
          });
        } else {
          subfolder.forEach((folder) => {
            arr.push(
              ...map
                .get(folder)
                .filter((filepath) =>
                  splitArr.some((spliter) =>
                    splitTest(filepath)
                      .toLowerCase()
                      .includes(spliter.toLowerCase())
                  )
                )
            );
          });
        }
        return arr;
      } else {
        let arr = [];
        if (escapeChar != undefined) {
          subfolder.forEach((folder) => {
            let bigArr = map.get(folder);
            bigArr.forEach((filepath) => {
              if (filepath.toLowerCase().includes(escapeChar.toLowerCase())) {
                arr.push(filepath);
              }
            });
          });
        } else {
          subfolder.forEach((folder) => {
            arr.push(...map.get(folder));
          });
        }
        return arr;
      }
    };
    let replacableCases = [
      "gt@cl",
      "cu@cl",
      "ce@cl",
      "st@cl",
      "gt@adv",
      "cu@adv",
    ];
    const unmappedFiles = (fullarr, mappedarr) => {
      // mappedarr = [...new Set(mappedarr)]
      // console.log(fullarr, mappedarr, mappedFiles)
      let simpleArr = [];
      const arr = [].concat(fullarr).map((el) => {
        replacableCases.forEach((rep) => {
          // console.log(rep)
          el.filepath = el.filepath.replaceAll(rep, "");
        });
        // console.log(el)
        return el;
      });

      console.log(arr, mappedarr);
      let unmappedArr = arr.filter(
        (el, i) =>
          !el.filepath.includes("##") && !mappedarr.includes(el.filepath)
      );
      unmappedArr.forEach((el) => {
        simpleArr.push(el.filepath);
      });
      console.log(simpleArr);
      // console.log(unmappedArr.filter((el) => !mappedarr.includes(el.filepath)))
      // console.log(mappedarr, fullarr, unmappedArr)
      //  = unmappedArr.forEach((el) => el.filepath)
      return simpleArr;
    };
    const nisfiles = () => {
      let simpleArr = [];
      console.log(this.state.nisfiles);
      if (this.state.nisfiles != undefined) {
        if (this.state.nisfiles.length > 0) {
          this.state.nisfiles.forEach((el) => {
            simpleArr.push(el.nisfilepath);
          });
          console.log(simpleArr);
        }
      }
      return simpleArr;
    };

    // const mapFileInArr = (files)=>{
    //     if(mappedFiles.includes())
    // }
    console.log(this.state.targetField, this.state.targetSubField, mappedFiles);
    if (
      this.state.targetField != "" &&
      this.state.targetField != "All" &&
      this.state.targetField != "Case Law & Advance Ruling" &&
      this.state.targetField != "Advance Ruling"
    ) {
      // This code works when user point a specific folder from search modal
      newtree.forEach((parent) => {
        if (parent.name == "GST") {
          if (
            this.state.targetField == "CGST" ||
            this.state.targetField == "IGST" ||
            this.state.targetField == "UGST" ||
            this.state.targetField == "GST(Compensation to the States) Act" ||
            this.state.targetField == "All"
          ) {
            let subjects = [
              "CGST",
              "IGST",
              "UGST",
              "GST(Compensation to the States) Act",
              "All",
            ];
            parent.children.forEach((child) => {
              // console.log(this.state.targetField, this.state.targetSubField)
              // if (this.state.targetSubField == "All") {
              //     this.state.targetSubField = 'Act'
              // }
              if (
                this.state.targetField == child.name &&
                child.name == "CGST"
              ) {
                child.children.forEach((el) => {
                  if (
                    this.state.targetSubField == "All" &&
                    this.state.targetField == "CGST"
                  ) {
                    if (el.name == "Act" && el.subfolder == "gtcgst") {
                      el.children = checkRemarks(["gtcgst"], []);
                      mappedFiles.push(...el.children);
                    } else if (el.name == "Rules" && el.subfolder == "gtstrl") {
                      el.children = checkRemarks(["gtstrl"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      el.name == "Notification(Non-Rate)" &&
                      el.subfolder == "ctntnr"
                    ) {
                      // let includeArr = checkRemarks(['ctntnr'], [])
                      // let notIncludeArr = checkRemarks(['gtnoti'], ['gtnoti_cgst_rate', 'gtcorri_cgst_'])
                      // el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                      el.children = checkRemarks(["ctntnr"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      el.name == "Notification(Rate)" &&
                      el.subfolder == "ctntra"
                    ) {
                      el.children = checkRemarks(["ctntra"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      el.name == "Circular" &&
                      el.subfolder == "ctcirc"
                    ) {
                      el.children = checkRemarks(["ctcirc"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      el.name == "Instructions" &&
                      el.subfolder == "gtcgcu"
                    ) {
                      el.children = checkRemarks(["gtcgcu"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      el.name == "Guidelines" &&
                      el.subfolder == "gtcggu"
                    ) {
                      el.children = checkRemarks(["gtcggu"], []);
                      mappedFiles.push(...el.children);
                    } else if (el.name == "Order" && el.subfolder == "ctordr") {
                      el.children = checkRemarks(["ctordr"], []);
                      mappedFiles.push(...el.children);
                    } else if (el.name == "ROD" && el.subfolder == "ctorod") {
                      el.children = checkRemarks(["ctorod"], []);
                      mappedFiles.push(...el.children);
                    }
                    //   else if (el.name == 'Order') {
                    //     el.children.forEach((child_el) => {
                    //         if (child_el.name == 'Order' && child_el.subfolder == 'ctordr') {
                    //             let includeArr = checkRemarks(['ctordr'], ['cgst_circular_order', 'cgst_circular_orderno'])
                    //             let notIncludeArr = checkRemarks(['ctordr'], ['ct.', 'ct.', '_centraltax.', '_centraltax.', '_unionterritorytax.'])
                    //             child_el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                    //             mappedFiles.push(...child_el.children)
                    //         } else if (child_el.name == 'ROD' && child_el.subfolder == 'ctorod') {
                    //             child_el.children = checkRemarks(['ctorod'], ['ct.', 'ct.', '_centraltax.', '_centraltax.'])
                    //             mappedFiles.push(...child_el.children)
                    //         }
                    //     })
                    // }
                  } else {
                    if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Act" &&
                      el.subfolder == "gtcgst"
                    ) {
                      el.children = checkRemarks(["gtcgst"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Rules" &&
                      el.subfolder == "gtstrl"
                    ) {
                      el.children = checkRemarks(["gtstrl"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Notification(Non-Rate)" &&
                      el.subfolder == "ctntnr"
                    ) {
                      el.children = checkRemarks(["ctntnr"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Notification(Rate)" &&
                      el.subfolder == "ctntra"
                    ) {
                      el.children = checkRemarks(["ctntra"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Circular" &&
                      el.subfolder == "ctcirc"
                    ) {
                      el.children = checkRemarks(["ctcirc"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == "Instructions" &&
                      el.name == "Instructions" &&
                      el.subfolder == "gtcgcu"
                    ) {
                      el.children = checkRemarks(["gtcgcu"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Guidelines" &&
                      el.name == "Guidelines" &&
                      el.subfolder == "gtcggu"
                    ) {
                      el.children = checkRemarks(["gtcggu"], []);
                      mappedFiles.push(...el.children);
                    }
                    // else if (el.name == 'Order') {
                    //     el.children.forEach((child_el) => {
                    //         if (child_el.name == 'Order' && child_el.subfolder == 'ctordr') {
                    //             let includeArr = checkRemarks(['ctordr'], ['cgst_circular_order', 'cgst_circular_orderno'])
                    //             let notIncludeArr = checkRemarks(['ctordr'], ['ct.', 'ct.', '_centraltax.', '_centraltax.', '_unionterritorytax.'])
                    //             child_el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                    //             mappedFiles.push(...child_el.children)
                    //         } else if (child_el.name == 'ROD' && child_el.subfolder == 'ctorod') {
                    //             child_el.children = checkRemarks(['ctorod'], ['ct.', 'ct.', '_centraltax.', '_centraltax.'])
                    //             mappedFiles.push(...child_el.children)
                    //         }
                    //     })
                    // }
                    else if (
                      this.state.targetSubField == el.name &&
                      el.name == "Order" &&
                      el.subfolder == "ctordr"
                    ) {
                      el.children = checkRemarks(["ctordr"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      el.name == "ROD" &&
                      el.subfolder == "ctorod"
                    ) {
                      el.children = checkRemarks(["ctorod"], []);
                      mappedFiles.push(...el.children);
                    }
                  }
                });
              } else if (
                this.state.targetField == child.name &&
                this.state.targetField == "IGST"
              ) {
                child.children.forEach((el) => {
                  if (
                    this.state.targetSubField == "All" &&
                    this.state.targetField == "IGST"
                  ) {
                    if (el.name == "Act" && el.subfolder == "gtigst") {
                      el.children = checkRemarks(["gtigst"], []);
                      mappedFiles.push(...el.children);
                    } else if (el.name == "Rules" && el.subfolder == "gtistr") {
                      el.children = checkRemarks(["gtistr"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      el.name == "Notification(Non-Rate)" &&
                      el.subfolder == "igntnr"
                    ) {
                      // el.children = checkRemarks(['gtnoti'], ['gtnoti_igst'])
                      el.children = checkRemarks(["igntnr"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      el.name == "Notification(Rate)" &&
                      el.subfolder == "igntra"
                    ) {
                      el.children = checkRemarks(["igntra"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      el.name == "Circular" &&
                      el.subfolder == "gtigcu"
                    ) {
                      el.children = checkRemarks(["gtigcu"], []);
                      mappedFiles.push(...el.children);
                    }
                  } else {
                    if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Act" &&
                      el.subfolder == "gtigst"
                    ) {
                      el.children = checkRemarks(["gtigst"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Rules" &&
                      el.subfolder == "gtistr"
                    ) {
                      el.children = checkRemarks(["gtistr"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Notification(Non-Rate)" &&
                      el.subfolder == "igntnr"
                    ) {
                      el.children = checkRemarks(["igntnr"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Notification(Rate)" &&
                      el.subfolder == "igntra"
                    ) {
                      el.children = checkRemarks(["igntra"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Circular" &&
                      el.subfolder == "gtigcu"
                    ) {
                      el.children = checkRemarks(["gtigcu"], []);
                      mappedFiles.push(...el.children);
                    }
                  }
                });
              } else if (
                this.state.targetField == child.name &&
                this.state.targetField == "UGST"
              ) {
                child.children.forEach((el) => {
                  if (
                    this.state.targetSubField == "All" &&
                    this.state.targetField == "UGST"
                  ) {
                    if (el.name == "Act" && el.subfolder == "gtugst") {
                      el.children = checkRemarks(["gtugst"], []);
                      mappedFiles.push(...el.children);
                    } else if (el.name == "Rules" && el.subfolder == "gtugru") {
                      el.children = checkRemarks(["gtugru"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      el.name == "Notification(Non-Rate)" &&
                      el.subfolder == "ugntnr"
                    ) {
                      el.children = checkRemarks(["ugntnr"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      el.name == "Notification(Rate)" &&
                      el.subfolder == "ugntra"
                    ) {
                      el.children = checkRemarks(["ugntra"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      el.name == "Order/ROD" &&
                      el.subfolder == "ugordr"
                    ) {
                      el.children = checkRemarks(["ugordr"], []);
                      mappedFiles.push(...el.children);
                    }
                  } else {
                    if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Act" &&
                      el.subfolder == "gtugst"
                    ) {
                      el.children = checkRemarks(["gtugst"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Rules" &&
                      el.subfolder == "gtugru"
                    ) {
                      el.children = checkRemarks(["gtugru"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Notification(Non-Rate)" &&
                      el.subfolder == "ugntnr"
                    ) {
                      el.children = checkRemarks(["ugntnr"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Notification(Rate)" &&
                      el.subfolder == "ugntra"
                    ) {
                      el.children = checkRemarks(["ugntra"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Order/ROD" &&
                      el.subfolder == "ugordr"
                    ) {
                      el.children = checkRemarks(["ugordr"], []);
                      mappedFiles.push(...el.children);
                    }
                  }
                });
              } else if (
                this.state.targetField == child.name &&
                this.state.targetField == "GST(Compensation to the States) Act"
              ) {
                child.children.forEach((el) => {
                  if (
                    this.state.targetSubField == "All" &&
                    this.state.targetField ==
                      "GST(Compensation to the States) Act"
                  ) {
                    if (el.name == "Act" && el.subfolder == "gtcomp") {
                      el.children = checkRemarks(["gtcomp"], []);
                      mappedFiles.push(...el.children);
                    } else if (el.name == "Rules" && el.subfolder == "gtcoru") {
                      el.children = checkRemarks(["gtcoru"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      el.name == "Notification(Non-Rate)" &&
                      el.subfolder == "compnr"
                    ) {
                      el.children = checkRemarks(["compnr"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      el.name == "Notification(Rate)" &&
                      el.subfolder == "compra"
                    ) {
                      el.children = checkRemarks(["compra"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      el.name == "Circular" &&
                      el.subfolder == "gtcoci"
                    ) {
                      el.children = checkRemarks(["gtcoci"], []);
                      mappedFiles.push(...el.children);
                    }
                  } else {
                    if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Act" &&
                      el.subfolder == "gtcomp"
                    ) {
                      el.children = checkRemarks(["gtcomp"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Rules" &&
                      el.subfolder == "gtcoru"
                    ) {
                      el.children = checkRemarks(["gtcoru"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Notification(Non-Rate)" &&
                      el.subfolder == "compnr"
                    ) {
                      el.children = checkRemarks(["compnr"], []);
                      console.log(el.children);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Notification(Rate)" &&
                      el.subfolder == "compra"
                    ) {
                      el.children = checkRemarks(["compra"], []);
                      mappedFiles.push(...el.children);
                    } else if (
                      this.state.targetSubField == el.name &&
                      this.state.targetSubField == "Circular" &&
                      el.subfolder == "gtcoci"
                    ) {
                      el.children = checkRemarks(["gtcoci"], []);
                      mappedFiles.push(...el.children);
                    }
                  }
                });
              }
              //  else if (this.state.targetSubField == "All" && this.state.targetField == 'GST-Other') {
              //     if (child.name == 'Press Release' && child.subfolder == 'gtpres') {
              //         child.children = checkRemarks(['gtpres'], [])
              //         mappedFiles.push(...child.children)
              //     } else if (child.name == 'Portal Related Updates' && child.subfolder == 'gtcgcu') {
              //         child.children = checkRemarks(['gtcgcu'], ['por', 'pro', 'updates_einvoice', 'hsn_advisry_table_12_2', 'gstr_3b_advisry_190722', 'advisory_hsn_gstr1_fil', 'einvoicing'])
              //         mappedFiles.push(...child.children)
              //     }
              //     else if (child.name == 'Council Meeting Updates' && child.subfolder == 'gtport') {
              //         child.children = checkRemarks(['gtport'], [])
              //         mappedFiles.push(...child.children)
              //     } else if (child.name == 'Form' && child.subfolder == 'gtcfrm') {
              //         child.children = checkRemarks(['gtcfrm'], [])
              //         mappedFiles.push(...child.children)
              //     }
              // }
              else {
                if (
                  (this.state.targetSubField == child.name ||
                    this.state.targetSubField == "All") &&
                  child.name == "Press Release" &&
                  child.subfolder == "gtpres"
                ) {
                  child.children = checkRemarks(["gtpres"], []);
                  mappedFiles.push(...child.children);
                } else if (
                  (this.state.targetSubField == child.name ||
                    this.state.targetSubField == "All") &&
                  child.name == "Portal Related Updates" &&
                  child.subfolder == "gstpru"
                ) {
                  child.children = checkRemarks(["gstpru"], []);
                  mappedFiles.push(...child.children);
                } else if (
                  (this.state.targetSubField == child.name ||
                    this.state.targetSubField == "All") &&
                  child.name == "Council Meeting Updates" &&
                  child.subfolder == "gtport"
                ) {
                  child.children = checkRemarks(["gtport"], []);
                  mappedFiles.push(...child.children);
                } else if (
                  (this.state.targetSubField == child.name ||
                    this.state.targetSubField == "All") &&
                  child.name == "Form" &&
                  child.subfolder == "gtcfrm"
                ) {
                  child.children = checkRemarks(["gtcfrm"], []);
                  mappedFiles.push(...child.children);
                }
                // else if ((this.state.targetSubField == child.name || this.state.targetSubField == 'All') && child.name == 'Case Laws' && subjects.includes(this.state.targetField)) {
                //     child.children = checkRemarks(['clsupr', 'clhigh', 'cloths', 'cltban', 'cltche', 'cltdel', 'cltkol', 'cltmum', 'clthyd', 'cltrib', 'cltahm', 'clnapa','cltald','cltchd'], [])
                //     console.log(child.children)
                //     this.setState({ skipCount: this.state.skipCount + child.children.length })
                //     mappedFiles.push(...child.children)
                // } else if ((this.state.targetSubField == child.name || this.state.targetSubField == 'All') && child.name == 'Advance Ruling' && subjects.includes(this.state.targetField)) {
                //     child.children = checkRemarks(['claaar', 'claarc'], [])
                //     console.log(child.children)
                //     this.setState({ skipCount: this.state.skipCount + child.children.length })
                //     mappedFiles.push(...child.children)
                // }
                else if (
                  (this.state.targetSubField == child.name ||
                    this.state.targetSubField == "All") &&
                  child.name == "CBIC FAQ's" &&
                  child.subfolder == "gtfaqb"
                ) {
                  child.children = checkRemarks(["gtfaqb"], []);
                  mappedFiles.push(...child.children);
                }
              }
            });
          }
        } else if (parent.name == "Custom") {
          parent.children.forEach((child) => {
            console.log(child);
            if (
              this.state.targetSubField == "All" &&
              this.state.targetField == "Custom"
            ) {
              console.log("All");
              if (child.name == "Act" && child.subfolder == "cuacts") {
                child.children = checkRemarks(["cuacts"], []);
                mappedFiles.push(...child.children);
              } else if (child.name == "Rules" && child.subfolder == "curule") {
                child.children = checkRemarks(["curule"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Circular/Instructions/Guidelines" &&
                child.subfolder == "cuncuc"
              ) {
                child.children = checkRemarks(["cuncuc"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Regulations" &&
                child.subfolder == "curegu"
              ) {
                child.children = checkRemarks(["curegu"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Manual" &&
                child.subfolder == "cumanu"
              ) {
                child.children = checkRemarks(["cumanu"], []);
                mappedFiles.push(...child.children);
              } else if (child.name == "Form" && child.subfolder == "cuform") {
                child.children = checkRemarks(["cuform"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Tariff" &&
                child.subfolder == "cutar2"
              ) {
                child.children = checkRemarks(["cutar2"], []);
                mappedFiles.push(...child.children);
              }
              //  else if (child.name == 'Case Laws' && (this.state.targetField == 'Custom' || this.state.targetField == 'All')) {
              //     child.children = checkRemarks(['clsupr', 'clhigh', 'cloths', 'cltban', 'cltche', 'cltdel', 'cltkol', 'cltmum', 'clthyd', 'cltrib', 'cltahm', 'clnapa','cltald','cltchd'], [])
              //     this.setState({ skipCount: this.state.skipCount + child.children.length })
              //     mappedFiles.push(...child.children)
              // } else if (child.name == 'Advance Ruling' && (this.state.targetField == 'Custom' || this.state.targetField == 'All')) {
              //     child.children = checkRemarks(['claaar', 'claarc'], [])
              //     this.setState({ skipCount: this.state.skipCount + child.children.length })
              //     mappedFiles.push(...child.children)
              // }
              else if (
                child.name == "CBIC FAQ's" &&
                child.subfolder == "cufaqb"
              ) {
                child.children = checkRemarks(["cufaqb"], []);
                mappedFiles.push(...child.children);
              } else if (child.name == "Notification") {
                child.children.forEach((child_el) => {
                  if (child_el.name == "Notification(Tariff)") {
                    child_el.children.forEach((child_child_el) => {
                      if (
                        child_child_el.name == "ADD" &&
                        child_child_el.subfolder == "cuncuv"
                      ) {
                        child_child_el.children = checkRemarks(
                          ["cuncuv"],
                          [],
                          "/ad/"
                        );
                        mappedFiles.push(...child_child_el.children);
                      } else if (
                        child_child_el.name == "Safeguard" &&
                        child_child_el.subfolder == "cuncuv"
                      ) {
                        child_child_el.children = checkRemarks(
                          ["cuncuv"],
                          [],
                          "/sg/"
                        );
                        mappedFiles.push(...child_child_el.children);
                      } else if (
                        child_child_el.name == "CVD" &&
                        child_child_el.subfolder == "cuncuv"
                      ) {
                        child_child_el.children = checkRemarks(
                          ["cuncuv"],
                          [],
                          "/cv/"
                        );
                        mappedFiles.push(...child_child_el.children);
                      } else if (
                        child_child_el.name == "Others" &&
                        child_child_el.subfolder == "cuncuv"
                      ) {
                        child_child_el.children = checkRemarks(
                          ["cuncuv"],
                          [],
                          "/ot/"
                        );
                        mappedFiles.push(...child_child_el.children);
                      }
                    });
                  } else if (
                    child_el.name == "Notification(Non-Tariff)" &&
                    child_el.subfolder == "cuncuv"
                  ) {
                    child_el.children = checkRemarks(["cuncuv"], [], "/nt/");
                    mappedFiles.push(...child_el.children);
                  }
                });
              }
            } else {
              // console.log('Other than all')
              // console.log(this.state.targetSubField == child.name,this.state.targetSubField == "CBIC FAQ's", child.subfolder == "cufaqb", this.state.targetSubField, child.name)

              // console.log(child.name == 'Case Laws' && this.state.targetField == 'Custom')
              if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Act" &&
                child.subfolder == "cuacts"
              ) {
                child.children = checkRemarks(["cuacts"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Rules" &&
                child.subfolder == "curule"
              ) {
                child.children = checkRemarks(["curule"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField ==
                  "Circular/Instructions/Guidelines" &&
                child.subfolder == "cuncuc"
              ) {
                child.children = checkRemarks(["cuncuc"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Regulations" &&
                child.subfolder == "curegu"
              ) {
                child.children = checkRemarks(["curegu"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Manual" &&
                child.subfolder == "cumanu"
              ) {
                child.children = checkRemarks(["cumanu"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Form" &&
                child.subfolder == "cuform"
              ) {
                child.children = checkRemarks(["cuform"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Tariff" &&
                child.subfolder == "cutar2"
              ) {
                child.children = checkRemarks(["cutar2"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "CBIC FAQ's" &&
                child.subfolder == "cufaqb"
              ) {
                child.children = checkRemarks(["cufaqb"], []);
                mappedFiles.push(...child.children);
              }
              // else if (this.state.targetSubField == child.name && child.name == 'Case Laws' && this.state.targetField == 'Custom') {
              //     console.log(21)
              //     child.children = checkRemarks(['clsupr', 'clhigh', 'cloths', 'cltban', 'cltche', 'cltdel', 'cltkol', 'cltmum', 'clthyd', 'cltrib', 'cltahm', 'clnapa','cltald','cltchd'], [])
              //     this.setState({ skipCount: this.state.skipCount + child.children.length })
              //     mappedFiles.push(...child.children)
              // }else if (this.state.targetSubField == child.name && child.name == 'Advance Ruling' && (this.state.targetField == 'Custom' || this.state.targetField == 'All')) {
              //     console.log(22)
              //     child.children = checkRemarks(['claaar', 'claarc'], [])
              //     this.setState({ skipCount: this.state.skipCount + child.children.length })
              //     mappedFiles.push(...child.children)
              // }
              else if (this.state.targetSubField.includes(child.name)) {
                child.children.forEach((child_el) => {
                  console.log(this.state.targetSubField);
                  if (
                    child_el.name == "Notification(Tariff)" &&
                    this.state.targetSubField == "Notification(Tariff)"
                  ) {
                    child_el.children.forEach((child_child_el) => {
                      if (
                        child_child_el.name == "ADD" &&
                        child_child_el.subfolder == "cuncuv"
                      ) {
                        child_child_el.children = checkRemarks(
                          ["cuncuv"],
                          [],
                          "/ad/"
                        );
                        mappedFiles.push(...child_child_el.children);
                      } else if (
                        child_child_el.name == "Safeguard" &&
                        child_child_el.subfolder == "cuncuv"
                      ) {
                        child_child_el.children = checkRemarks(
                          ["cuncuv"],
                          [],
                          "/sg/"
                        );
                        mappedFiles.push(...child_child_el.children);
                      } else if (
                        child_child_el.name == "CVD" &&
                        child_child_el.subfolder == "cuncuv"
                      ) {
                        child_child_el.children = checkRemarks(
                          ["cuncuv"],
                          [],
                          "/cv/"
                        );
                        mappedFiles.push(...child_child_el.children);
                      } else if (
                        child_child_el.name == "Others" &&
                        child_child_el.subfolder == "cuncuv"
                      ) {
                        child_child_el.children = checkRemarks(
                          ["cuncuv"],
                          [],
                          "/ot/"
                        );
                        mappedFiles.push(...child_child_el.children);
                      }
                    });
                  } else if (
                    child_el.name == "Notification(Non-Tariff)" &&
                    child_el.subfolder == "cuncuv" &&
                    this.state.targetSubField == "Notification(Non-Tariff)"
                  ) {
                    child_el.children = checkRemarks(["cuncuv"], [], "/nt/");
                    mappedFiles.push(...child_el.children);
                  }
                });
              }
            }
          });
        } else if (parent.name == "Excise") {
          parent.children.forEach((child) => {
            if (
              this.state.targetSubField == "All" &&
              this.state.targetField == "Excise"
            ) {
              if (child.name == "Act" && child.subfolder == "ceacts") {
                child.children = checkRemarks(["ceacts"], []);
                mappedFiles.push(...child.children);
              } else if (child.name == "Rules" && child.subfolder == "cerule") {
                child.children = checkRemarks(["cerule"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Circular/Instruction/Guidelines" &&
                child.subfolder == "cencec"
              ) {
                child.children = checkRemarks(["cencec"], []);
                mappedFiles.push(...child.children);
              } else if (child.name == "Notification") {
                child.children.forEach((child_el) => {
                  if (
                    child_el.name == "Notification(Non-Tariff)" &&
                    child_el.subfolder == "cencev"
                  ) {
                    child_el.children = checkRemarks(["cencev"], [], "/nt/");
                    mappedFiles.push(...child_el.children);
                  } else if (
                    child_el.name == "Notification(Tariff)" &&
                    child_el.subfolder == "cencev"
                  ) {
                    child_el.children = checkRemarks(["cencev"], [], "/ta/");
                    mappedFiles.push(...child_el.children);
                  }
                });
              } else if (
                child.name == "Manual" &&
                child.subfolder == "cemanu"
              ) {
                child.children = checkRemarks(["cemanu"], []);
                mappedFiles.push(...child.children);
              } else if (child.name == "Form" && child.subfolder == "ceform") {
                child.children = checkRemarks(["ceform"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Tariff" &&
                child.subfolder == "cetari"
              ) {
                child.children = checkRemarks(["cetari"], []);

                mappedFiles.push(...child.children);
              }
              //  else if (child.name == 'Case Laws' && (this.state.targetField == 'Excise' || this.state.targetField == 'All')) {
              //     child.children = checkRemarks(['clsupr', 'clhigh', 'cloths', 'cltban', 'cltche', 'cltdel', 'cltkol', 'cltmum', 'clthyd', 'cltrib', 'cltahm', 'clnapa','cltald','cltchd'], [])
              //     this.setState({ skipCount: this.state.skipCount + child.children.length })
              //     mappedFiles.push(...child.children)
              // }
            } else {
              console.log(this.state.targetSubField, child.name);
              if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Act" &&
                child.subfolder == "ceacts"
              ) {
                let arr = checkRemarks(["ceacts"], []);
                // arr.push(...checkRemarks(['cetari'], ['cetari08_Ist_sch.htm', 'cetari08_IInd_sch.htm', 'cetari13_IIIrd_sch.htm', 'cetari18_IVth_sch.htm']))
                child.children = arr;
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Rules" &&
                child.subfolder == "cerule"
              ) {
                child.children = checkRemarks(["cerule"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField.includes("Circular") &&
                child.subfolder == "cencec"
              ) {
                child.children = checkRemarks(["cencec"], []);
                mappedFiles.push(...child.children);
              } else if (child.name == "Notification") {
                child.children.forEach((child_el) => {
                  if (
                    this.state.targetSubField == child_el.name &&
                    child_el.name == "Notification(Non-Tariff)" &&
                    child_el.subfolder == "cencev" &&
                    this.state.targetSubField == "Notification(Non-Tariff)"
                  ) {
                    child_el.children = checkRemarks(["cencev"], [], "/nt/");
                    mappedFiles.push(...child_el.children);
                  } else if (
                    this.state.targetSubField == child_el.name &&
                    child_el.name == "Notification(Tariff)" &&
                    child_el.subfolder == "cencev" &&
                    this.state.targetSubField == "Notification(Tariff)"
                  ) {
                    child_el.children = checkRemarks(["cencev"], [], "/ta/");
                    mappedFiles.push(...child_el.children);
                  }
                });
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Manual" &&
                child.subfolder == "cemanu"
              ) {
                child.children = checkRemarks(["cemanu"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Form" &&
                child.subfolder == "ceform"
              ) {
                child.children = checkRemarks(["ceform"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Tariff" &&
                child.subfolder == "cetari"
              ) {
                let arr = checkRemarks(["cetari"], []);
                child.children = arr;
                // child.children = arr.filter((filepath) => !['cetari08_Ist_sch.htm', 'cetari08_IInd_sch.htm', 'cetari13_IIIrd_sch.htm', 'cetari18_IVth_sch.htm'].includes(filepath.split('\\').pop().split('/').pop()))
                mappedFiles.push(...child.children);
              }
              //  else if (this.state.targetSubField == child.name && child.name == 'Case Laws' && this.state.targetField == 'Excise') {
              //     child.children = checkRemarks(['clsupr', 'clhigh', 'cloths', 'cltban', 'cltche', 'cltdel', 'cltkol', 'cltmum', 'clthyd', 'cltrib', 'cltahm', 'clnapa'], [])
              //     this.setState({ skipCount: this.state.skipCount + child.children.length })
              //     mappedFiles.push(...child.children)
              // }
              // if (this.state.targetSubField == child.name && this.state.targetSubField == 'AAR' && child.subfolder == "claarc") {
              //     child.children = checkRemarks(['claarc'], [])
              // } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'AAAR' && child.subfolder == "claaar") {
              //     child.children = checkRemarks(['claaar'], [])
              // }
            }
          });
        } else if (parent.name == "FTP") {
          parent.children.forEach((child) => {
            if (
              this.state.targetSubField == "All" &&
              this.state.targetField == "Foreign Trade Policy"
            ) {
              if (child.name == "Act" && child.subfolder == "ftacts") {
                child.children = checkRemarks(["ftacts"], []);
                mappedFiles.push(...child.children);
              } else if (child.name == "Rules" && child.subfolder == "ftrule") {
                child.children = checkRemarks(["ftrule"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Circular" &&
                child.subfolder == "ftcirc"
              ) {
                child.children = checkRemarks(["ftcirc"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Notification" &&
                child.subfolder == "ftnoti"
              ) {
                child.children = checkRemarks(["ftnoti"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Appendix" &&
                child.subfolder == "ftappe"
              ) {
                child.children = checkRemarks(["ftappe"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "ITC (HS)" &&
                child.subfolder == "ftitch"
              ) {
                child.children = checkRemarks(["ftitch"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "DEPB DrawBack" &&
                child.subfolder == "ftrate"
              ) {
                child.children = checkRemarks(["ftrate"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Policy" &&
                child.subfolder == "ftpoli"
              ) {
                child.children = checkRemarks(["ftpoli"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Trade Notice" &&
                child.subfolder == "fttrad"
              ) {
                child.children = checkRemarks(["fttrad"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Public Notice" &&
                child.subfolder == "ftpubl"
              ) {
                child.children = checkRemarks(["ftpubl"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "HB Procedure" &&
                child.subfolder == "ftproc"
              ) {
                child.children = checkRemarks(["ftproc"], []);
                mappedFiles.push(...child.children);
              } else if (child.name == "SION" && child.subfolder == "ftsion") {
                child.children = checkRemarks(["ftsion"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "DGFT FAQ's" &&
                child.subfolder == "ftfaqb"
              ) {
                child.children = checkRemarks(["ftfaqb"], []);
                mappedFiles.push(...child.children);
              }
              //  else if (child.name == 'Case Laws' && (this.state.targetField == 'FTP' || this.state.targetField == 'All')) {
              //     child.children = checkRemarks(['clsupr', 'clhigh', 'cloths', 'cltban', 'cltche', 'cltdel', 'cltkol', 'cltmum', 'clthyd', 'cltrib', 'cltahm', 'clnapa','cltald','cltchd'], [])
              //     this.setState({ skipCount: this.state.skipCount + child.children.length })
              //     mappedFiles.push(...child.children)
              // }
            } else {
              if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Act" &&
                child.subfolder == "ftacts"
              ) {
                child.children = checkRemarks(["ftacts"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Rules" &&
                child.subfolder == "ftrule"
              ) {
                child.children = checkRemarks(["ftrule"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Circular" &&
                child.subfolder == "ftcirc"
              ) {
                child.children = checkRemarks(["ftcirc"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Notification" &&
                child.subfolder == "ftnoti"
              ) {
                child.children = checkRemarks(["ftnoti"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Appendix" &&
                child.subfolder == "ftappe"
              ) {
                child.children = checkRemarks(["ftappe"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "ITC (HS)" &&
                child.subfolder == "ftitch"
              ) {
                child.children = checkRemarks(["ftitch"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "DEPB DrawBack" &&
                child.subfolder == "ftrate"
              ) {
                child.children = checkRemarks(["ftrate"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Policy" &&
                child.subfolder == "ftpoli"
              ) {
                child.children = checkRemarks(["ftpoli"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Trade Notice" &&
                child.subfolder == "fttrad"
              ) {
                child.children = checkRemarks(["fttrad"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Public Notice" &&
                child.subfolder == "ftpubl"
              ) {
                child.children = checkRemarks(["ftpubl"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "HB Procedure" &&
                child.subfolder == "ftproc"
              ) {
                child.children = checkRemarks(["ftproc"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "SION" &&
                child.subfolder == "ftsion"
              ) {
                child.children = checkRemarks(["ftsion"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "DGFT FAQ's" &&
                child.subfolder == "ftfaqb"
              ) {
                child.children = checkRemarks(["ftfaqb"], []);
                mappedFiles.push(...child.children);
              }
              //  else if (this.state.targetSubField == child.name && child.name == 'Case Laws' && this.state.targetField == 'Foreign Trade Policy') {
              //     child.children = checkRemarks(['clsupr', 'clhigh', 'cloths', 'cltban', 'cltche', 'cltdel', 'cltkol', 'cltmum', 'clthyd', 'cltrib', 'cltahm', 'clnapa'], [])
              //     this.setState({ skipCount: this.state.skipCount + child.children.length })
              //     mappedFiles.push(...child.children)
              // }
            }
          });
        } else if (parent.name == "Service Tax") {
          parent.children.forEach((child) => {
            if (
              this.state.targetSubField == "All" &&
              this.state.targetField == "Service Tax"
            ) {
              if (child.name == "Act" && child.subfolder == "stacts") {
                child.children = checkRemarks(["stacts"], []);
                mappedFiles.push(...child.children);
              } else if (child.name == "Rules" && child.subfolder == "strule") {
                child.children = checkRemarks(["strule"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Circular/Instruction/Guidelines" &&
                child.subfolder == "stncsc"
              ) {
                child.children = checkRemarks(["stncsc"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Notification" &&
                child.subfolder == "stncsv"
              ) {
                child.children = checkRemarks(["stncsv"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Scheme" &&
                child.subfolder == "stsche"
              ) {
                child.children = checkRemarks(["stsche"], []);
                mappedFiles.push(...child.children);
              } else if (child.name == "Forms" && child.subfolder == "stform") {
                child.children = checkRemarks(["stform"], []);
                mappedFiles.push(...child.children);
              }
              //  else if (child.name == 'Case Laws' && (this.state.targetField == 'Service Tax' || this.state.targetField == 'All')) {
              //     child.children = checkRemarks(['clsupr', 'clhigh', 'cloths', 'cltban', 'cltche', 'cltdel', 'cltkol', 'cltmum', 'clthyd', 'cltrib', 'cltahm', 'clnapa','cltald','cltchd'], [])
              //     this.setState({ skipCount: this.state.skipCount + child.children.length })
              //     mappedFiles.push(...child.children)
              // }
            } else {
              if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Act" &&
                child.subfolder == "stacts"
              ) {
                child.children = checkRemarks(["stacts"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Rules" &&
                child.subfolder == "strule"
              ) {
                child.children = checkRemarks(["strule"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField ==
                  "Circular/Instruction/Guidelines" &&
                child.subfolder == "stncsc"
              ) {
                child.children = checkRemarks(["stncsc"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Notification" &&
                child.subfolder == "stncsv"
              ) {
                child.children = checkRemarks(["stncsv"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Scheme" &&
                child.subfolder == "stsche"
              ) {
                child.children = checkRemarks(["stsche"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Forms" &&
                child.subfolder == "stform"
              ) {
                child.children = checkRemarks(["stform"], []);
                mappedFiles.push(...child.children);
              }
              //  else if (child.name == 'Case Laws' && this.state.targetSubField == 'Case Laws' && (this.state.targetField == 'Service Tax' || this.state.targetField == 'All')) {
              //     child.children = checkRemarks(['clsupr', 'clhigh', 'cloths', 'cltban', 'cltche', 'cltdel', 'cltkol', 'cltmum', 'clthyd', 'cltrib', 'cltahm', 'clnapa','cltald','cltchd'], [])
              //     this.setState({ skipCount: this.state.skipCount + child.children.length })
              //     mappedFiles.push(...child.children)
              // }
            }
          });
        } else if (parent.name == "SEZ") {
          parent.children.forEach((child) => {
            if (
              this.state.targetSubField == "All" &&
              this.state.targetField == "SEZ"
            ) {
              if (child.name == "Act" && child.subfolder == "szacts") {
                child.children = checkRemarks(["szacts"], []);
                mappedFiles.push(...child.children);
              } else if (child.name == "Rules" && child.subfolder == "szrule") {
                child.children = checkRemarks(["szrule"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Policy" &&
                child.subfolder == "szpoli"
              ) {
                child.children = checkRemarks(["szpoli"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Appendix" &&
                child.subfolder == "szappe"
              ) {
                child.children = checkRemarks(["szappe"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Fema on SEZ" &&
                child.subfolder == "szfema"
              ) {
                child.children = checkRemarks(["szfema"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Notification" &&
                child.subfolder == "sznoti"
              ) {
                child.children = checkRemarks(["sznoti"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Circular" &&
                child.subfolder == "szcirc"
              ) {
                child.children = checkRemarks(["szcirc"], []);
                mappedFiles.push(...child.children);
              }
            } else {
              if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Act" &&
                child.subfolder == "szacts"
              ) {
                child.children = checkRemarks(["szacts"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Rules" &&
                child.subfolder == "szrule"
              ) {
                child.children = checkRemarks(["szrule"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Policy" &&
                child.subfolder == "szpoli"
              ) {
                child.children = checkRemarks(["szpoli"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Appendix" &&
                child.subfolder == "szappe"
              ) {
                child.children = checkRemarks(["szappe"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Fema on SEZ" &&
                child.subfolder == "szfema"
              ) {
                child.children = checkRemarks(["szfema"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Notification" &&
                child.subfolder == "sznoti"
              ) {
                child.children = checkRemarks(["sznoti"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Circular" &&
                child.subfolder == "szcirc"
              ) {
                child.children = checkRemarks(["szcirc"], []);
                mappedFiles.push(...child.children);
              }
            }
          });
        } else if (parent.name == "Budget") {
          if (this.state.targetField == "Miscellaneous") {
            parent.children = checkRemarks(["bu"], []);
            mappedFiles.push(...parent.children);
          }
        } else if (parent.name == "Allied Act") {
          if (this.state.targetField == "Miscellaneous") {
            parent.children = checkRemarks(["cualar"], []);
            mappedFiles.push(...parent.children);
          }
        } else if (parent.name == "Ask-a-Question") {
          if (this.state.targetField == "Miscellaneous") {
            parent.children = checkRemarks(["gtillu"], []);
            mappedFiles.push(...parent.children);
          }
        }
        // else if (parent.name == 'Tariff') {
        //     if (this.state.targetSubField == "All") {
        //         parent.children = checkRemarks(['cutari'], [])
        //         mappedFiles.push(...parent.children)
        //     }
        // }
        else if (parent.name == "Case Law") {
          // parent.children.forEach((child) => {
          //     console.log(this.state.targetSubField, this.state.targetField, (this.state.targetSubField == "All" && this.state.targetField == 'Case Law'), '============================comming================')

          // })
          if (
            (this.state.targetSubField == "All" &&
              this.state.targetField == "Case Law") ||
            ((this.state.targetSubField == "Case Law & Advance Ruling" ||
              this.state.targetSubField == "All") &&
              this.state.targetField == "CGST") ||
            ((this.state.targetSubField == "Case Law & Advance Ruling" ||
              this.state.targetSubField == "All") &&
              this.state.targetField == "IGST") ||
            ((this.state.targetSubField == "Case Law & Advance Ruling" ||
              this.state.targetSubField == "All") &&
              this.state.targetField == "UGST") ||
            ((this.state.targetSubField == "Case Law & Advance Ruling" ||
              this.state.targetSubField == "All") &&
              this.state.targetField ==
                "GST(Compensation to the States) Act") ||
            ((this.state.targetSubField == "Case Law & Advance Ruling" ||
              this.state.targetSubField == "All") &&
              this.state.targetField == "Custom") ||
            ((this.state.targetSubField == "Case Law & Advance Ruling" ||
              this.state.targetSubField == "All") &&
              this.state.targetField == "Service Tax") ||
            ((this.state.targetSubField == "Case Law & Advance Ruling" ||
              this.state.targetSubField == "All") &&
              this.state.targetField == "Foreign Trade Policy") ||
            ((this.state.targetSubField == "Case Law & Advance Ruling" ||
              this.state.targetSubField == "All") &&
              this.state.targetField == "Excise")
          ) {
            parent.children = checkRemarks(
              [
                "clsupr",
                "clhigh",
                "clnapa",
                "cloths",
                "cltban",
                "cltche",
                "cltdel",
                "cltkol",
                "cltmum",
                "clthyd",
                "cltrib",
                "cltahm",
                "cltald",
                "cltchd",
              ],
              []
            );
            mappedFiles.push(...parent.children);
          }
        } else if (parent.name == "Advance Ruling") {
          // parent.children.forEach((child) => {

          // })
          if (
            (this.state.targetSubField == "All" &&
              this.state.targetField == "Advance Ruling") ||
            ((this.state.targetSubField == "Case Law & Advance Ruling" ||
              this.state.targetSubField == "All") &&
              this.state.targetField == "CGST") ||
            ((this.state.targetSubField == "Case Law & Advance Ruling" ||
              this.state.targetSubField == "All") &&
              this.state.targetField == "IGST") ||
            ((this.state.targetSubField == "Case Law & Advance Ruling" ||
              this.state.targetSubField == "All") &&
              this.state.targetField == "UGST") ||
            ((this.state.targetSubField == "Case Law & Advance Ruling" ||
              this.state.targetSubField == "All") &&
              this.state.targetField == "Custom") ||
            ((this.state.targetSubField == "Case Law & Advance Ruling" ||
              this.state.targetSubField == "All") &&
              this.state.targetField == "GST(Compensation to the States) Act")
          ) {
            parent.children = checkRemarks(["claarc", "claaar", "cuaarc"], []);
            mappedFiles.push(...parent.children);
          }
          //  else {
          //     parent.children = checkRemarks(['claarc','claaar'], [])
          //     mappedFiles.push(...parent.children)
          // }
        } else if (parent.name == "Question Bank") {
          parent.children.forEach((child) => {
            if (this.state.targetField == "Miscellaneous") {
              if (
                child.name == "Ask-A-Question" &&
                child.subfolder == "gtillu"
              ) {
                child.children = checkRemarks(["gtillu"], []);
                mappedFiles.push(...child.children);
              }
            }
            // if (this.state.targetSubField == "All" && this.state.targetField == 'Question Bank') {
            //     if (child.name == 'CBIC FAQ' && child.subfolder == "gtfaqb") {
            //         child.children = checkRemarks(['gtfaqb'], [])
            //         mappedFiles.push(...child.children)
            //     } else if (child.name == 'Ask-A-Question' && child.subfolder == "gtillu") {
            //         child.children = checkRemarks(['gtillu'], [])
            //         mappedFiles.push(...child.children)
            //     }
            // } else {
            //     if (this.state.targetSubField == child.name && this.state.targetSubField == 'CBIC FAQ' && child.subfolder == "gtfaqb") {
            //         child.children = checkRemarks(['gtfaqb'], [])
            //         mappedFiles.push(...child.children)
            //     } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Ask-A-Question' && child.subfolder == "gtillu") {
            //         child.children = checkRemarks(['gtillu'], [])
            //         mappedFiles.push(...child.children)
            //     }
            // }
          });
        } else if (parent.name == "FEMA") {
          parent.children.forEach((child) => {
            if (
              this.state.targetSubField == "All" &&
              this.state.targetField == "FEMA"
            ) {
              if (child.name == "Act" && child.subfolder == "rbacts") {
                child.children = checkRemarks(["rbacts"], []);
                mappedFiles.push(...child.children);
              } else if (child.name == "Rules" && child.subfolder == "rbrule") {
                child.children = checkRemarks(["rbrule"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Circular" &&
                child.subfolder == "rbcirc"
              ) {
                child.children = checkRemarks(["rbcirc"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Notification" &&
                child.subfolder == "rbnoti"
              ) {
                child.children = checkRemarks(["rbnoti"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Regulations" &&
                child.subfolder == "rbregu"
              ) {
                child.children = checkRemarks(["rbregu"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "Citizen Charter" &&
                child.subfolder == "rbciti"
              ) {
                child.children = checkRemarks(["rbciti"], []);
                mappedFiles.push(...child.children);
              } else if (child.name == "Form" && child.subfolder == "rbform") {
                child.children = checkRemarks(["rbform"], []);
                mappedFiles.push(...child.children);
              } else if (
                child.name == "RBI FAQ" &&
                child.subfolder == "rbfaqb"
              ) {
                child.children = checkRemarks(["rbfaqb"], []);
                mappedFiles.push(...child.children);
              }
            } else {
              if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Act" &&
                child.subfolder == "rbacts"
              ) {
                child.children = checkRemarks(["rbacts"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Rules" &&
                child.subfolder == "rbrule"
              ) {
                child.children = checkRemarks(["rbrule"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Circular" &&
                child.subfolder == "rbcirc"
              ) {
                child.children = checkRemarks(["rbcirc"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Notification" &&
                child.subfolder == "rbnoti"
              ) {
                child.children = checkRemarks(["rbnoti"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Regulations" &&
                child.subfolder == "rbregu"
              ) {
                child.children = checkRemarks(["rbregu"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Citizen Charter" &&
                child.subfolder == "rbciti"
              ) {
                child.children = checkRemarks(["rbciti"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "Form" &&
                child.subfolder == "rbform"
              ) {
                child.children = checkRemarks(["rbform"], []);
                mappedFiles.push(...child.children);
              } else if (
                this.state.targetSubField == child.name &&
                this.state.targetSubField == "RBI FAQ" &&
                child.subfolder == "rbfaqb"
              ) {
                child.children = checkRemarks(["rbfaqb"], []);
                mappedFiles.push(...child.children);
              }
            }
          });
        } else if (parent.name == "Unmapped Files") {
          parent.children = unmappedFiles(input, mappedFiles);
        } else if (parent.name == "NIS Files") {
          parent.children = nisfiles();
        }
      });

      const getTotalCount = (nodes) => {
        console.log(nodes);
        let total = 0;
        nodes.map((node) => {
          let node_files_count = this.getLeafCount(node);
          console.log(node_files_count);
          total += node_files_count;
        });
        this.setState({ totalFilesCount: total });
      };
      getTotalCount(newtree);
      this.setState({ newTreeData: newtree });
      // console.log(newtree, map, mappedFiles)
    } else {
      console.log(
        "This code works when user point all folders from search modal"
      );
      newtree.forEach((parent) => {
        console.log("test123");
        // if (parent.name == "GST") {
        //     parent.children.forEach((child) => {
        //         if (child.name == "CGST") {
        //             child.children.forEach((el) => {
        //                 if (el.name == 'Act' && el.subfolder == "gtcgst") {
        //                     el.children = checkRemarks(['gtcgst'], [])
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == 'Rules' && el.subfolder == 'gtstrl') {
        //                     el.children = checkRemarks(['gtstrl'], [])
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == "Notification(Non-Rate)" && el.subfolder == 'ctntnr') {
        //                     // let includeArr = checkRemarks(['ctntnr'], [])
        //                     el.children = checkRemarks(['ctntnr'], [])
        //                     // let notIncludeArr = checkRemarks(['gtnoti'], ['gtnoti_cgst_rate', 'gtcorri_cgst_'])
        //                     // el.children = includeArr.filter(el => !notIncludeArr.includes(el))
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == 'Notification(Rate)' && el.subfolder == 'ctntra') {
        //                     el.children = checkRemarks(['ctntra'], [])
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == 'Circular' && el.subfolder == 'ctcirc') {
        //                     el.children = checkRemarks(['ctcirc'], [])
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == 'Instructions' && el.subfolder == 'gtcgcu') {
        //                     el.children = checkRemarks(['gtcgcu'], ['instruction'])
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == 'Guidelines' && el.subfolder == 'gtcggu') {
        //                     let arr = checkRemarks(['gtcgcu'], ['guide_index'])
        //                     el.children = checkRemarks(['gtcgcu'], ['guide_']).filter(el => !arr.includes(el))
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == 'Order' && el.subfolder == 'ctordr') {
        //                     let includeArr = checkRemarks(['ctordr'], ['cgst_circular_order', 'cgst_circular_orderno'])
        //                     let notIncludeArr = checkRemarks(['ctordr'], ['ct.', 'ct.', '_centraltax.', '_centraltax.', '_unionterritorytax.'])
        //                     el.children = includeArr.filter(el => !notIncludeArr.includes(el))
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == 'ROD' && el.subfolder == 'ctorod') {
        //                     el.children = checkRemarks(['ctorod'], ['ct.', 'ct.', '_centraltax.', '_centraltax.'])
        //                     mappedFiles.push(...el.children)
        //                 }
        //             })
        //         } else if (child.name == 'IGST') {
        //             child.children.forEach((el) => {
        //                 if (el.name == 'Act' && el.subfolder == "gtigst") {
        //                     el.children = checkRemarks(['gtigst'], [])
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == 'Rules' && el.subfolder == 'gtistr') {
        //                     el.children = checkRemarks(['gtistr'], [])
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == "Notification(Non-Rate)" && el.subfolder == 'igntnr') {
        //                     // el.children = checkRemarks(['gtnoti'], ['gtnoti_igst'])
        //                     el.children = checkRemarks(['igntnr'], [])
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == 'Notification(Rate)' && el.subfolder == 'igntra') {
        //                     el.children = checkRemarks(['igntra'], [])
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == 'Circular' && el.subfolder == 'gtigcu') {
        //                     el.children = checkRemarks(['gtnoti'], ['igst_circular', 'igst_circular_no'])
        //                     mappedFiles.push(...el.children)
        //                 }
        //             })
        //         } else if (child.name == 'UGST') {
        //             child.children.forEach((el) => {
        //                 if (el.name == 'Act' && el.subfolder == "gtugst") {
        //                     el.children = checkRemarks(['gtugst'], ['utgst_bill_17_'])
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == 'Rules' && el.subfolder == 'gtugru') {
        //                     el.children = checkRemarks(['gtugru'], ['rule'])
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == "Notification(Non-Rate)" && el.subfolder == 'ugntnr') {
        //                     el.children = checkRemarks(['ugntnr'], [])
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == 'Notification(Rate)' && el.subfolder == 'ugntra') {
        //                     el.children = checkRemarks(['ugntra'], [])
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == 'Order/ROD' && el.subfolder == 'ugordr') {
        //                     // el.children.forEach((child_el) => {
        //                     //     if (child_el.name == 'ROD' && child_el.subfolder == 'gtnoti') {
        //                     //         child_el.children = checkRemarks(['gtnoti'], ['Nut.', '_unionterritorytax.'])
        //                     //         // child_el.children = checkRemarks(['gtnoti'], ['ugst_circular_order19_', 'cgst_circular_orderno'])
        //                     //         mappedFiles.push(...child_el.children)
        //                     //     }
        //                     // })
        //                     el.children = checkRemarks(['ugordr'], ['ut.', '_unionterritorytax.'])
        //                     mappedFiles.push(...el.children)
        //                 }
        //             })
        //         } else if (child.name == 'GST(Compensation to the States) Act') {
        //             child.children.forEach((el) => {
        //                 if (el.name == 'Act' && el.subfolder == "gtcomp") {
        //                     el.children = checkRemarks(['gtcomp'], ['com_act_', 'com_sch'])
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == 'Rules' && el.subfolder == "gtcoru") {
        //                     el.children = checkRemarks(['gtcoru'], ['com_Rule_'])
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == "Notification(Non-Rate)" && el.subfolder == 'compnr') {
        //                     el.children = checkRemarks(['compnr'], [])
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == 'Notification(Rate)' && el.subfolder == 'compra') {
        //                     el.children = checkRemarks(['compra'], [])
        //                     mappedFiles.push(...el.children)
        //                 } else if (el.name == 'Circular' && el.subfolder == 'gtcoci') {
        //                     el.children = checkRemarks(['gtnoti'], ['compcess_circular_no'])
        //                     mappedFiles.push(...el.children)
        //                 }
        //             })
        //         } else if (child.name == 'Press Release' && child.subfolder == 'gtpres') {
        //             child.children = checkRemarks(['gtpres'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Portal Related Updates' && child.subfolder == 'gtcgcu') {
        //             let includeArr = checkRemarks(['gtcgcu'], ['por', 'pro'])
        //             let notIncludeArr = checkRemarks(['gtcgcu'], ['index'])
        //             child.children = includeArr.filter(el => !notIncludeArr.includes(el))
        //             // child.children = checkRemarks(['gtcgcu'], ['por', 'pro', 'updates_einvoice', 'hsn_advisry_table_12_2', 'gstr_3b_advisry_190722', 'advisory_hsn_gstr1_fil', 'einvoicing'])
        //             mappedFiles.push(...child.children)
        //         }
        //         else if (child.name == 'Council Meeting Updates' && child.subfolder == 'gtport') {
        //             child.children = checkRemarks(['gtport'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Form' && child.subfolder == 'gtcfrm') {
        //             child.children = checkRemarks(['gtcfrm'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Case Laws' && (this.state.targetSubField == "All" || this.props.field == 'GT')) {// && this.props.field == 'GT'
        //             let data = [
        //                 ...checkRemarks(['clsupr'], [], 'gt@cl'), ...checkRemarks(['clhigh'], [], 'gt@cl'), ...checkRemarks(['cltban'], [], 'gt@cl'),
        //                 ...checkRemarks(['cltche'], [], 'gt@cl'), ...checkRemarks(['cltdel'], [], 'gt@cl'), ...checkRemarks(['cltkol'], [], 'gt@cl'),
        //                 ...checkRemarks(['cltmum'], [], 'gt@cl'), ...checkRemarks(['clthyd'], [], 'gt@cl'), ...checkRemarks(['cltrib'], [], 'gt@cl'),
        //                 ...checkRemarks(['cltahm'], [], 'gt@cl'), ...checkRemarks(['clnapa'], [], 'gt@cl'), ...checkRemarks(['cloths'], [], 'gt@cl'),
        //             ]
        //             this.setState({ skipCount: this.state.skipCount + data.length })
        //             child.children = data
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Advance Ruling' && this.state.targetSubField == "All") {
        //             child.children = checkRemarks(['claaar', 'claarc'], [], 'gt@adv')
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == "CBIC FAQ's" && child.subfolder == 'gtfaqb') {
        //             child.children = checkRemarks(['gtfaqb'], [])
        //             mappedFiles.push(...child.children)
        //         }
        //     })
        // } else if (parent.name == "Custom") {
        //     parent.children.forEach((child) => {
        //         if (child.name == 'Act' && child.subfolder == "cuacts") {
        //             child.children = checkRemarks(['cuacts'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Rules' && child.subfolder == "curule") {
        //             child.children = checkRemarks(['curule'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Circular/Instructions/Guidelines' && child.subfolder == "cuncuc") {
        //             child.children = checkRemarks(['cuncuc'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Regulations' && child.subfolder == "curegu") {
        //             child.children = checkRemarks(['curegu'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Manual' && child.subfolder == "cumanu") {
        //             child.children = checkRemarks(['cumanu'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Form' && child.subfolder == "cuform") {
        //             child.children = checkRemarks(['cuform'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Tariff' && child.subfolder == "cutari") {
        //             child.children = checkRemarks(['cutari'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Notification') {
        //             child.children.forEach((child_el) => {
        //                 if (child_el.name == 'Notification(Tariff)') {
        //                     child_el.children.forEach((child_child_el) => {
        //                         if (child_child_el.name == 'ADD' && child_child_el.subfolder == 'cuncuv') {
        //                             child_child_el.children = checkRemarks(['cuncuv'], [], '##ADD')
        //                             mappedFiles.push(...child_child_el.children)
        //                         } else if (child_child_el.name == 'Safeguard' && child_child_el.subfolder == 'cuncuv') {
        //                             child_child_el.children = checkRemarks(['cuncuv'], [], '##SG')
        //                             mappedFiles.push(...child_child_el.children)
        //                         } else if (child_child_el.name == 'CVD' && child_child_el.subfolder == 'cuncuv') {
        //                             child_child_el.children = checkRemarks(['cuncuv'], [], '##CVD')
        //                             mappedFiles.push(...child_child_el.children)
        //                         } else if (child_child_el.name == 'Others' && child_child_el.subfolder == 'cuncuv') {
        //                             child_child_el.children = checkRemarks(['cuncuv'], [], '##OTHER')
        //                             mappedFiles.push(...child_child_el.children)
        //                         }
        //                     })
        //                 } else if (child_el.name == 'Notification(Non-Tariff)' && child_el.subfolder == 'cuncuv') {
        //                     child_el.children = checkRemarks(['cuncuv'], [], '##NT')
        //                     mappedFiles.push(...child_el.children)
        //                 }
        //             })
        //         } else if (child.name == 'Case Laws' && (this.props.field == 'CU' || this.state.targetSubField == "All")) {
        //             let data = [
        //                 ...checkRemarks(['clsupr'], [], 'cu@cl'), ...checkRemarks(['clhigh'], [], 'cu@cl'), ...checkRemarks(['cltban'], [], 'cu@cl'),
        //                 ...checkRemarks(['cltche'], [], 'cu@cl'), ...checkRemarks(['cltdel'], [], 'cu@cl'), ...checkRemarks(['cltkol'], [], 'cu@cl'),
        //                 ...checkRemarks(['cltmum'], [], 'cu@cl'), ...checkRemarks(['clthyd'], [], 'cu@cl'), ...checkRemarks(['cltrib'], [], 'cu@cl'),
        //                 ...checkRemarks(['cltahm'], [], 'cu@cl'), ...checkRemarks(['clnapa'], [], 'cu@cl'), ...checkRemarks(['cloths'], [], 'cu@cl')
        //             ]
        //             this.setState({ skipCount: this.state.skipCount + data.length })
        //             child.children = data
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Advance Ruling' && this.state.targetSubField == "All") {
        //             child.children = checkRemarks(['claaar', 'claarc'], [], 'cu@adv')
        //             mappedFiles.push(...child.children)
        //         } else if (this.state.targetSubField == "CBIC FAQ's" && child.subfolder == "cufaqb") {
        //             child.children = checkRemarks(['cufaqb'], [])
        //             mappedFiles.push(...child.children)
        //         }
        //     })
        // } else if (parent.name == 'Excise') {
        //     parent.children.forEach((child) => {
        //         if (child.name == 'Act' && child.subfolder == "ceacts") {
        //             let arr = checkRemarks(['ceacts'], [])
        //             // arr.push(...checkRemarks(['cetari'], ['cetari08_Ist_sch.htm', 'cetari08_IInd_sch.htm', 'cetari13_IIIrd_sch.htm', 'cetari18_IVth_sch.htm']))
        //             child.children = arr
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Rules' && child.subfolder == "cerule") {
        //             child.children = checkRemarks(['cerule'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Circular/Instruction/Guidelines' && child.subfolder == "cencec") {
        //             child.children = checkRemarks(['cencec'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Notification') {
        //             child.children.forEach((child_el) => {
        //                 if (child_el.name == 'Notification(Non-Tariff)' && child_el.subfolder == 'cencev') {
        //                     child_el.children = checkRemarks(['cencev'], [], '##NT')
        //                     mappedFiles.push(...child_el.children)
        //                 } else if (child_el.name == 'Notification(Tariff)' && child_el.subfolder == 'cencev') {
        //                     child_el.children = checkRemarks(['cencev'], [], '##OTHER')
        //                     mappedFiles.push(...child_el.children)
        //                 }
        //             })
        //         } else if (child.name == 'Manual' && child.subfolder == "cemanu") {
        //             child.children = checkRemarks(['cemanu'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Form' && child.subfolder == "ceform") {
        //             child.children = checkRemarks(['ceform'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Tariff' && child.subfolder == "cetari") {
        //             let arr = checkRemarks(['cetari'], [])
        //             child.children = arr
        //             // child.children = arr.filter((filepath) => !['cetari08_Ist_sch.htm', 'cetari08_IInd_sch.htm', 'cetari13_IIIrd_sch.htm', 'cetari18_IVth_sch.htm'].includes(filepath.split('\\').pop().split('/').pop()))
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Case Laws' && (this.props.field == 'CE' || this.state.targetSubField == "All")) {
        //             let data = [
        //                 ...checkRemarks(['clsupr'], [], 'ce@cl'), ...checkRemarks(['clhigh'], [], 'ce@cl'), ...checkRemarks(['cltban'], [], 'ce@cl'),
        //                 ...checkRemarks(['cltche'], [], 'ce@cl'), ...checkRemarks(['cltdel'], [], 'ce@cl'), ...checkRemarks(['cltkol'], [], 'ce@cl'),
        //                 ...checkRemarks(['cltmum'], [], 'ce@cl'), ...checkRemarks(['clthyd'], [], 'ce@cl'), ...checkRemarks(['cltrib'], [], 'ce@cl'),
        //                 ...checkRemarks(['cltahm'], [], 'ce@cl'), ...checkRemarks(['clnapa'], [], 'ce@cl'), ...checkRemarks(['cloths'], [], 'ce@cl'),
        //             ]
        //             this.setState({ skipCount: this.state.skipCount + data.length })
        //             child.children = data
        //             mappedFiles.push(...child.children)
        //         }
        //     })
        // } else if (parent.name == 'Service Tax') {
        //     parent.children.forEach((child) => {
        //         if (child.name == 'Act' && child.subfolder == "stacts") {
        //             child.children = checkRemarks(['stacts'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Rules' && child.subfolder == "strule") {
        //             child.children = checkRemarks(['strule'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Circular/Instruction/Guidelines' && child.subfolder == "stncsc") {
        //             child.children = checkRemarks(['stncsc'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Notification' && child.subfolder == "stncsv") {
        //             child.children = checkRemarks(['stncsv'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Scheme' && child.subfolder == "stsche") {
        //             child.children = checkRemarks(['stsche'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Forms' && child.subfolder == "stform") {
        //             child.children = checkRemarks(['stform'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Case Laws' && (this.props.field == 'ST' || this.state.targetSubField == "All")) {
        //             let data = [
        //                 ...checkRemarks(['cltche'], [], 'st@cl'), ...checkRemarks(['cltdel'], [], 'st@cl'), ...checkRemarks(['cltkol'], [], 'st@cl'),
        //                 ...checkRemarks(['cltmum'], [], 'st@cl'), ...checkRemarks(['clthyd'], [], 'st@cl'), ...checkRemarks(['cltrib'], [], 'st@cl'),
        //                 ...checkRemarks(['clsupr'], [], 'st@cl'), ...checkRemarks(['clhigh'], [], 'st@cl'), ...checkRemarks(['cltban'], [], 'st@cl'),
        //                 ...checkRemarks(['cltahm'], [], 'st@cl'), ...checkRemarks(['clnapa'], [], 'st@cl'), ...checkRemarks(['cloths'], [], 'st@cl'),
        //             ]

        //             this.setState({ skipCount: this.state.skipCount + data.length })
        //             child.children = data
        //             mappedFiles.push(...child.children)
        //         }
        //     })
        // } else if (parent.name == "FTP") {
        //     parent.children.forEach((child) => {
        //         if (child.name == 'Act' && child.subfolder == "ftacts") {
        //             child.children = checkRemarks(['ftacts'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Rules' && child.subfolder == "ftrule") {
        //             child.children = checkRemarks(['ftrule'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Circular' && child.subfolder == "ftcirc") {
        //             child.children = checkRemarks(['ftcirc'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Notification' && child.subfolder == "ftnoti") {
        //             child.children = checkRemarks(['ftnoti'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Appendix' && child.subfolder == "ftappe") {
        //             child.children = checkRemarks(['ftappe'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'ITC (HS)' && child.subfolder == "ftitch") {
        //             child.children = checkRemarks(['ftitch'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'DEPB DrawBack' && child.subfolder == "ftrate") {
        //             child.children = checkRemarks(['ftrate'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Policy' && child.subfolder == "ftpoli") {
        //             child.children = checkRemarks(['ftpoli'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Trade Notice' && child.subfolder == "fttrad") {
        //             child.children = checkRemarks(['fttrad'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Public Notice' && child.subfolder == "ftpubl") {
        //             child.children = checkRemarks(['ftpubl'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'HB Procedure' && child.subfolder == "ftproc") {
        //             child.children = checkRemarks(['ftproc'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'SION' && child.subfolder == "ftsion") {
        //             child.children = checkRemarks(['ftsion'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == "DGFT FAQ's" && child.subfolder == "ftftqb") {
        //             child.children = checkRemarks(['ftftqb'], [])
        //             mappedFiles.push(...child.children)
        //         }
        //     })
        // } else if (parent.name == "SEZ") {
        //     parent.children.forEach((child) => {
        //         if (child.name == 'Act' && child.subfolder == "szacts") {
        //             child.children = checkRemarks(['szacts'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Rules' && child.subfolder == "szrule") {
        //             child.children = checkRemarks(['szrule'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Policy' && child.subfolder == "szpoli") {
        //             child.children = checkRemarks(['szpoli'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Appendix' && child.subfolder == "szappe") {
        //             child.children = checkRemarks(['szappe'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Fema on SEZ' && child.subfolder == "szfema") {
        //             child.children = checkRemarks(['szfema'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Notification' && child.subfolder == "sznoti") {
        //             child.children = checkRemarks(['sznoti'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Circular' && child.subfolder == "szcirc") {
        //             child.children = checkRemarks(['szcirc'], [])
        //             mappedFiles.push(...child.children)
        //         }
        //     })
        // } else if (parent.name == 'Budget') {
        //     parent.children = checkRemarks(['bu'], [])
        //     mappedFiles.push(...parent.children)
        // } else if (parent.name == 'Allied Act') {
        //     parent.children = checkRemarks(['cualar'], [])
        //     mappedFiles.push(...parent.children)
        // } else if (parent.name == 'Ask-a-Question') {
        //     parent.children = checkRemarks(['gtillu'], [])
        //     mappedFiles.push(...parent.children)
        // }
        // else if (parent.name == 'Tariff') {
        //     parent.children = checkRemarks(['cutari'], [])
        //     mappedFiles.push(...parent.children)
        // }

        if (
          parent.name == "Case Law" &&
          (this.state.targetField == "Case Law & Advance Ruling" ||
            this.state.targetSubField == "All" ||
            this.props.field == "CU" ||
            this.props.field == "GT" ||
            this.props.field == "ST" ||
            this.props.field == "CE" ||
            this.props.field == "FT" ||
            this.props.isCourt == true)
        ) {
          // console.log((parent.name == 'Case Law' && this.state.targetSubField == "Case Law" || this.state.targetSubField == "All"), '========coming=====')
          parent.children = checkRemarks(
            [
              "clsupr",
              "clhigh",
              "clnapa",
              "cloths",
              "cltban",
              "cltche",
              "cltdel",
              "cltkol",
              "cltmum",
              "clthyd",
              "cltrib",
              "cltahm",
              "cltald",
              "cltchd",
            ],
            []
          );
          mappedFiles.push(...parent.children);
          // parent.children.forEach((child) => {
          // if (child.name == 'Supreme Court' && child.subfolder == "clsupr") {
          //     let gtCl = checkRemarks(['clsupr'], [])
          //     child.children = [...gtCl]
          //     mappedFiles.push(...child.children)
          // } else if (child.name == 'High Court' && child.subfolder == "clhigh") {
          //     let gtCl = checkRemarks(['clhigh'], [])
          //     child.children = [...gtCl]
          //     mappedFiles.push(...child.children)
          // } else if (child.name == 'Tribunal') {
          //     let gtCl = checkRemarks(['cloths', 'cltban', 'cltche', 'cltdel', 'cltkol', 'cltmum', 'clthyd', 'cltrib', 'cltahm','cltald','cltchd'], [])
          //     child.children = [...gtCl]
          //     mappedFiles.push(...child.children)
          // child.children.forEach((child_el) => {
          //     if (child_el.name == 'Bangalore' && child_el.subfolder == "cltban") {
          //         child_el.children = checkRemarks(['cltban'], [])
          //         mappedFiles.push(...child_el.children)
          //     } else if (child_el.name == 'Chennai' && child_el.subfolder == "cltche") {
          //         child_el.children = checkRemarks(['cltche'], [])
          //         mappedFiles.push(...child_el.children)
          //     } else if (child_el.name == 'Delhi' && child_el.subfolder == "cltdel") {
          //         child_el.children = checkRemarks(['cltdel'], [])
          //         mappedFiles.push(...child_el.children)
          //     } else if (child_el.name == 'Kolkata' && child_el.subfolder == "cltkol") {
          //         child_el.children = checkRemarks(['cltkol'], [])
          //         mappedFiles.push(...child_el.children)
          //     } else if (child_el.name == 'Mumbai' && child_el.subfolder == "cltmum") {
          //         child_el.children = checkRemarks(['cltmum'], [])
          //         mappedFiles.push(...child_el.children)
          //     } else if (child_el.name == 'Hyderabad' && child_el.subfolder == "clthyd") {
          //         child_el.children = checkRemarks(['clthyd'], [])
          //         mappedFiles.push(...child_el.children)
          //     } else if (child_el.name == 'Others' && child_el.subfolder == "cltrib") {
          //         child_el.children = checkRemarks(['cltrib','cltahm'], [])
          //         mappedFiles.push(...child_el.children)
          //     } else if (child_el.name == 'Larger Bench' && child_el.subfolder == "cltlar") {
          //         child_el.children = checkRemarks(['cltlar'], [])
          //         mappedFiles.push(...child_el.children)
          //     }
          // })
          // } else if (child.name == 'NAPA' && child.subfolder == "clnapa") {
          //     let gtCl = checkRemarks(['clnapa'], [])
          //     child.children = [...gtCl]
          //     mappedFiles.push(...child.children)
          // }
          // else if (child.name == 'Others' && child.subfolder == "cloths") {
          //     child.children = checkRemarks(['cloths'], [])
          //     mappedFiles.push(...child.children)
          // }
          // })
        } else if (
          parent.name == "Advance Ruling" &&
          (this.state.targetField == "Case Law & Advance Ruling" ||
            this.state.targetSubField == "All")
        ) {
          // parent.children.forEach((child) => {
          //     if (child.name == 'AAR' && child.subfolder == "claarc") {
          //         child.children = checkRemarks(['claarc'], [])
          //         mappedFiles.push(...child.children)
          //     } else if (child.name == 'AAAR' && child.subfolder == "claaar") {
          //         child.children = checkRemarks(['claaar'], [])
          //         mappedFiles.push(...child.children)
          //     }
          // })
          parent.children = checkRemarks(["claarc", "claaar", "cuaarc"], []);
          mappedFiles.push(...parent.children);
        }
        //  else if (parent.name == "Question Bank") {
        //     parent.children.forEach((child) => {
        //         if (child.name == 'CBIC FAQ' && child.subfolder == "gtfaqb") {
        //             child.children = checkRemarks(['gtfaqb'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Ask-A-Question' && child.subfolder == "gtillu") {
        //             child.children = checkRemarks(['gtillu'], [])
        //             mappedFiles.push(...child.children)
        //         }
        //     })
        // } else if (parent.name == 'FEMA') {
        //     parent.children.forEach((child) => {
        //         if (child.name == 'Act' && child.subfolder == "rbacts") {
        //             child.children = checkRemarks(['rbacts'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Rules' && child.subfolder == "rbrule") {
        //             child.children = checkRemarks(['rbrule'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Circular' && child.subfolder == "rbcirc") {
        //             child.children = checkRemarks(['rbcirc'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Notification' && child.subfolder == "rbnoti") {
        //             child.children = checkRemarks(['rbnoti'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Regulations' && child.subfolder == "rbregu") {
        //             child.children = checkRemarks(['rbregu'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Citizen Charter' && child.subfolder == "rbciti") {
        //             child.children = checkRemarks(['rbciti'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'Form' && child.subfolder == "rbform") {
        //             child.children = checkRemarks(['rbform'], [])
        //             mappedFiles.push(...child.children)
        //         } else if (child.name == 'RBI FAQ' && child.subfolder == "rbfaqb") {
        //             child.children = checkRemarks(['rbfaqb'], [])
        //             mappedFiles.push(...child.children)
        //         }
        //     })
        // }
        else if (parent.name == "Unmapped Files") {
          parent.children = unmappedFiles(input, mappedFiles);
        }
      });

      // const getTotalCount = (nodes) => {
      //     let total = 0
      //     nodes.map((node) => {
      //         // if (node.nodeid != '1.10') {
      //         console.log(node)
      //         let node_files_count = this.getLeafCount(node)
      //         console.log(node_files_count)
      //         total += node_files_count
      //         // }
      //     })
      //     this.setState({ totalFilesCount: total })
      // }
      const getTotalCount = (nodes) => {
        let total = 0;
        nodes.map((node) => {
          let node_files_count = this.getLeafCount(node);
          console.log(node_files_count);
          total += node_files_count;
        });
        this.setState({ totalFilesCount: total });
      };
      getTotalCount(newtree);
      this.setState({ newTreeData: newtree });
      // console.log(newtree, map, mappedFiles, input)
    }
  }

  // Call when searching through both content & perameters
  onCombineSearch = (allfiles, searchWord, url) =>
    new Promise((resolve, reject) => {
      let obj = {
        allfiles: allfiles.join("##12##"),
        url: url,
        search: `${searchWord}`,
        searchType: `${this.state.searchType}`,
      };
      console.log(obj);
      Hexapi(obj)
        .then((resp) => {
          console.log(resp);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });

  // Call when only serching through parameters
  onParametricSearch = (allfiles, url, folderpath) =>
    new Promise((resolve, reject) => {
      this.setState({
        isHistoryOpen: false,
        openInitialModal: false,
        isFetchFileApiHitComplete: false,
        isFirstSearchComplete: true,
        isApiHitComplete: false,
        isDataFound: false,
        selectedFile: "",
      });
      let obj = {
        allfiles: allfiles,
        url: url,
        filename: folderpath,
      };
      Hexapi(obj)
        .then((resp) => {
          console.log(resp);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });

  // Call when showing popup of No record found
  noRecordFound() {
    if (this.state.allData.length > 0) {
      Swal.fire({
        text: "No Record Found",
      }).then(() => {
        this.setState({ isDataFound: true });
      });
    } else {
      this.createTree([]);
      this.setState({ isDataFound: true });
    }
  }

  refined_combine_search(searchWord, searchurl) {
    // This if part works on refined search + content search + parametric search
    this.setState({
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      selectedFile: "",
    });
    // let refinedArr = this.state.allData.filter((el) => el.count != 0)
    // let nonRefinedArr = this.state.allData.filter((el) => el.count == 0)

    const refinedArr = this.state.allData;
    console.log(refinedArr);
    let filesArr = [];
    refinedArr.forEach((el) => {
      if (el.filepath.includes("/cl/")) {
        filesArr.push(
          el.filepath.substring(el.filepath.lastIndexOf("/") + 1).split(".")[0]
        );
      }
    });
    console.log(filesArr);
    if (filesArr.length > 0) {
      let obj = {
        query: `[dbo].[Lawcrux_Web_Proc_RefinedSearch_GetData]
        @Files='{0}',
        @JudgeID='{1}',
        @PartyID='{2}',
        @Landmark='{3}',
        @Order='{4}',
        @Citation='{5}',
        @CitationValue='{6}',
        @FromDate='{7}',
        @ToDate='{8}',
        @Court='{9}',
        @Favour='{10}'`,
        queryArr: [
          `${filesArr.join("##12##")}`,
          `${this.state.paramSearchObj.judge}`,
          `${this.state.paramSearchObj.party}`,
          `${this.state.paramSearchObj.landmark}`,
          `${this.state.paramSearchObj.order}`,
          `${this.state.paramSearchObj.citation}`,
          `${this.state.paramSearchObj.citationcode}`,
          `${this.state.paramSearchObj.fromdate}`,
          `${this.state.paramSearchObj.todate}`,
          `${this.state.paramSearchObj.court}`,
          `${this.state.paramSearchObj.favour}`,
        ],
      };
      Hexapi(obj).then((resp) => {
        console.log(resp);
        let array_comes_from_paramSearch = [];
        let keys = Object.keys(resp);
        console.log(keys);
        for (let i = 0; i < keys.length; i++) {
          let rs = resp[keys[i]];
          for (let j = 0; j < rs.length; j++) {
            let record = rs[j].code + ".htm";
            array_comes_from_paramSearch.push(record);
          }
        }
        if (array_comes_from_paramSearch.length > 0) {
          let obj = {
            search: `${searchWord}`,
            searchType: `${this.state.searchType}`,
            url: this.props.searchApi,
            searchurl: searchurl,
          };
          Hexapi(obj)
            .then((re) => {
              if (re.length > 0) {
                let array_comes_from_contentSearch = re;
                array_comes_from_contentSearch.forEach((data) => {
                  if (data.filepath.includes("\\")) {
                    data.filepath = data.filepath
                      .split("\\Web\\")[1]
                      .replaceAll("\\", "/");
                  }
                });
                console.log(
                  array_comes_from_contentSearch,
                  array_comes_from_paramSearch,
                  refinedArr
                );
                var common = refinedArr.filter(
                  (file) =>
                    array_comes_from_paramSearch.some((filename) =>
                      file.filepath.includes(filename)
                    ) &&
                    array_comes_from_contentSearch.some((newfile) =>
                      file.filepath.includes(newfile.filepath)
                    )
                );

                console.log(common);
                this.createTree(common);

                // var totalFilesCount = 0
                // for (let i = 0; i < resp.length; i++) {
                //     totalFilesCount += parseInt(resp[i].count)
                // }
                this.setState({ allData: common, isDataFound: true }, () => {
                  // console.log(this.state.treeData)
                });
              } else {
                this.setState(
                  { isApiHitComplete: true, paramSearchObj: null },
                  () => this.noRecordFound()
                );
              }
            })
            .then(() =>
              this.setState({
                isApiHitComplete: true,
                paramSearchObj: null,
                isTimerModal: false,
                searchPath: "Assets/Data5t/",
                selectedJudge: "",
              })
            )
            .catch((err) => {
              console.error(err);
              document.getElementById("loadingTimer").innerHTML =
                "Something went wrong. Please try again.";
            });
          // this.onCombineSearch(jsonArr, searchWord, this.state.Combinesearchurl).then(re => {
          //     if (re.length > 0) {
          //         const input = [...re, ...nonRefinedArr]
          //         var totalFilesCount = 0
          //         for (let i = 0; i < re.length; i++) {
          //             totalFilesCount += parseInt(re[i].count)
          //         }
          //         this.setState({ totalFilesCount: totalFilesCount, treeData: this.createTree(input), isDataFound: true }, () => {
          //             console.log(this.state.treeData)
          //         })
          //     } else {
          //         this.noRecordFound()
          //     }
          // }).then(res => this.setState({ isApiHitComplete: true, paramSearchObj: null })).catch(err => console.error(err))
        } else {
          this.setState({ isApiHitComplete: true, paramSearchObj: null }, () =>
            this.noRecordFound()
          );
        }
      });
    } else {
      this.setState({ isApiHitComplete: true, paramSearchObj: null }, () =>
        this.noRecordFound()
      );
    }
  }

  refined_content_search(searchWord, searchurl) {
    // This if part works only refined search + content search
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });

    console.log(this.state.allData);
    const refinedArr = this.state.allData;

    let obj = {
      search: `${searchWord}`,
      searchType: `${this.state.searchType}`,
      url: this.props.searchApi,
      searchurl: searchurl,
    };

    Hexapi(obj)
      .then((allfiles) => {
        // console.log(allfiles, refinedArr)
        if (allfiles.length > 0) {
          const input = allfiles;
          // for(let i=0;i<input.length;i++){
          //     let filepath = input[i].filepath
          //     if(filepath.includes('\\')){
          //         filepath = filepath.split('\\Web\\')[1]
          //     }
          // }
          var newPromise = new Promise((resolve, reject) => {
            input.forEach((data) => {
              if (data.filepath.includes("\\")) {
                data.filepath = data.filepath
                  .split("\\Web\\")[1]
                  .replaceAll("\\", "/");
              }
            });
            // console.log(input,refinedArr)
            resolve(input);
          });

          newPromise.then((d) => {
            let common = refinedArr.filter((fileObj) =>
              d.some((refinedObj) =>
                fileObj.filepath.includes(refinedObj.filepath)
              )
            );

            console.log(common);
            this.createTree(common);

            // var totalFilesCount = 0
            // for (let i = 0; i < resp.length; i++) {
            //     totalFilesCount += parseInt(resp[i].count)
            // }
            this.setState({ allData: common, isDataFound: true }, () => {
              // console.log(this.state.treeData)
            });
          });
          // console.log(input)
          // console.log(this.state.newTreeData)
        } else {
          this.createTree([]);
          this.setState({ isDataFound: true });
        }
      })
      .then(() =>
        this.setState({
          isApiHitComplete: true,
          isTimerModal: false,
          searchPath: "Assets/Data5t/",
          isRefinedSearch: false,
        })
      )
      .catch((err) => {
        console.error(err);
        document.getElementById("loadingTimer").innerHTML =
          "Something went wrong. Please try again.";
      });
  }

  refined_judge_search(searchurl) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });

    console.log(this.state.allData);
    const refinedArr = this.state.allData;

    let obj = {
      search: `${this.state.selectedJudge}`,
      searchType: `2`,
      url: this.props.searchApi,
      searchurl: searchurl,
    };
    Hexapi(obj)
      .then((resp) => {
        console.log(resp);

        if (resp.length > 0) {
          const input = resp;
          var newPromise = new Promise((resolve, reject) => {
            input.forEach((data) => {
              if (data.filepath.includes("\\")) {
                data.filepath = data.filepath
                  .split("\\Web\\")[1]
                  .replaceAll("\\", "/");
              }
            });
            resolve(input);
          });

          newPromise.then((d) => {
            let common = refinedArr.filter((fileObj) =>
              d.some((refinedObj) =>
                fileObj.filepath.includes(refinedObj.filepath)
              )
            );

            console.log(common);
            this.createTree(common);

            this.setState({ allData: common, isDataFound: true }, () => {
              // console.log(this.state.treeData)
            });
          });
        } else {
          this.createTree([]);
          this.setState({ isDataFound: true });
        }
      })
      .then(() =>
        this.setState({
          isApiHitComplete: true,
          isTimerModal: false,
          searchPath: "Assets/Data5t/",
          selectedJudge: "",
        })
      )
      .catch((err) => {
        console.error(err);
        document.getElementById("loadingTimer").innerHTML =
          "Something went wrong. Please try again.";
      });
  }

  refined_parametric_search() {
    // this.setState({ isOnlyParamSearch: false, isHistoryOpen: false, openInitialModal: false, isFetchFileApiHitComplete: false, isFirstSearchComplete: true, isApiHitComplete: false, selectedFile: '' })
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });

    console.log(this.state.allData);
    const refinedArr = this.state.allData;

    // let refinedArr = this.state.allData.filter((el) => el.count != 0)
    // let nonRefinedArr = this.state.allData.filter((el) => el.count == 0)
    console.log(refinedArr);
    let filesArr = [];
    refinedArr.forEach((el) => {
      if (el.filepath.includes("/cl/")) {
        filesArr.push(
          el.filepath.substring(el.filepath.lastIndexOf("/") + 1).split(".")[0]
        );
      }
    });
    console.log(filesArr);
    if (filesArr.length > 0) {
      let obj = {
        query: `[dbo].[Lawcrux_Web_Proc_RefinedSearch_GetData]
        @Files='{0}',
        @JudgeID='{1}',
        @PartyID='{2}',
        @Landmark='{3}',
        @Order='{4}',
        @Citation='{5}',
        @CitationValue='{6}',
        @FromDate='{7}',
        @ToDate='{8}',
        @Court='{9}',
        @Favour='{10}'`,
        queryArr: [
          `${filesArr.join("##12##")}`,
          `${this.state.paramSearchObj.judge}`,
          `${this.state.paramSearchObj.party}`,
          `${this.state.paramSearchObj.landmark}`,
          `${this.state.paramSearchObj.order}`,
          `${this.state.paramSearchObj.citation}`,
          `${this.state.paramSearchObj.citationcode}`,
          `${this.state.paramSearchObj.fromdate}`,
          `${this.state.paramSearchObj.todate}`,
          `${this.state.paramSearchObj.court}`,
          `${this.state.paramSearchObj.favour}`,
        ],
      };
      Hexapi(obj)
        .then((resp) => {
          console.log(resp);
          let jsonArr = [];
          let keys = Object.keys(resp);
          console.log(keys);
          for (let i = 0; i < keys.length; i++) {
            let rs = resp[keys[i]];
            for (let j = 0; j < rs.length; j++) {
              let record = rs[j].code + ".htm";
              jsonArr.push(record);
            }
          }
          if (jsonArr.length > 0) {
            console.log(refinedArr, jsonArr);
            let common = refinedArr.filter((fileObj) =>
              jsonArr.some((filename) => fileObj.filepath.includes(filename))
            );

            console.log(common);
            this.createTree(common);

            // var totalFilesCount = 0
            // for (let i = 0; i < resp.length; i++) {
            //     totalFilesCount += parseInt(resp[i].count)
            // }
            this.setState({ allData: common, isDataFound: true }, () => {
              // console.log(this.state.treeData)
            });

            // this.onParametricSearch(jsonArr.join('##12##'), this.state.ParametericsearchUrl).then(re => {
            //     if (re.length > 0) {
            //         const input = [...re, ...nonRefinedArr]
            //         const allfiles = [...re, ...nonRefinedArr]
            //         var totalFilesCount = 0
            //         for (let i = 0; i < re.length; i++) {
            //             totalFilesCount += parseInt(re[i].count)
            //         }
            //         this.setState({ totalFilesCount: totalFilesCount, treeData: this.createTree(input), isDataFound: true }, () => {
            //             console.log(this.state.treeData)
            //         })
            //     } else {
            //         this.setState({ isDataFound: false })
            //     }
            // }).then(() => this.setState({ isApiHitComplete: true, paramSearchObj: null })).catch(err => console.error(err))
          } else {
            this.setState(
              {
                isApiHitComplete: true,
                isTimerModal: false,
                paramSearchObj: null,
              },
              () => this.noRecordFound()
            );
          }
        })
        .then(() =>
          this.setState({
            isApiHitComplete: true,
            paramSearchObj: null,
            isTimerModal: false,
            searchPath: "Assets/Data5t/",
            selectedJudge: "",
          })
        )
        .catch((err) => {
          console.error(err);
          document.getElementById("loadingTimer").innerHTML =
            "Something went wrong. Please try again.";
        });
    } else {
      this.setState(
        { isApiHitComplete: true, isTimerModal: false, paramSearchObj: null },
        () => this.noRecordFound()
      );
    }
  }

  refined_judge_and_content_search(searchWord, searchurl) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });

    console.log(this.state.allData);
    const refinedArr = this.state.allData;

    let obj = {
      search: `${searchWord}`,
      searchType: `${this.state.searchType}`,
      url: this.props.searchApi,
      searchurl: searchurl,
    };
    Hexapi(obj).then((files) => {
      // console.log(resp)
      if (files.length > 0) {
        var array_comes_from_contentSearch = files;
        array_comes_from_contentSearch.forEach((data) => {
          if (data.filepath.includes("\\")) {
            data.filepath = data.filepath
              .split("\\Web\\")[1]
              .replaceAll("\\", "/");
          }
        });

        // let array_comes_from_contentSearch = files
        let obj = {
          search: `${this.state.selectedJudge}`,
          searchType: `2`,
          url: this.props.searchApi,
          searchurl: searchurl,
        };
        Hexapi(obj)
          .then((judges) => {
            // console.log(resp)
            if (judges.length > 0) {
              let array_comes_from_judgeSearch = judges;
              array_comes_from_judgeSearch.forEach((data) => {
                if (data.filepath.includes("\\")) {
                  data.filepath = data.filepath
                    .split("\\Web\\")[1]
                    .replaceAll("\\", "/");
                }
              });
              console.log(
                array_comes_from_contentSearch,
                array_comes_from_judgeSearch,
                refinedArr
              );
              var common = refinedArr.filter(
                (file) =>
                  array_comes_from_judgeSearch.some((newfile) =>
                    file.filepath.includes(newfile.filepath)
                  ) &&
                  array_comes_from_contentSearch.some((newfile) =>
                    file.filepath.includes(newfile.filepath)
                  )
              );

              console.log(common);
              this.createTree(common);

              // var totalFilesCount = 0
              // for (let i = 0; i < resp.length; i++) {
              //     totalFilesCount += parseInt(resp[i].count)
              // }
              this.setState({ allData: common, isDataFound: true }, () => {
                // console.log(this.state.treeData)
              });
            } else {
              this.setState(
                {
                  isApiHitComplete: true,
                  isTimerModal: false,
                  paramSearchObj: null,
                  selectedJudge: "",
                },
                () => this.noRecordFound()
              );
            }
          })
          .then(() =>
            this.setState({
              isApiHitComplete: true,
              paramSearchObj: null,
              isTimerModal: false,
              searchPath: "Assets/Data5t/",
              selectedJudge: "",
            })
          )
          .catch((err) => {
            console.error(err);
            document.getElementById("loadingTimer").innerHTML =
              "Something went wrong. Please try again.";
          });
      } else {
        this.setState(
          {
            isApiHitComplete: true,
            isTimerModal: false,
            paramSearchObj: null,
            selectedJudge: "",
          },
          () => this.noRecordFound()
        );
      }
    });
  }

  normal_combine_search(obj, searchWord, searchurl, isClOrAdv) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    // this.setState({ isOnlyParamSearch: false, isHistoryOpen: false, openInitialModal: false, isFetchFileApiHitComplete: false, isFirstSearchComplete: true, isApiHitComplete: false, isDataFound: false, selectedFile: '' })
    console.log(this.state.paramSearchObj);
    if (isClOrAdv != undefined) {
      Hexapi(obj)
        .then((resp) => {
          console.log(resp);
          let files_comes_from_paramSearch = resp;
          // let keys = Object.keys(resp)
          // console.log(keys)
          // for (let i = 0; i < keys.length; i++) {
          //     let rs = resp[keys[i]];
          //     if (rs.length > 0) {
          //         for (let j = 0; j < rs.length; j++) {
          //             let record = rs[j].code + '.htm'+`${keys[i]}`;
          //             files_comes_from_paramSearch.push(record)
          //         }
          //     }
          // }
          console.log(files_comes_from_paramSearch);
          if (Object.keys(files_comes_from_paramSearch).length > 0) {
            if (searchWord != "") {
              let obj = {
                search: `${searchWord}`,
                searchType: `${this.state.searchType}`,
                url: this.props.searchApi,
                searchurl: searchurl,
              };
              Hexapi(obj)
                .then((files) => {
                  let files_comes_from_contentSearch = files;
                  console.log(files_comes_from_contentSearch);
                  // let common = files_comes_from_contentSearch.filter(file => files_comes_from_paramSearch.includes(file))
                  let common = [];
                  let caseLawsFiles = files_comes_from_contentSearch.filter(
                    (fileObj) => fileObj.filepath.includes("\\cl\\")
                  );
                  let withoutCaseLawsFiles =
                    files_comes_from_contentSearch.filter(
                      (fileObj) => !fileObj.filepath.includes("\\cl\\")
                    );
                  common.push(...withoutCaseLawsFiles);
                  // // if()
                  let keys = Object.keys(files_comes_from_paramSearch);
                  console.log(keys);
                  for (let i = 0; i < keys.length; i++) {
                    let rs = resp[keys[i]];
                    console.log(rs);
                    if (rs.length > 0) {
                      // for (let j = 0; j < rs.length; j++) {
                      //   //  let record = rs[j].code + '.htm'// + `${keys[i]}`;
                      //     let filtered = caseLawsFiles.filter((fileObj) => rs.some((spliter) => fileObj.filepath.toLowerCase().includes(spliter.toLowerCase()+'.htm')))
                      //     console.log(filtered)
                      //     // common.push(...caseLawsFiles.filter((fileObj) => record.some((spliter) => fileObj.filepath.toLowerCase().includes(spliter.toLowerCase()))))
                      //     // files_comes_from_paramSearch.push(record)
                      // }
                      let filtered = caseLawsFiles
                        .filter((fileObj) =>
                          rs.some((spliter) =>
                            fileObj.filepath.includes(spliter.code)
                          )
                        )
                        .map((el) => {
                          let obj = { filepath: el.filepath + `${keys[i]}` };
                          return obj;
                        });
                      console.log(filtered);
                      common.push(...filtered);
                    }
                  }
                  // common.push(...caseLawsFiles.filter((fileObj) => files_comes_from_paramSearch.some((spliter) => fileObj.filepath.toLowerCase().includes(spliter.toLowerCase()))))
                  console.log("CaseLawsfiles=>", caseLawsFiles);
                  console.log("withoutCaseLawsFiles=>", withoutCaseLawsFiles);
                  console.log("Tree Array => ", common);

                  if (common.length > 0) {
                    const input = common;
                    input.forEach((data) => {
                      if (data.filepath.includes("\\")) {
                        data.filepath = data.filepath
                          .split("\\Web\\")[1]
                          .replaceAll("\\", "/");
                      }
                    });

                    // this function creating tree structure
                    this.createTree(input);

                    this.setState({ allData: common, isDataFound: true });
                  } else {
                    this.createTree([]);
                    this.setState({ isDataFound: true });
                  }
                })
                .then(() =>
                  this.setState({
                    isApiHitComplete: true,
                    isTimerModal: false,
                    paramSearchObj: null,
                    searchPath: "Assets/Data5t/",
                  })
                )
                .catch((err) => {
                  console.error(err);
                  document.getElementById("loadingTimer").innerHTML =
                    "Something went wrong. Please try again.";
                });
            } else {
              this.onParametricSearch(
                "",
                this.state.ParametericsearchUrl,
                searchurl
              )
                .then((files) => {
                  let files_comes_from_contentSearch = files;
                  console.log(files_comes_from_contentSearch);
                  // let common = files_comes_from_contentSearch.filter(file => files_comes_from_paramSearch.includes(file))
                  let common = [];
                  let caseLawsFiles = files_comes_from_contentSearch;
                  // let withoutCaseLawsFiles = files_comes_from_contentSearch.filter((fileObj) => !fileObj.filepath.includes('\\cl\\'))
                  // common.push(...withoutCaseLawsFiles)
                  // // if()
                  let keys = Object.keys(files_comes_from_paramSearch);
                  console.log(keys);
                  for (let i = 0; i < keys.length; i++) {
                    let rs = resp[keys[i]];
                    console.log(rs);
                    if (rs.length > 0) {
                      // for (let j = 0; j < rs.length; j++) {
                      //   //  let record = rs[j].code + '.htm'// + `${keys[i]}`;
                      //     let filtered = caseLawsFiles.filter((fileObj) => rs.some((spliter) => fileObj.filepath.toLowerCase().includes(spliter.toLowerCase()+'.htm')))
                      //     console.log(filtered)
                      //     // common.push(...caseLawsFiles.filter((fileObj) => record.some((spliter) => fileObj.filepath.toLowerCase().includes(spliter.toLowerCase()))))
                      //     // files_comes_from_paramSearch.push(record)
                      // }
                      let filtered = caseLawsFiles
                        .filter((fileObj) =>
                          rs.some((spliter) =>
                            fileObj.filepath.includes(spliter.code)
                          )
                        )
                        .map((el) => {
                          let obj = { filepath: el.filepath + `${keys[i]}` };
                          return obj;
                        });
                      console.log(filtered);
                      common.push(...filtered);
                    }
                  }
                  // common.push(...caseLawsFiles.filter((fileObj) => files_comes_from_paramSearch.some((spliter) => fileObj.filepath.toLowerCase().includes(spliter.toLowerCase()))))
                  console.log("CaseLawsfiles=>", caseLawsFiles);
                  // console.log('withoutCaseLawsFiles=>', withoutCaseLawsFiles)
                  console.log("Tree Array => ", common);

                  if (common.length > 0) {
                    const input = common;
                    input.forEach((data) => {
                      if (data.filepath.includes("\\")) {
                        data.filepath = data.filepath
                          .split("\\Web\\")[1]
                          .replaceAll("\\", "/");
                      }
                    });

                    // this function creating tree structure
                    this.createTree(input);

                    this.setState({ allData: common, isDataFound: true });
                  } else {
                    this.createTree([]);
                    this.setState({ isDataFound: true });
                  }
                })
                .then(() =>
                  this.setState({
                    isApiHitComplete: true,
                    isTimerModal: false,
                    paramSearchObj: null,
                    searchPath: "Assets/Data5t/",
                  })
                )
                .catch((err) => {
                  console.error(err);
                  document.getElementById("loadingTimer").innerHTML =
                    "Something went wrong. Please try again.";
                });
            }

            // .then(res => this.setState({ isApiHitComplete: true, paramSearchObj: null })).catch(err => console.error(err))

            // this.onCombineSearch(files_comes_from_paramSearch, searchWord, this.state.Combinesearchurl).then(re => {
            //     if (re.length > 0) {
            //         const input = re
            //         var totalFilesCount = 0
            //         for (let i = 0; i < re.length; i++) {
            //             totalFilesCount += parseInt(re[i].count)
            //         }
            //         this.setState({ allData: re, totalFilesCount: totalFilesCount, treeData: this.createTree(input), isDataFound: true }, () => {
            //             console.log(this.state.treeData)
            //         })
            //     } else {
            //         this.setState({ isDataFound: false })
            //     }
            // }).then(res => this.setState({ isApiHitComplete: true, paramSearchObj: null })).catch(err => console.error(err))
          } else {
            this.setState({
              isApiHitComplete: true,
              isTimerModal: false,
              paramSearchObj: null,
              searchPath: "Assets/Data5t/",
            });
          }

          // this.props.onSearch(jsonArr.join('##12##'),this.state.ParametericsearchUrl)
          // this.props.onCloseModal()
        })
        .catch((err) => console.error(err));
    } else {
      let clKey = "";
      if (this.state.selectedField == "GT") {
        clKey = "gt@cl";
      } else if (this.state.selectedField == "CU") {
        clKey = "cu@cl";
      } else if (this.state.selectedField == "ST") {
        clKey = "st@cl";
      } else if (this.state.selectedField == "CE") {
        clKey = "ce@cl";
      } else if (this.state.selectedField == "-1") {
        clKey = "gt@cl";
      }

      console.log(clKey);
      Hexapi(obj)
        .then((resp) => {
          console.log(resp);
          let files_comes_from_paramSearch = {};
          files_comes_from_paramSearch[clKey] = resp[""];
          // let keys = Object.keys(resp)
          // console.log(keys)
          // for (let i = 0; i < keys.length; i++) {
          //     let rs = resp[keys[i]];
          //     for (let j = 0; j < rs.length; j++) {
          //         let record = rs[j].code + '.htm';
          //         files_comes_from_paramSearch.push(record)
          //     }
          // }
          console.log(files_comes_from_paramSearch);
          if (Object.keys(files_comes_from_paramSearch).length > 0) {
            let obj = {
              search: `${searchWord}`,
              searchType: `${this.state.searchType}`,
              url: this.props.searchApi,
              searchurl: searchurl,
            };
            Hexapi(obj)
              .then((files) => {
                // let files_comes_from_contentSearch = files
                // console.log(files_comes_from_contentSearch)
                // // let common = files_comes_from_contentSearch.filter(file => files_comes_from_paramSearch.includes(file))
                // let common = files_comes_from_contentSearch.filter((fileObj) => files_comes_from_paramSearch.some((spliter) => fileObj.filepath.toLowerCase().includes(spliter)))
                // console.log('Tree Array => ', common)

                // if (common.length > 0) {
                //     const input = common
                //     input.forEach((data) => {
                //         if (data.filepath.includes('\\')) {
                //             data.filepath = data.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
                //         }
                //     })

                //     // this function creating tree structure
                //     this.createTree(input)

                //     this.setState({ allData: common, isDataFound: true })
                // } else {
                //     this.setState({ isDataFound: false })
                // }

                let files_comes_from_contentSearch = files;
                console.log(files_comes_from_contentSearch);
                // let common = files_comes_from_contentSearch.filter(file => files_comes_from_paramSearch.includes(file))
                let common = [];
                let caseLawsFiles = files_comes_from_contentSearch.filter(
                  (fileObj) => fileObj.filepath.includes("\\cl\\")
                );
                let withoutCaseLawsFiles =
                  files_comes_from_contentSearch.filter(
                    (fileObj) => !fileObj.filepath.includes("\\cl\\")
                  );
                common.push(...withoutCaseLawsFiles);
                console.log(caseLawsFiles);
                // // if()
                let keys = Object.keys(files_comes_from_paramSearch);
                console.log(keys);
                for (let i = 0; i < keys.length; i++) {
                  let rs = files_comes_from_paramSearch[keys[i]];
                  for (let j = 0; j < rs.length; j++) {
                    rs[j].code = "/" + rs[j].code + ".htm";
                  }
                  console.log(rs);
                  if (rs.length > 0) {
                    let filtered = caseLawsFiles
                      .filter((fileObj) => {
                        // let filepath = fileObj.filepath.replaceAll()
                        if (fileObj.filepath.includes("\\")) {
                          fileObj.filepath = fileObj.filepath
                            .split("\\Web\\")[1]
                            .replaceAll("\\", "/");
                        }
                        return rs.some((spliter) =>
                          fileObj.filepath.includes(spliter.code)
                        );
                      })
                      .map((el) => {
                        console.log(el);
                        let obj = { filepath: el.filepath + `${keys[i]}` };
                        return obj;
                      });
                    console.log(filtered);
                    common.push(...filtered);
                  }
                }
                // common.push(...caseLawsFiles.filter((fileObj) => files_comes_from_paramSearch.some((spliter) => fileObj.filepath.toLowerCase().includes(spliter.toLowerCase()))))
                console.log("CaseLawsfiles=>", caseLawsFiles);
                console.log("withoutCaseLawsFiles=>", withoutCaseLawsFiles);
                console.log("Tree Array => ", common);

                if (common.length > 0) {
                  const input = common;
                  input.forEach((data) => {
                    if (data.filepath.includes("\\")) {
                      data.filepath = data.filepath
                        .split("\\Web\\")[1]
                        .replaceAll("\\", "/");
                    }
                  });

                  // this function creating tree structure
                  this.createTree(input);

                  this.setState({ allData: common, isDataFound: true });
                } else {
                  this.createTree([]);
                  this.setState({ isDataFound: true });
                }
              })
              .then(() =>
                this.setState({
                  isApiHitComplete: true,
                  isTimerModal: false,
                  paramSearchObj: null,
                  searchPath: "Assets/Data5t/",
                })
              )
              .catch((err) => {
                console.error(err);
                document.getElementById("loadingTimer").innerHTML =
                  "Something went wrong. Please try again.";
              });
            // .then(res => this.setState({ isApiHitComplete: true, paramSearchObj: null })).catch(err => console.error(err))

            // this.onCombineSearch(files_comes_from_paramSearch, searchWord, this.state.Combinesearchurl).then(re => {
            //     if (re.length > 0) {
            //         const input = re
            //         var totalFilesCount = 0
            //         for (let i = 0; i < re.length; i++) {
            //             totalFilesCount += parseInt(re[i].count)
            //         }
            //         this.setState({ allData: re, totalFilesCount: totalFilesCount, treeData: this.createTree(input), isDataFound: true }, () => {
            //             console.log(this.state.treeData)
            //         })
            //     } else {
            //         this.setState({ isDataFound: false })
            //     }
            // }).then(res => this.setState({ isApiHitComplete: true, paramSearchObj: null })).catch(err => console.error(err))
          } else {
            this.setState({ isApiHitComplete: true, paramSearchObj: null });
            this.createTree([]);
            this.setState({ isDataFound: true });
          }

          // this.props.onSearch(jsonArr.join('##12##'),this.state.ParametericsearchUrl)
          // this.props.onCloseModal()
        })
        .catch((err) => console.error(err));
    }
  }

  normal_folder_search(searchurl) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    let obj = {
      url: "foldersearch.php",
      searchurl: searchurl.join("##"),
    };
    Hexapi(obj)
      .then((resp) => {
        console.log(resp);
        if (resp.length > 0) {
          const input = resp;
          input.forEach((data) => {
            data.filepath = data.filepath + "gt@cl";
          });
          // console.log(input)
          this.createTree(input);
          this.setState({ allData: resp, isDataFound: true });
        } else {
          this.createTree([]);
          this.setState({ isDataFound: true });
        }
      })
      .then(() =>
        this.setState({
          isApiHitComplete: true,
          isTimerModal: false,
          searchPath: "Assets/Data5t/",
        })
      )
      .catch((err) => {
        console.error(err);
        document.getElementById("loadingTimer").innerHTML =
          "Something went wrong. Please try again.";
      });
    // .then(res => this.setState({ isApiHitComplete: true })).catch(err => console.error(err))
  }

  refined_normal_content_search(searchWord, searchurl) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    const refinedArr = this.state.allData;

    if (this.state.internalsearch.length > 0) {
      var len = this.state.internalsearch.length;
      if (this.state.searchcount > 0 && this.state.searchcount < len) {
        const settypeno =
          this.state.searchtypeValue[len - this.state.searchcount - 1];
        this.setState({ searchType: settypeno });
      }
    }

    setTimeout(() => {
      let obj = {
        search: `${searchWord}`,
        searchType: `${this.state.searchType}`,
        url: this.props.searchApi,
        searchurl: searchurl,
      };
      console.log(obj);
      Hexapi(obj)
        .then((resp) => {
          console.log(resp);

          if (resp) {
            var files = [];
            var nisfiles = [];
            for (var i = 0; i < resp.length; i++) {
              for (const key in resp[i]) {
                if (key.includes("nisfilepath")) {
                  nisfiles.push(resp[i]);
                } else {
                  files.push(resp[i]);
                }
              }
            }
            this.setState({ nisfiles: nisfiles });

            console.log(files);
            console.log(nisfiles);
            const input = files;
            // for(let i=0;i<input.length;i++){
            //     let filepath = input[i].filepath
            //     if(filepath.includes('\\')){
            //         filepath = filepath.split('\\Web\\')[1]
            //     }
            // }
            input.forEach((data) => {
              if (data.filepath.includes("\\")) {
                data.filepath = data.filepath
                  .split("\\Web\\")[1]
                  .replaceAll("\\", "/");
              }
            });
            // console.log(input)
            // console.log(this.state.newTreeData)

            // this.setState({ newTreeData: this.copyNewTree }, () => {
            //     console.log(this.state.newTreeData)

            // This part is causing trouble
            // console.log(common)
            // })

            // All results are storing here

            if (this.state.arrowsearch == false) {
              this.setState(
                (prevState) => {
                  const { setsearchcount, firstsearchresult } = prevState;
                  const updatedResults = [...firstsearchresult];
                  const files = updatedResults[setsearchcount - 2].filter(
                    (obj1) =>
                      input.some((obj2) => obj1.filepath == obj2.filepath)
                  );
                  updatedResults[setsearchcount - 1] = files;
                  return { firstsearchresult: updatedResults };
                },
                () => {
                  console.log(this.state.firstsearchresult);
                }
              );
              // this.setState({ arrowsearch: false });
            }

            let common = [];

            // arrow searching forward & backward
            const len = this.state.firstsearchresult.length;
            if (
              this.state.searchcount >= 0 &&
              this.state.searchcount < len &&
              this.state.arrowsearch == true
            ) {
              const value =
                this.state.firstsearchresult[len - this.state.searchcount - 1];
              common = value;
              setTimeout(() => {
                this.setState({ arrowsearch: false });
              }, 300);
            }
            console.log(common);

            // setTimeout(() => {
            if (
              this.state.setsearchcount > 1 &&
              this.state.arrowsearch == false
            ) {
              common = this.state.firstsearchresult[
                this.state.setsearchcount - 1
              ].filter((obj1) =>
                input.some((obj2) => obj1.filepath == obj2.filepath)
              );
              console.log(common);
            }
            // else{
            //     console.log(this.state.firstsearchresult[this.state.searchcount-1]);
            //     common = this.state.firstsearchresult[this.state.searchcount-1].filter(obj1 => input.some(obj2 => obj1.filepath == obj2.filepath))
            //     console.log(common)
            // }
            this.createTree(common);

            // }, 500);

            // else {
            //     common = input;
            //     this.createTree(input);
            //     // this.setState({ firstsearchresult: input });

            // }

            // var totalFilesCount = 0
            // for (let i = 0; i < resp.length; i++) {
            //     totalFilesCount += parseInt(resp[i].count)
            // }

            // This part is causing trouble
            // allData: common,

            this.setState({ allData: common, isDataFound: true }, () => {
              // console.log(this.state.treeData)
            });
          } else {
            this.createTree([]);
            this.setState({ isDataFound: true });
          }
        })
        .then(() =>
          this.setState({
            isApiHitComplete: true,
            isTimerModal: false,
            refinedsearch: false,
          })
        )
        .catch((err) => {
          console.error(err);
          document.getElementById("loadingTimer").innerHTML =
            "Something went wrong. Please try again.";
        });
    }, 1000);
  }

  refined_circular_noti_normal_search(obj, folderpath) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    const refinedArr = this.state.allData;
    console.log(refinedArr);
    Hexapi(obj)
      .then((resp) => {
        console.log(resp);
        let files_comes_from_paramSearch = {};
        files_comes_from_paramSearch[""] = resp[""];
        console.log(files_comes_from_paramSearch);
        if (Object.keys(files_comes_from_paramSearch).length > 0) {
          this.onParametricSearch(
            "",
            this.state.ParametericsearchUrl,
            folderpath
          )
            .then((files) => {
              console.log(files);
              if (files.length > 0) {
                let files_comes_from_contentSearch = files;
                console.log(files_comes_from_contentSearch);
                let common = [];
                let withoutCaseLawsFiles =
                  files_comes_from_contentSearch.filter(
                    (fileObj) => !fileObj.filepath.includes("/cl/")
                  );
                let keys = Object.keys(files_comes_from_paramSearch);
                console.log(keys);
                for (let i = 0; i < keys.length; i++) {
                  let rs = files_comes_from_paramSearch[keys[i]];
                  for (let j = 0; j < rs.length; j++) {
                    rs[j].code = "/" + rs[j].code + ".htm";
                  }
                  console.log(rs, i);
                  if (rs.length > 0) {
                    let filtered = [];
                    rs.forEach((spliter) => {
                      let obj = {};
                      withoutCaseLawsFiles.forEach((fileObj) => {
                        if (
                          fileObj.filepath.includes(spliter.code) &&
                          !filtered.some((fileobj) =>
                            fileobj.filepath.includes(spliter.code)
                          )
                        ) {
                          obj["filepath"] = fileObj.filepath;
                          console.log(spliter.code, fileObj.filepath);
                          filtered.push(obj);
                        }
                      });
                      // return withoutCaseLawsFiles.some((fileObj) => fileObj.filepath.includes(spliter.code))
                    });
                    // let filtered = withoutCaseLawsFiles.filter((fileObj) => {
                    //     return rs.some((spliter) => fileObj.filepath.includes(spliter.code))
                    // }).map((el) => {
                    //     // console.log(el)
                    //     let obj = { filepath: el.filepath }
                    //     return obj
                    // })
                    console.log(filtered);
                    common.push(...filtered);
                  }
                }
                console.log("withoutCaseLawsFiles=>", withoutCaseLawsFiles);
                console.log("Tree Array => ", common);

                if (common.length > 0) {
                  const input = common;
                  input.forEach((data) => {
                    if (data.filepath.includes("\\")) {
                      data.filepath = data.filepath
                        .split("\\Web\\")[1]
                        .replaceAll("\\", "/");
                    }
                  });

                  common = refinedArr.filter((obj1) =>
                    input.some((obj2) => obj1.filepath == obj2.filepath)
                  );
                  console.log(common);
                  // this function creating tree structure
                  this.createTree(common);

                  this.setState({ allData: common, isDataFound: true });
                } else {
                  this.createTree([]);
                  this.setState({ isDataFound: true });
                }
              } else {
                this.createTree([]);
                this.setState({ isDataFound: true });
              }
            })
            .then(() =>
              this.setState({
                isApiHitComplete: true,
                isTimerModal: false,
                paramSearchObj: null,
                searchPath: "Assets/Data5t/",
              })
            )
            .catch((err) => {
              console.error(err);
              document.getElementById("loadingTimer").innerHTML =
                "Something went wrong. Please try again.";
            });
        } else {
          this.createTree([]);
          this.setState({ isDataFound: true });
          this.setState({ isApiHitComplete: true, paramSearchObj: null });
        }
      })
      .catch((err) => console.error(err));
  }

  refined_circular_noti_combine_search(obj, searchWord, searchurl) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    let refinedArr = this.state.allData;
    Hexapi(obj)
      .then((resp) => {
        console.log(resp);
        let files_comes_from_paramSearch = {};
        files_comes_from_paramSearch[""] = resp[""];
        console.log(files_comes_from_paramSearch);
        if (Object.keys(files_comes_from_paramSearch).length > 0) {
          let obj = {
            search: `${searchWord}`,
            searchType: `${this.state.searchType}`,
            url: this.props.searchApi,
            searchurl: searchurl,
          };
          Hexapi(obj)
            .then((files) => {
              let files_comes_from_contentSearch = files;
              console.log(files_comes_from_contentSearch);
              let common = [];
              // let caseLawsFiles = files_comes_from_contentSearch.filter((fileObj) => fileObj.filepath.includes('\\cl\\'))
              let withoutCaseLawsFiles = files_comes_from_contentSearch.filter(
                (fileObj) => !fileObj.filepath.includes("\\cl\\")
              );
              // common.push(...withoutCaseLawsFiles)
              // console.log(caseLawsFiles)
              // // if()
              let keys = Object.keys(files_comes_from_paramSearch);
              console.log(keys);
              for (let i = 0; i < keys.length; i++) {
                let rs = files_comes_from_paramSearch[keys[i]];
                for (let j = 0; j < rs.length; j++) {
                  rs[j].code = "/" + rs[j].code + ".htm";
                }
                console.log(rs);
                if (rs.length > 0) {
                  let filtered = withoutCaseLawsFiles
                    .filter((fileObj) => {
                      // let filepath = fileObj.filepath.replaceAll()
                      if (fileObj.filepath.includes("\\")) {
                        fileObj.filepath = fileObj.filepath
                          .split("\\Web\\")[1]
                          .replaceAll("\\", "/");
                      }
                      return rs.some((spliter) =>
                        fileObj.filepath.includes(spliter.code)
                      );
                    })
                    .map((el) => {
                      // console.log(el)
                      let obj = { filepath: el.filepath + `${keys[i]}` };
                      return obj;
                    });

                  console.log(filtered);
                  common.push(...filtered);
                }
              }
              // common.push(...caseLawsFiles.filter((fileObj) => files_comes_from_paramSearch.some((spliter) => fileObj.filepath.toLowerCase().includes(spliter.toLowerCase()))))
              // console.log('CaseLawsfiles=>', caseLawsFiles)
              console.log("withoutCaseLawsFiles=>", withoutCaseLawsFiles);
              console.log("Tree Array => ", common);
              console.log(refinedArr, common);
              common = refinedArr.filter((obj1) =>
                common.some((obj2) => obj2.filepath.includes(obj1.filepath))
              );
              console.log("=====================================");
              console.log(
                "=============== This is refined array ================="
              );
              console.log(common);
              console.log("=====================================");
              console.log(
                "=============== This is refined array =================="
              );

              if (common.length > 0) {
                const input = common;
                input.forEach((data) => {
                  if (data.filepath.includes("\\")) {
                    data.filepath = data.filepath
                      .split("\\Web\\")[1]
                      .replaceAll("\\", "/");
                  }
                });

                // this function creating tree structure
                this.createTree(input);

                this.setState({ allData: common, isDataFound: true });
              } else {
                this.createTree([]);
                this.setState({ isDataFound: true });
              }
            })
            .then(() =>
              this.setState({
                isApiHitComplete: true,
                isTimerModal: false,
                paramSearchObj: null,
                searchPath: "Assets/Data5t/",
              })
            )
            .catch((err) => {
              console.error(err);
              document.getElementById("loadingTimer").innerHTML =
                "Something went wrong. Please try again.";
            });
        } else {
          this.createTree([]);
          this.setState({ isDataFound: true });
          this.setState({ isApiHitComplete: true, paramSearchObj: null });
        }
      })
      .catch((err) => console.error(err));
  }

  refined_normal_judge_search(obj, searchurl, isSearchWithCaseLawsGetData) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });

    let clKey = "";
    console.log(this.state.selectedSubField);
    if (this.state.selectedSubField == "-1") {
      if (this.state.selectedField == "GT") {
        clKey = "gt@cl";
      } else if (this.state.selectedField == "CU") {
        clKey = "cu@cl";
      } else if (this.state.selectedField == "ST") {
        clKey = "st@cl";
      } else if (this.state.selectedField == "CE") {
        clKey = "ce@cl";
      } else if (this.state.selectedField == "-1") {
        clKey = "gt@cl";
      }
    } else {
      if (this.state.selectedField == "GT") {
        clKey = "gt@adv";
      } else if (this.state.selectedField == "CU") {
        clKey = "cu@adv";
      }
    }

    console.log(clKey);
    let refinedArr = this.state.allData;

    if (isSearchWithCaseLawsGetData == "1") {
      Hexapi(obj).then((files) => {
        console.log(files, files[clKey]);
        if (files[clKey] != undefined && files[clKey].length > 0) {
          let files_comes_from_paramSearch = {};
          files_comes_from_paramSearch[clKey] = files[clKey];

          let obj = {
            search: `${this.state.selectedJudge}`,
            searchType: `2`,
            url: this.props.searchApi,
            searchurl: searchurl,
          };

          Hexapi(obj)
            .then((judges) => {
              console.log(judges);
              if (judges.length > 0) {
                let files_comes_from_judgeSearch = judges;

                // let common = files_comes_from_judgeSearch
                // console.log(common)
                let keys = Object.keys(files_comes_from_paramSearch);
                let input = [];
                console.log(keys);
                for (let i = 0; i < keys.length; i++) {
                  let rs = files_comes_from_paramSearch[keys[i]];
                  for (let j = 0; j < rs.length; j++) {
                    rs[j].code = "/" + rs[j].code + ".htm";
                  }
                  console.log(rs);
                  if (rs.length > 0) {
                    let filtered = files_comes_from_judgeSearch
                      .filter((fileObj) => {
                        // let filepath = fileObj.filepath.replaceAll()
                        if (fileObj.filepath.includes("\\")) {
                          fileObj.filepath = fileObj.filepath
                            .split("\\Web\\")[1]
                            .replaceAll("\\", "/");
                        }
                        return rs.some((spliter) =>
                          fileObj.filepath.includes(spliter.code)
                        );
                      })
                      .map((el) => {
                        console.log(el);
                        let obj = { filepath: el.filepath + `${keys[i]}` };
                        return obj;
                      });
                    console.log(filtered);
                    input.push(...filtered);
                  }
                }
                console.log(refinedArr, input);
                input = refinedArr.filter((obj1) =>
                  input.some((obj2) => obj2.filepath.includes(obj1.filepath))
                );
                console.log("=====================================");
                console.log(
                  "=============== This is refined array ================="
                );
                console.log(input);
                console.log("=====================================");
                console.log(
                  "=============== This is refined array =================="
                );
                if (input.length > 0) {
                  const input1 = input;
                  input1.forEach((data) => {
                    if (data.filepath.includes("\\")) {
                      data.filepath = data.filepath
                        .split("\\Web\\")[1]
                        .replaceAll("\\", "/");
                    }
                  });

                  // this function creating tree structure
                  this.createTree(input1);

                  this.setState({ allData: input, isDataFound: true });
                } else {
                  this.createTree([]);
                  this.setState({ isDataFound: true });
                }
              } else {
                this.createTree([]);
                this.setState({ isDataFound: true });
              }
            })
            .then(() =>
              this.setState({
                isApiHitComplete: true,
                isTimerModal: false,
                paramSearchObj: null,
                searchPath: "Assets/Data5t/",
              })
            )
            .catch((err) => {
              console.error(err);
              document.getElementById("loadingTimer").innerHTML =
                "Something went wrong. Please try again.";
            });
        } else {
          console.log("coming");
          this.createTree([]);
          this.setState({ isDataFound: true });
          this.setState({
            isApiHitComplete: true,
            isTimerModal: false,
            paramSearchObj: null,
            searchPath: "Assets/Data5t/",
          });
        }
      });
    } else {
      let obj = {
        search: `${this.state.selectedJudge}`,
        searchType: `2`,
        url: this.props.searchApi,
        searchurl: searchurl,
      };

      Hexapi(obj)
        .then((judges) => {
          console.log(judges);
          if (judges.length > 0) {
            let filterarr = judges;
            filterarr = refinedArr.filter((obj1) =>
              filterarr.some((obj2) => obj2.filepath.includes(obj1.filepath))
            );
            console.log("=====================================");
            console.log(
              "=============== This is refined array ================="
            );
            console.log(filterarr);
            console.log("=====================================");
            console.log(
              "=============== This is refined array =================="
            );

            const input = filterarr;
            input.forEach((data) => {
              if (data.filepath.includes("\\")) {
                data.filepath =
                  data.filepath.split("\\Web\\")[1].replaceAll("\\", "/") +
                  clKey;
              }
            });
            this.createTree(input);
            this.setState({ allData: filterarr, isDataFound: true });
          } else {
            this.createTree([]);
            this.setState({ isDataFound: true });
          }
        })
        .then(() =>
          this.setState({
            isApiHitComplete: true,
            isTimerModal: false,
            searchPath: "Assets/Data5t/",
            selectedJudge: "",
          })
        )
        .catch((err) => {
          console.error(err);
          document.getElementById("loadingTimer").innerHTML =
            "Something went wrong. Please try again.";
        });
    }
  }

  refined_normal_caseLaws_param_search(obj, folderpath) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    let clKey = "";
    if (this.state.selectedSubField == "-1") {
      if (this.state.selectedField == "GT") {
        clKey = "gt@cl";
      } else if (this.state.selectedField == "CU") {
        clKey = "cu@cl";
      } else if (this.state.selectedField == "ST") {
        clKey = "st@cl";
      } else if (this.state.selectedField == "CE") {
        clKey = "ce@cl";
      } else if (this.state.selectedField == "-1") {
        clKey = "gt@cl";
      }
    } else {
      if (this.state.selectedField == "GT") {
        clKey = "gt@adv";
      } else if (this.state.selectedField == "CU") {
        clKey = "cu@adv";
      }
    }

    let refinedArr = this.state.allData;
    console.log(clKey);
    Hexapi(obj).then((resp) => {
      console.log(resp);
      let files_comes_from_paramSearch = {};
      files_comes_from_paramSearch[clKey] = resp[""];

      console.log(files_comes_from_paramSearch);
      if (files_comes_from_paramSearch[clKey].length > 0) {
        this.onParametricSearch("", this.state.ParametericsearchUrl, folderpath)
          .then((files) => {
            console.log(files);
            if (files.length > 0) {
              let files_comes_from_contentSearch = files;
              console.log(files_comes_from_contentSearch);
              // let common = files_comes_from_contentSearch.filter(file => files_comes_from_paramSearch.includes(file))
              let common = [];
              let caseLawsFiles = files_comes_from_contentSearch.filter(
                (fileObj) => fileObj.filepath.includes("/cl/")
              );
              let withoutCaseLawsFiles = files_comes_from_contentSearch.filter(
                (fileObj) => !fileObj.filepath.includes("/cl/")
              );
              common.push(...withoutCaseLawsFiles);
              console.log(caseLawsFiles);
              // // if()
              let keys = Object.keys(files_comes_from_paramSearch);
              console.log(keys);
              for (let i = 0; i < keys.length; i++) {
                let rs = files_comes_from_paramSearch[keys[i]];
                for (let j = 0; j < rs.length; j++) {
                  rs[j].code = "/" + rs[j].code + ".htm";
                }
                console.log(rs, i);
                if (rs.length > 0) {
                  let filtered = caseLawsFiles
                    .filter((fileObj) => {
                      // let filepath = fileObj.filepath.replaceAll()
                      // if (fileObj.filepath.includes('\\')) {
                      //     fileObj.filepath = fileObj.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
                      // }
                      return rs.some((spliter) =>
                        fileObj.filepath.includes(spliter.code)
                      );
                    })
                    .map((el) => {
                      // console.log(el)
                      let obj = { filepath: el.filepath + `${keys[i]}` };
                      return obj;
                    });
                  console.log(filtered);
                  common.push(...filtered);
                }
              }
              // common.push(...caseLawsFiles.filter((fileObj) => files_comes_from_paramSearch.some((spliter) => fileObj.filepath.toLowerCase().includes(spliter.toLowerCase()))))
              console.log("CaseLawsfiles=>", caseLawsFiles);
              console.log("withoutCaseLawsFiles=>", withoutCaseLawsFiles);
              // console.log('Tree Array => ', common)
              console.log(refinedArr, common);
              common = refinedArr.filter((obj1) =>
                common.some((obj2) => obj2.filepath.includes(obj1.filepath))
              );
              console.log("=====================================");
              console.log(
                "=============== This is refined array ================="
              );
              console.log(common);
              console.log("=====================================");
              console.log(
                "=============== This is refined array =================="
              );

              if (common.length > 0) {
                const input = common;
                input.forEach((data) => {
                  if (data.filepath.includes("\\")) {
                    data.filepath = data.filepath
                      .split("\\Web\\")[1]
                      .replaceAll("\\", "/");
                  }
                });
                let maparr = input.map((data) => {
                  let obj = {};
                  obj["filepath"] = data.filepath + clKey;
                  return obj;
                });
                // this function creating tree structure
                // this.createTree(input)
                console.log(maparr);

                this.setState({ allData: common, isDataFound: true });
              } else {
                this.createTree([]);
                this.setState({ isDataFound: true });
              }
            } else {
              this.createTree([]);
              this.setState({ isDataFound: true });
            }
          })
          .then(() =>
            this.setState({
              isApiHitComplete: true,
              isTimerModal: false,
              paramSearchObj: null,
              searchPath: "Assets/Data5t/",
            })
          )
          .catch((err) => {
            console.error(err);
            document.getElementById("loadingTimer").innerHTML =
              "Something went wrong. Please try again.";
          });
      } else {
        this.setState({
          isApiHitComplete: true,
          isTimerModal: false,
          paramSearchObj: null,
          searchPath: "Assets/Data5t/",
        });
        this.createTree([]);
        this.setState({ isDataFound: true });
      }
    });
  }

  refined_combine_caseLaws_param_search(obj, searchWord, searchurl) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    let clKey = "";
    if (this.state.selectedSubField == "-1") {
      if (this.state.selectedField == "GT") {
        clKey = "gt@cl";
      } else if (this.state.selectedField == "CU") {
        clKey = "cu@cl";
      } else if (this.state.selectedField == "ST") {
        clKey = "st@cl";
      } else if (this.state.selectedField == "CE") {
        clKey = "ce@cl";
      } else if (this.state.selectedField == "-1") {
        clKey = "gt@cl";
      }
    } else {
      if (this.state.selectedField == "GT") {
        clKey = "gt@adv";
      } else if (this.state.selectedField == "CU") {
        clKey = "cu@adv";
      }
    }
    console.log(clKey);
    let refinedArr = this.state.allData;
    Hexapi(obj)
      .then((resp) => {
        console.log(resp);
        let files_comes_from_paramSearch = {};
        files_comes_from_paramSearch[clKey] = resp[""];
        console.log(files_comes_from_paramSearch);
        if (Object.keys(files_comes_from_paramSearch).length > 0) {
          let obj = {
            search: `${searchWord}`,
            searchType: `${this.state.searchType}`,
            url: this.props.searchApi,
            searchurl: searchurl,
          };
          Hexapi(obj)
            .then((files) => {
              let files_comes_from_contentSearch = files;
              console.log(files_comes_from_contentSearch);
              // let common = files_comes_from_contentSearch.filter(file => files_comes_from_paramSearch.includes(file))
              let common = [];
              let caseLawsFiles = files_comes_from_contentSearch.filter(
                (fileObj) => fileObj.filepath.includes("\\cl\\")
              );
              let withoutCaseLawsFiles = files_comes_from_contentSearch.filter(
                (fileObj) => !fileObj.filepath.includes("\\cl\\")
              );
              common.push(...withoutCaseLawsFiles);
              console.log(caseLawsFiles);
              // // if()
              let keys = Object.keys(files_comes_from_paramSearch);
              console.log(keys);
              for (let i = 0; i < keys.length; i++) {
                let rs = files_comes_from_paramSearch[keys[i]];
                for (let j = 0; j < rs.length; j++) {
                  rs[j].code = "/" + rs[j].code + ".htm";
                }
                console.log(rs);
                if (rs.length > 0) {
                  let filtered = caseLawsFiles
                    .filter((fileObj) => {
                      // let filepath = fileObj.filepath.replaceAll()
                      if (fileObj.filepath.includes("\\")) {
                        fileObj.filepath = fileObj.filepath
                          .split("\\Web\\")[1]
                          .replaceAll("\\", "/");
                      }
                      return rs.some((spliter) =>
                        fileObj.filepath.includes(spliter.code)
                      );
                    })
                    .map((el) => {
                      console.log(el);
                      let obj = { filepath: el.filepath + `${keys[i]}` };
                      return obj;
                    });
                  console.log(filtered);
                  common.push(...filtered);
                }
              }
              console.log("CaseLawsfiles=>", caseLawsFiles);
              console.log("withoutCaseLawsFiles=>", withoutCaseLawsFiles);
              console.log("Tree Array => ", common);

              console.log(refinedArr, common);
              common = refinedArr.filter((obj1) =>
                common.some((obj2) => obj2.filepath.includes(obj1.filepath))
              );
              console.log("=====================================");
              console.log(
                "=============== This is refined array ================="
              );
              console.log(common);
              console.log("=====================================");
              console.log(
                "=============== This is refined array =================="
              );

              if (common.length > 0) {
                const input = common;
                input.forEach((data) => {
                  if (data.filepath.includes("\\")) {
                    data.filepath = data.filepath
                      .split("\\Web\\")[1]
                      .replaceAll("\\", "/");
                  }
                });

                let maparr = input.map((data) => {
                  let obj = {};
                  obj["filepath"] = data.filepath + clKey;
                  return obj;
                });
                // this function creating tree structure
                this.createTree(maparr);

                this.setState({ allData: common, isDataFound: true });
              } else {
                this.createTree([]);
                this.setState({ isDataFound: true });
              }
            })
            .then(() =>
              this.setState({
                isApiHitComplete: true,
                isTimerModal: false,
                paramSearchObj: null,
                searchPath: "Assets/Data5t/",
              })
            )
            .catch((err) => {
              console.error(err);
              document.getElementById("loadingTimer").innerHTML =
                "Something went wrong. Please try again.";
            });
        } else {
          this.createTree([]);
          this.setState({ isDataFound: true });
          this.setState({ isApiHitComplete: true, paramSearchObj: null });
        }
      })
      .catch((err) => console.error(err));
  }

  refined_normal_caseLaws_content_search(
    obj,
    searchWord,
    searchurl,
    isSearchWithCaseLawsGetData
  ) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    let clKey = "";
    // if (this.state.selectedSubField == '-1') {
    //     if (this.state.selectedField == 'GT') {
    //         clKey = 'gt@cl'
    //     } else if (this.state.selectedField == "CU") {
    //         clKey = 'cu@cl'
    //     } else if (this.state.selectedField == 'ST') {
    //         clKey = 'st@cl'
    //     } else if (this.state.selectedField == 'CE') {
    //         clKey = 'ce@cl'
    //     } else if (this.state.selectedField == "-1") {
    //         clKey = 'gt@cl'
    //     }
    // } else {
    //     if (this.state.selectedField == 'GT') {
    //         clKey = 'gt@adv'
    //     } else if (this.state.selectedField == "CU") {
    //         clKey = 'cu@adv'
    //     }
    // }
    // console.log(clKey)

    let refinedArr = this.state.allData;
    if (isSearchWithCaseLawsGetData == "1") {
      Hexapi(obj)
        .then((resp) => {
          console.log(resp);
          if (resp[clKey] != undefined && resp[clKey].length > 0) {
            let files_comes_from_paramSearch = {};
            files_comes_from_paramSearch[clKey] = resp[clKey];
            console.log(files_comes_from_paramSearch);
            if (Object.keys(files_comes_from_paramSearch).length > 0) {
              let obj = {
                search: `${searchWord}`,
                searchType: `${this.state.searchType}`,
                url: this.props.searchApi,
                searchurl: searchurl,
              };
              Hexapi(obj)
                .then((files) => {
                  let files_comes_from_contentSearch = files;
                  console.log(files_comes_from_contentSearch);
                  // let common = files_comes_from_contentSearch.filter(file => files_comes_from_paramSearch.includes(file))
                  let common = [];
                  let caseLawsFiles = files_comes_from_contentSearch.filter(
                    (fileObj) => fileObj.filepath.includes("\\cl\\")
                  );
                  let withoutCaseLawsFiles =
                    files_comes_from_contentSearch.filter(
                      (fileObj) => !fileObj.filepath.includes("\\cl\\")
                    );
                  common.push(...withoutCaseLawsFiles);
                  console.log(caseLawsFiles);
                  // // if()
                  let keys = Object.keys(files_comes_from_paramSearch);
                  console.log(keys);
                  for (let i = 0; i < keys.length; i++) {
                    let rs = files_comes_from_paramSearch[keys[i]];
                    for (let j = 0; j < rs.length; j++) {
                      rs[j].code = "/" + rs[j].code + ".htm";
                    }
                    console.log(rs);
                    if (rs.length > 0) {
                      let filtered = caseLawsFiles
                        .filter((fileObj) => {
                          // let filepath = fileObj.filepath.replaceAll()
                          if (fileObj.filepath.includes("\\")) {
                            fileObj.filepath = fileObj.filepath
                              .split("\\Web\\")[1]
                              .replaceAll("\\", "/");
                          }
                          return rs.some((spliter) =>
                            fileObj.filepath.includes(spliter.code)
                          );
                        })
                        .map((el) => {
                          // console.log(el)
                          let obj = { filepath: el.filepath + `${keys[i]}` };
                          return obj;
                        });
                      console.log(filtered);
                      common.push(...filtered);
                    }
                  }
                  console.log("CaseLawsfiles=>", caseLawsFiles);
                  console.log("withoutCaseLawsFiles=>", withoutCaseLawsFiles);
                  console.log("Tree Array => ", common);

                  console.log(refinedArr, common);
                  common = refinedArr.filter((obj1) =>
                    common.some((obj2) => obj2.filepath.includes(obj1.filepath))
                  );
                  console.log("=====================================");
                  console.log(
                    "=============== This is refined array ================="
                  );
                  console.log(common);
                  console.log("=====================================");
                  console.log(
                    "=============== This is refined array =================="
                  );

                  if (common.length > 0) {
                    const input = common;
                    input.forEach((data) => {
                      if (data.filepath.includes("\\")) {
                        data.filepath = data.filepath
                          .split("\\Web\\")[1]
                          .replaceAll("\\", "/");
                      }
                    });

                    let maparr = input.map((data) => {
                      let obj = {};
                      obj["filepath"] = data.filepath + clKey;
                      return obj;
                    });
                    // this function creating tree structure
                    this.createTree(maparr);

                    this.setState({ allData: common, isDataFound: true });
                  } else {
                    this.createTree([]);
                    this.setState({ isDataFound: true });
                  }
                })
                .then(() =>
                  this.setState({
                    isApiHitComplete: true,
                    isTimerModal: false,
                    paramSearchObj: null,
                    searchPath: "Assets/Data5t/",
                  })
                )
                .catch((err) => {
                  console.error(err);
                  document.getElementById("loadingTimer").innerHTML =
                    "Something went wrong. Please try again.";
                });
            } else {
              this.createTree([]);
              this.setState({ isDataFound: true });
              this.setState({ isApiHitComplete: true, paramSearchObj: null });
            }
          } else {
            this.createTree([]);
            this.setState({ isDataFound: true });
            this.setState({
              isApiHitComplete: true,
              isTimerModal: false,
              paramSearchObj: null,
              searchPath: "Assets/Data5t/",
            });
          }
        })
        .catch((err) => console.error(err));
    } else {
      let obj = {
        search: `${searchWord}`,
        searchType: `${this.state.searchType}`,
        url: this.props.searchApi,
        searchurl: searchurl,
      };
      Hexapi(obj)
        .then((resp) => {
          console.log(resp);
          if (resp.length > 0) {
            const input = resp;
            input.forEach((data) => {
              if (data.filepath.includes("\\")) {
                data.filepath =
                  data.filepath.split("\\Web\\")[1].replaceAll("\\", "/") +
                  clKey;
              }
            });

            console.log(refinedArr, resp);
            let common = refinedArr.filter((obj1) =>
              input.some((obj2) => obj2.filepath.includes(obj1.filepath))
            );
            console.log("=====================================");
            console.log(
              "=============== This is refined array ================="
            );
            console.log(common);
            console.log("=====================================");
            console.log(
              "=============== This is refined array =================="
            );

            let maparr = common.map((data) => {
              let obj = {};
              obj["filepath"] = data.filepath + clKey;
              return obj;
            });
            this.createTree(maparr);
            // console.log(input)
            this.setState({ allData: common, isDataFound: true });
          } else {
            this.createTree([]);
            this.setState({ isDataFound: true });
          }
        })
        .then(() =>
          this.setState({
            isApiHitComplete: true,
            isTimerModal: false,
            searchPath: "Assets/Data5t/",
          })
        )
        .catch((err) => {
          console.error(err);
          document.getElementById("loadingTimer").innerHTML =
            "Something went wrong. Please try again.";
        });
    }
  }

  refined_normal_judge_and_content_search(
    obj,
    searchWord,
    searchurl,
    isSearchWithCaseLawsGetData
  ) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    let clKey = "";
    if (this.state.selectedSubField == "-1") {
      if (this.state.selectedField == "GT") {
        clKey = "gt@cl";
      } else if (this.state.selectedField == "CU") {
        clKey = "cu@cl";
      } else if (this.state.selectedField == "ST") {
        clKey = "st@cl";
      } else if (this.state.selectedField == "CE") {
        clKey = "ce@cl";
      } else if (this.state.selectedField == "-1") {
        clKey = "gt@cl";
      }
    } else {
      if (this.state.selectedField == "GT") {
        clKey = "gt@adv";
      } else if (this.state.selectedField == "CU") {
        clKey = "cu@adv";
      }
    }

    console.log(clKey);
    let refinedArr = this.state.allData;

    if (isSearchWithCaseLawsGetData == "1") {
      Hexapi(obj)
        .then((resp) => {
          console.log(resp);
          if (resp[clKey] != undefined && resp[clKey].length > 0) {
            let files_comes_from_paramSearch = {};
            files_comes_from_paramSearch[clKey] = resp[clKey];
            // console.log(files_comes_from_paramSearch)
            if (files_comes_from_paramSearch[clKey].length > 0) {
              let obj = {
                search: `${searchWord}`,
                searchType: `${this.state.searchType}`,
                url: this.props.searchApi,
                searchurl: searchurl,
              };

              Hexapi(obj).then((files) => {
                console.log(files);
                if (files.length > 0) {
                  let files_comes_from_contentSearch = files;

                  let obj = {
                    search: `${this.state.selectedJudge}`,
                    searchType: `2`,
                    url: this.props.searchApi,
                    searchurl: searchurl,
                  };

                  Hexapi(obj)
                    .then((judges) => {
                      console.log(judges);
                      if (judges.length > 0) {
                        let files_comes_from_judgeSearch = judges;

                        let common = files_comes_from_judgeSearch.filter(
                          (obj1) =>
                            files_comes_from_contentSearch.some(
                              (obj2) => obj1.filepath == obj2.filepath
                            )
                        );
                        // console.log(common)
                        let keys = Object.keys(files_comes_from_paramSearch);
                        let input = [];
                        console.log(keys);
                        for (let i = 0; i < keys.length; i++) {
                          let rs = files_comes_from_paramSearch[keys[i]];
                          for (let j = 0; j < rs.length; j++) {
                            rs[j].code = "/" + rs[j].code + ".htm";
                          }
                          console.log(rs);
                          if (rs.length > 0) {
                            let filtered = common
                              .filter((fileObj) => {
                                // let filepath = fileObj.filepath.replaceAll()
                                if (fileObj.filepath.includes("\\")) {
                                  fileObj.filepath = fileObj.filepath
                                    .split("\\Web\\")[1]
                                    .replaceAll("\\", "/");
                                }
                                return rs.some((spliter) =>
                                  fileObj.filepath.includes(spliter.code)
                                );
                              })
                              .map((el) => {
                                console.log(el);
                                let obj = {
                                  filepath: el.filepath + `${keys[i]}`,
                                };
                                return obj;
                              });
                            console.log(filtered);
                            input.push(...filtered);
                          }
                        }

                        console.log(refinedArr, input);
                        common = refinedArr.filter((obj1) =>
                          input.some((obj2) =>
                            obj2.filepath.includes(obj1.filepath)
                          )
                        );
                        console.log("=====================================");
                        console.log(
                          "=============== This is refined array ================="
                        );
                        console.log(common);
                        console.log("=====================================");
                        console.log(
                          "=============== This is refined array =================="
                        );

                        if (input.length > 0) {
                          const input1 = common;
                          input1.forEach((data) => {
                            if (data.filepath.includes("\\")) {
                              data.filepath = data.filepath
                                .split("\\Web\\")[1]
                                .replaceAll("\\", "/");
                            }
                          });

                          let maparr = input1.map((data) => {
                            let obj = {};
                            obj["filepath"] = data.filepath + clKey;
                            return obj;
                          });
                          // this function creating tree structure
                          this.createTree(maparr);

                          this.setState({ allData: common, isDataFound: true });
                        } else {
                          this.createTree([]);
                          this.setState({ isDataFound: true });
                        }
                      } else {
                        this.createTree([]);
                        this.setState({ isDataFound: true });
                      }
                    })
                    .then(() =>
                      this.setState({
                        isApiHitComplete: true,
                        isTimerModal: false,
                        paramSearchObj: null,
                        searchPath: "Assets/Data5t/",
                      })
                    )
                    .catch((err) => {
                      console.error(err);
                      document.getElementById("loadingTimer").innerHTML =
                        "Something went wrong. Please try again.";
                    });
                } else {
                  this.createTree([]);
                  this.setState({ isDataFound: true });
                }
              });
            } else {
              this.createTree([]);
              this.setState({ isDataFound: true });
            }
          } else {
            this.createTree([]);
            this.setState({ isDataFound: true });
            this.setState({
              isApiHitComplete: true,
              isTimerModal: false,
              paramSearchObj: null,
              searchPath: "Assets/Data5t/",
            });
          }
        })
        .catch((err) => console.error(err));
    } else {
      let obj = {
        search: `${searchWord}`,
        searchType: `${this.state.searchType}`,
        url: this.props.searchApi,
        searchurl: searchurl,
      };
      Hexapi(obj)
        .then((files) => {
          console.log(files);
          let array_comes_from_contentSearch = files;
          if (array_comes_from_contentSearch.length > 0) {
            let obj = {
              search: `${this.state.selectedJudge}`,
              searchType: `2`,
              url: this.props.searchApi,
              searchurl: searchurl,
            };

            Hexapi(obj).then((judges) => {
              console.log(judges);
              let filterarr = array_comes_from_contentSearch.filter(
                (fileobj1) =>
                  judges.some(
                    (fileobj2) => fileobj1.filepath == fileobj2.filepath
                  )
              );
              console.log(filterarr);
              const input = filterarr;
              input.forEach((data) => {
                if (data.filepath.includes("\\")) {
                  data.filepath =
                    data.filepath.split("\\Web\\")[1].replaceAll("\\", "/") +
                    clKey;
                }
              });

              console.log(refinedArr, input);
              let common = refinedArr.filter((obj1) =>
                input.some((obj2) => obj2.filepath.includes(obj1.filepath))
              );
              console.log("=====================================");
              console.log(
                "=============== This is refined array ================="
              );
              console.log(common);
              console.log("=====================================");
              console.log(
                "=============== This is refined array =================="
              );

              let maparr = common.map((data) => {
                let obj = {};
                obj["filepath"] = data.filepath + clKey;
                return obj;
              });

              this.createTree(maparr);
              this.setState({ allData: common, isDataFound: true });
            });
          } else {
            this.createTree([]);
            this.setState({ isDataFound: true });
          }
        })
        .then(() =>
          this.setState({
            isApiHitComplete: true,
            isTimerModal: false,
            searchPath: "Assets/Data5t/",
            selectedJudge: "",
          })
        )
        .catch((err) => {
          console.error(err);
          document.getElementById("loadingTimer").innerHTML =
            "Something went wrong. Please try again.";
        });
    }
  }

  refined_normal_all_search(obj, searchWord, searchurl) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    let refinedArr = this.state.allData;
    Hexapi(obj)
      .then((resp) => {
        console.log(resp);
        let files_comes_from_paramSearch = resp;
        console.log(files_comes_from_paramSearch);
        if (Object.keys(files_comes_from_paramSearch).length > 0) {
          let obj = {
            search: `${searchWord}`,
            searchType: `${this.state.searchType}`,
            url: this.props.searchApi,
            searchurl: searchurl,
          };
          Hexapi(obj)
            .then((files) => {
              let files_comes_from_contentSearch = files;
              console.log(files_comes_from_contentSearch);
              let common = [];
              let caseLawsFiles = files_comes_from_contentSearch.filter(
                (fileObj) => fileObj.filepath.includes("\\cl\\")
              );
              let withoutCaseLawsFiles = files_comes_from_contentSearch.filter(
                (fileObj) => !fileObj.filepath.includes("\\cl\\")
              );
              common.push(...withoutCaseLawsFiles);
              // // if()
              let keys = Object.keys(files_comes_from_paramSearch);
              console.log(keys);
              for (let i = 0; i < keys.length; i++) {
                let rs = resp[keys[i]];
                console.log(rs);
                if (rs.length > 0) {
                  let filtered = caseLawsFiles
                    .filter((fileObj) =>
                      rs.some((spliter) =>
                        fileObj.filepath.includes(spliter.code)
                      )
                    )
                    .map((el) => {
                      let obj = { filepath: el.filepath + `${keys[i]}` };
                      return obj;
                    });
                  console.log(filtered);
                  common.push(...filtered);
                }
              }
              // common.push(...caseLawsFiles.filter((fileObj) => files_comes_from_paramSearch.some((spliter) => fileObj.filepath.toLowerCase().includes(spliter.toLowerCase()))))
              console.log("CaseLawsfiles=>", caseLawsFiles);
              console.log("withoutCaseLawsFiles=>", withoutCaseLawsFiles);
              console.log("Tree Array => ", common);

              // common = refinedArr.filter(obj1 => common.some(obj2 => obj2.filepath.includes(obj1.filepath)))

              if (common.length > 0) {
                const input = common;
                input.forEach((data) => {
                  if (data.filepath.includes("\\")) {
                    data.filepath = data.filepath
                      .split("\\Web\\")[1]
                      .replaceAll("\\", "/");
                  }
                });

                console.log(refinedArr, input);
                let maparr = [],
                  commonarr = [];
                refinedArr.forEach((obj1) => {
                  let mapObj = null,
                    commonObj = null;
                  input.forEach((obj2) => {
                    if (obj2.filepath.includes(obj1.filepath)) {
                      mapObj = obj2;
                      commonObj = obj1;
                    }
                  });
                  if (mapObj != null) {
                    maparr.push(mapObj);
                  }
                  if (commonObj != null) {
                    commonarr.push(commonObj);
                  }
                });
                console.log("=====================================");
                console.log(
                  "=============== This is refined array ================="
                );
                console.log(maparr);
                console.log("=====================================");
                console.log(
                  "=============== This is refined array =================="
                );
                // this function creating tree structure
                this.createTree(maparr);

                this.setState({ allData: commonarr, isDataFound: true });
              } else {
                this.createTree([]);
                this.setState({ isDataFound: true });
              }
            })
            .then(() =>
              this.setState({
                isApiHitComplete: true,
                isTimerModal: false,
                paramSearchObj: null,
                searchPath: "Assets/Data5t/",
              })
            )
            .catch((err) => {
              console.error(err);
              document.getElementById("loadingTimer").innerHTML =
                "Something went wrong. Please try again.";
            });
        } else {
          this.setState({
            isApiHitComplete: true,
            isTimerModal: false,
            paramSearchObj: null,
            searchPath: "Assets/Data5t/",
          });
        }
      })
      .catch((err) => console.error(err));
  }

  normal_content_search(searchWord, searchurl) {
    console.log(searchurl, this.state.selectedField);
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    let obj = {
      search: `${searchWord}`,
      searchType: `${this.state.searchType}`,
      url: this.props.searchApi,
      searchurl: searchurl,
    };
    Hexapi(obj)
      .then((resp) => {
        console.log(resp);

        if (resp.length > 0) {
          var files = [];
          var nisfiles = [];
          for (var i = 0; i < resp.length; i++) {
            for (const key in resp[i]) {
              if (key.includes("nisfilepath")) {
                nisfiles.push(resp[i]);
              } else {
                files.push(resp[i]);
              }
            }
          }
          this.setState({ nisfiles: nisfiles });

          console.log(files);
          console.log(nisfiles);
          const input = files;
          // for(let i=0;i<input.length;i++){
          //     let filepath = input[i].filepath
          //     if(filepath.includes('\\')){
          //         filepath = filepath.split('\\Web\\')[1]
          //     }
          // }
          input.forEach((data) => {
            if (data.filepath.includes("\\")) {
              data.filepath = data.filepath
                .split("\\Web\\")[1]
                .replaceAll("\\", "/");
            }
          });
          // console.log(input)
          // console.log(this.state.newTreeData)
          if (this.state.setsearchcount == 1) {
            this.setState((prevState) => {
              const updatedResults = [...prevState.firstsearchresult];
              updatedResults[0] = input;
              return { firstsearchresult: updatedResults };
            });
          }

          // const updatedResults ={};
          //     updatedResults[0] = input;
          // this.setState({firstsearchresult,updatedResults})
          // this.setState({ newTreeData: this.copyNewTree }, () => {
          //     console.log(this.state.newTreeData)
          this.createTree(input);
          // })

          // var totalFilesCount = 0
          // for (let i = 0; i < resp.length; i++) {
          //     totalFilesCount += parseInt(resp[i].count)
          // }
          this.setState({ allData: resp, isDataFound: true }, () => {
            // console.log(this.state.treeData)
          });
        } else {
          this.createTree([]);
          this.setState({ isDataFound: true });
        }
      })
      .then(() =>
        this.setState({ isApiHitComplete: true, isTimerModal: false })
      )
      .catch((err) => {
        console.error(err);
        document.getElementById("loadingTimer").innerHTML =
          "Something went wrong. Please try again.";
      });
    // let obj = {
    //     'url': 'foldersearch.php',
    //     'searchurl': searchurl
    // }
    // Hexapi(obj).then(resp => {
    //     console.log(resp)
    //     let obj = {
    //         allObj : {
    //             pathArray:resp,
    //             searchword: `${searchWord}`,
    //             searchtype:`${this.state.searchType}`
    //         }
    //     }
    //     Hexapi(obj).then(d => {
    //         console.log(d)
    //     })
    //     // if (resp.length > 0) {
    //     //     const input = resp
    //     //     var totalFilesCount = 0
    //     //     for (let i = 0; i < resp.length; i++) {
    //     //         totalFilesCount += parseInt(resp[i].count)
    //     //     }
    //     //     this.setState({ allData: resp, totalFilesCount: totalFilesCount, treeData: this.createTree(input), isDataFound: true }, () => {
    //     //         console.log(this.state.treeData)
    //     //     })
    //     // } else {
    //     //     this.setState({ isDataFound: false })
    //     // }
    // })
    // // .then(res => this.setState({ isApiHitComplete: true })).catch(err => console.error(err))
  }

  normal_parametric_search(obj, folderpath, isClOrAdv) {
    // this.setState({ isOnlyParamSearch: true, isHistoryOpen: false, openInitialModal: false, isFetchFileApiHitComplete: false, isFirstSearchComplete: true, isApiHitComplete: false, isDataFound: false, selectedFile: '' })
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    console.log(this.state.paramSearchObj);
    // let obj = {
    //     query: `[dbo].[Lawcrux_Web_Proc_ParamtericSearch_GetDataV2] @JudgeID='{0}', @PartyID='{1}', @Landmark='{2}', @Order='{3}', @Citation='{4}', @CitationValue='{5}', @FromDate='{6}', @ToDate='{7}', @Court='{8}', @Favour='{9}'`,
    //     queryArr: [`${this.state.paramSearchObj.judge}`, `${this.state.paramSearchObj.party}`, `${this.state.paramSearchObj.landmark}`, `${this.state.paramSearchObj.order}`, `${this.state.paramSearchObj.citation}`, `${this.state.paramSearchObj.citationcode}`, `${this.state.paramSearchObj.fromdate}`, `${this.state.paramSearchObj.todate}`, `${this.state.paramSearchObj.court}`, `${this.state.paramSearchObj.favour}`]
    // }
    // if (isClOrAdv != undefined) {
    Hexapi(obj).then((resp) => {
      console.log(resp);
      let jsonArr = [];
      let keys = Object.keys(resp);
      console.log(keys);
      for (let i = 0; i < keys.length; i++) {
        let rs = resp[keys[i]];
        console.log(rs);
        for (let j = 0; j < rs.length; j++) {
          let record = rs[j].code + ".htm";
          jsonArr.push(record);
        }
      }
      // jsonArr = jsonArr.filter(())
      console.log(jsonArr);
      if (jsonArr.length > 0) {
        this.onParametricSearch(
          jsonArr.join("##12##"),
          this.state.ParametericsearchUrl,
          folderpath
        )
          .then((re) => {
            console.log(re);
            if (re.length > 0) {
              const input = [];
              // if (folderpath == 'Assets/Data5t/cl') {
              //     re = re.filter((obj) => !obj.filepath.includes('claarc') && !obj.filepath.includes('claaar'))
              // }
              re.forEach((el) => {
                for (let i = 0; i < jsonArr.length; i++) {
                  if (
                    el.filepath.toLowerCase().includes(jsonArr[i].toLowerCase())
                  ) {
                    input.push(el);
                    jsonArr = jsonArr.filter(
                      (filename) => filename != jsonArr[i]
                    );
                    break;
                  }
                }
                // jsonArr.forEach((filename) => {
                //     // console.log(filename)
                //     if (el.filepath.toLowerCase().includes(filename.toLowerCase())) {
                //         input.push(el)

                //     }
                // })
              });
              // let userDict =

              input.forEach((data) => {
                if (data.filepath.includes("\\")) {
                  data.filepath =
                    data.filepath.split("\\Web\\")[1].replaceAll("\\", "/") +
                    "gt@cl";
                }
              });

              // this function creating tree structure
              this.createTree(input);

              this.setState({ allData: input, isDataFound: true });
            } else {
              this.createTree([]);
              this.setState({ isDataFound: true });
            }
          })
          .then(() =>
            this.setState({
              isApiHitComplete: true,
              isTimerModal: false,
              paramSearchObj: null,
              searchPath: "Assets/Data5t/",
            })
          )
          .catch((err) => {
            console.error(err);
            document.getElementById("loadingTimer").innerHTML =
              "Something went wrong. Please try again.";
          });
      } else {
        this.createTree([]);
        this.setState({ isDataFound: true });
        this.setState({
          isApiHitComplete: true,
          isTimerModal: false,
          paramSearchObj: null,
        });
      }
    });
    // } else {
    //     let clKey = ''
    //     if (this.state.selectedField == 'GT') {
    //         clKey = 'gt@cl'
    //     } else if (this.state.selectedField == "CU") {
    //         clKey = 'cu@cl'
    //     } else if (this.state.selectedField == 'ST') {
    //         clKey = 'st@cl'
    //     } else if (this.state.selectedField == 'CE') {
    //         clKey = 'ce@cl'
    //     } else if (this.state.selectedField == "-1") {
    //         clKey = 'gt@cl'
    //     }

    //     Hexapi(obj).then(resp => {
    //         console.log(resp)
    //         let files_comes_from_paramSearch = {};
    //         files_comes_from_paramSearch[clKey] = resp['']
    //         // let keys = Object.keys(resp)
    //         // console.log(keys)
    //         // for (let i = 0; i < keys.length; i++) {
    //         //     let rs = resp[keys[i]];
    //         //     for (let j = 0; j < rs.length; j++) {
    //         //         let record = rs[j].code + '.htm';
    //         //         files_comes_from_paramSearch.push(record)
    //         //     }
    //         // }
    //         console.log(files_comes_from_paramSearch)
    //         if (Object.keys(files_comes_from_paramSearch).length > 0) {
    //             this.onParametricSearch('', this.state.ParametericsearchUrl, folderpath).then(files => {
    //                 console.log(files)
    //                 if (files.length > 0) {
    //                     let files_comes_from_contentSearch = files
    //                     console.log(files_comes_from_contentSearch)
    //                     // let common = files_comes_from_contentSearch.filter(file => files_comes_from_paramSearch.includes(file))
    //                     let common = []
    //                     let caseLawsFiles = files_comes_from_contentSearch.filter((fileObj) => fileObj.filepath.includes('/cl/'))
    //                     let withoutCaseLawsFiles = files_comes_from_contentSearch.filter((fileObj) => !fileObj.filepath.includes('/cl/'))
    //                     common.push(...withoutCaseLawsFiles)
    //                     console.log(caseLawsFiles)
    //                     // // if()
    //                     let keys = Object.keys(files_comes_from_paramSearch)
    //                     console.log(keys)
    //                     for (let i = 0; i < keys.length; i++) {
    //                         let rs = files_comes_from_paramSearch[keys[i]];
    //                         for (let j = 0; j < rs.length; j++) {
    //                             rs[j].code = '/' + rs[j].code + '.htm';
    //                         }
    //                         console.log(rs, i)
    //                         if (rs.length > 0) {
    //                             let filtered = caseLawsFiles.filter((fileObj) => {
    //                                 // let filepath = fileObj.filepath.replaceAll()
    //                                 // if (fileObj.filepath.includes('\\')) {
    //                                 //     fileObj.filepath = fileObj.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
    //                                 // }
    //                                 return rs.some((spliter) => fileObj.filepath.includes(spliter.code))
    //                             }).map((el) => {
    //                                 console.log(el)
    //                                 let obj = { filepath: el.filepath + `${keys[i]}` }
    //                                 return obj
    //                             })
    //                             console.log(filtered)
    //                             common.push(...filtered)
    //                         }
    //                     }
    //                     // common.push(...caseLawsFiles.filter((fileObj) => files_comes_from_paramSearch.some((spliter) => fileObj.filepath.toLowerCase().includes(spliter.toLowerCase()))))
    //                     console.log('CaseLawsfiles=>', caseLawsFiles)
    //                     console.log('withoutCaseLawsFiles=>', withoutCaseLawsFiles)
    //                     console.log('Tree Array => ', common)

    //                     if (common.length > 0) {
    //                         const input = common
    //                         input.forEach((data) => {
    //                             if (data.filepath.includes('\\')) {
    //                                 data.filepath = data.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
    //                             }
    //                         })

    //                         // this function creating tree structure
    //                         this.createTree(input)

    //                         this.setState({ allData: common, isDataFound: true })
    //                     } else {
    //                         this.createTree([])
    //                         this.setState({ isDataFound: true })
    //                     }

    //                 } else {
    //                     this.createTree([])
    //                     this.setState({ isDataFound: true })

    //                 }
    //             }).then(() => this.setState({ isApiHitComplete: true, isTimerModal: false, paramSearchObj: null, searchPath: 'Assets/Data5t/' })).catch(err => {
    //                 console.error(err)
    //                 document.getElementById('loadingTimer').innerHTML = 'Something went wrong. Please try again.'
    //             })

    //         } else {
    //             this.createTree([])
    //             this.setState({ isDataFound: true })
    //             this.setState({ isApiHitComplete: true, paramSearchObj: null })
    //         }
    //     })
    // }
  }

  normal_judge_search(obj, searchurl, isSearchWithCaseLawsGetData) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });

    let clKey = "";
    console.log(this.state.selectedSubField);
    if (this.state.selectedSubField == "-1") {
      if (this.state.selectedField == "GT") {
        clKey = "gt@cl";
      } else if (this.state.selectedField == "CU") {
        clKey = "cu@cl";
      } else if (this.state.selectedField == "ST") {
        clKey = "st@cl";
      } else if (this.state.selectedField == "CE") {
        clKey = "ce@cl";
      } else if (this.state.selectedField == "-1") {
        clKey = "gt@cl";
      }
    } else {
      if (this.state.selectedField == "GT") {
        clKey = "gt@adv";
      } else if (this.state.selectedField == "CU") {
        clKey = "cu@adv";
      }
    }

    console.log(clKey);

    if (isSearchWithCaseLawsGetData == "1") {
      Hexapi(obj).then((files) => {
        console.log(files, files[clKey]);
        if (files[clKey] != undefined && files[clKey].length > 0) {
          let files_comes_from_paramSearch = {};
          files_comes_from_paramSearch[clKey] = files[clKey];

          let obj = {
            search: `${this.state.selectedJudge}`,
            searchType: `2`,
            url: this.props.searchApi,
            searchurl: searchurl,
          };

          Hexapi(obj)
            .then((judges) => {
              console.log(judges);
              if (judges.length > 0) {
                let files_comes_from_judgeSearch = judges;

                // let common = files_comes_from_judgeSearch
                // console.log(common)
                let keys = Object.keys(files_comes_from_paramSearch);
                let input = [];
                console.log(keys);
                for (let i = 0; i < keys.length; i++) {
                  let rs = files_comes_from_paramSearch[keys[i]];
                  for (let j = 0; j < rs.length; j++) {
                    rs[j].code = "/" + rs[j].code + ".htm";
                  }
                  console.log(rs);
                  if (rs.length > 0) {
                    let filtered = files_comes_from_judgeSearch
                      .filter((fileObj) => {
                        // let filepath = fileObj.filepath.replaceAll()
                        if (fileObj.filepath.includes("\\")) {
                          fileObj.filepath = fileObj.filepath
                            .split("\\Web\\")[1]
                            .replaceAll("\\", "/");
                        }
                        return rs.some((spliter) =>
                          fileObj.filepath.includes(spliter.code)
                        );
                      })
                      .map((el) => {
                        console.log(el);
                        let obj = { filepath: el.filepath + `${keys[i]}` };
                        return obj;
                      });
                    console.log(filtered);
                    input.push(...filtered);
                  }
                }

                if (input.length > 0) {
                  const input1 = input;
                  input1.forEach((data) => {
                    if (data.filepath.includes("\\")) {
                      data.filepath = data.filepath
                        .split("\\Web\\")[1]
                        .replaceAll("\\", "/");
                    }
                  });

                  // this function creating tree structure
                  this.createTree(input1);

                  this.setState({ allData: input, isDataFound: true });
                } else {
                  this.createTree([]);
                  this.setState({ isDataFound: true });
                }
              } else {
                this.createTree([]);
                this.setState({ isDataFound: true });
              }
            })
            .then(() =>
              this.setState({
                isApiHitComplete: true,
                isTimerModal: false,
                paramSearchObj: null,
                searchPath: "Assets/Data5t/",
              })
            )
            .catch((err) => {
              console.error(err);
              document.getElementById("loadingTimer").innerHTML =
                "Something went wrong. Please try again.";
            });
        } else {
          console.log("coming");
          this.createTree([]);
          this.setState({ isDataFound: true });
          this.setState({
            isApiHitComplete: true,
            isTimerModal: false,
            paramSearchObj: null,
            searchPath: "Assets/Data5t/",
          });
        }
      });
    } else {
      let obj = {
        search: `${this.state.selectedJudge}`,
        searchType: `2`,
        url: this.props.searchApi,
        searchurl: searchurl,
      };

      Hexapi(obj)
        .then((judges) => {
          if (judges.length > 0) {
            let filterarr = judges;
            console.log(filterarr);
            const input = filterarr;
            input.forEach((data) => {
              if (data.filepath.includes("\\")) {
                data.filepath =
                  data.filepath.split("\\Web\\")[1].replaceAll("\\", "/") +
                  clKey;
              }
            });

            this.createTree(input);
            this.setState({ allData: filterarr, isDataFound: true });
          } else {
            this.createTree([]);
            this.setState({ isDataFound: true });
          }
        })
        .then(() =>
          this.setState({
            isApiHitComplete: true,
            isTimerModal: false,
            searchPath: "Assets/Data5t/",
            selectedJudge: "",
          })
        )
        .catch((err) => {
          console.error(err);
          document.getElementById("loadingTimer").innerHTML =
            "Something went wrong. Please try again.";
        });
    }
  }

  normal_home_case_laws_search(obj, folderpath, field) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    let clKey = "";
    if (field == "GT") {
      clKey = "gt@cl";
    } else if (field == "CU") {
      clKey = "cu@cl";
    } else if (field == "ST") {
      clKey = "st@cl";
    } else if (field == "CE") {
      clKey = "ce@cl";
    } else if (field == "-1") {
      clKey = "gt@cl";
    }
    console.log(clKey);
    Hexapi(obj)
      .then((resp) => {
        console.log(resp);
        let files_comes_from_paramSearch = {};
        files_comes_from_paramSearch = resp[clKey];
        console.log(files_comes_from_paramSearch);
        if (files_comes_from_paramSearch.length > 0) {
          this.onParametricSearch(
            "",
            this.state.ParametericsearchUrl,
            folderpath
          )
            .then((files) => {
              console.log(files);
              if (files.length > 0) {
                let files_comes_from_contentSearch = files;
                // console.log(files_comes_from_contentSearch)
                let common = [];
                let CaseLawsfiles = files_comes_from_contentSearch; //.filter((fileObj) => fileObj.filepath.includes('/cl/'));
                //let keys = Object.keys(files_comes_from_paramSearch)
                //console.log(keys)
                //for (let i = 0; i < keys.length; i++) {
                let rscode = []; //files_comes_from_paramSearch;
                for (let j = 0; j < files_comes_from_paramSearch.length; j++) {
                  rscode.push("" + files_comes_from_paramSearch[j].code);
                }
                //console.log(rs, i)
                //let values =  Object.values(files_comes_from_paramSearch);
                //console.log(values);
                console.log(rscode);
                if (rscode.length > 0) {
                  let filtered = CaseLawsfiles.filter((fileObj) => {
                    //console.log(fileObj.filename)
                    //console.log(files_comes_from_paramSearch.indexOf(fileObj.filename))
                    return rscode.includes(fileObj.filename);
                  }).map((el) => {
                    // console.log(el)
                    let obj = { filepath: el.filepath + clKey };
                    return obj;
                  });
                  console.log(filtered);
                  common.push(...filtered);
                }
                //}
                console.log("CaseLawsfiles=>", CaseLawsfiles);
                console.log("Tree Array => ", common);

                if (common.length > 0) {
                  const input = common;
                  input.forEach((data) => {
                    if (data.filepath.includes("\\")) {
                      data.filepath = data.filepath
                        .split("\\Web\\")[1]
                        .replaceAll("\\", "/");
                    }
                  });

                  // this function creating tree structure
                  // console.log(input)
                  this.createTree(input);

                  this.setState({ allData: common, isDataFound: true });
                } else {
                  this.createTree([]);
                  this.setState({ isDataFound: true });
                }
              } else {
                this.createTree([]);
                this.setState({ isDataFound: true });
              }
            })
            .then(() =>
              this.setState({
                isApiHitComplete: true,
                isTimerModal: false,
                paramSearchObj: null,
                searchPath: "Assets/Data5t/",
              })
            )
            .catch((err) => {
              console.error(err);
              document.getElementById("loadingTimer").innerHTML =
                "Something went wrong. Please try again.";
            });
        } else {
          this.createTree([]);
          this.setState({ isDataFound: true });
          this.setState({
            isApiHitComplete: true,
            isTimerModal: false,
            paramSearchObj: null,
            searchPath: "Assets/Data5t/",
          });
        }
      })
      .catch((err) => console.error(err));
  }

  normal_judge_and_content_search(
    obj,
    searchWord,
    searchurl,
    isSearchWithCaseLawsGetData
  ) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    let clKey = "";
    if (this.state.selectedSubField == "-1") {
      if (this.state.selectedField == "GT") {
        clKey = "gt@cl";
      } else if (this.state.selectedField == "CU") {
        clKey = "cu@cl";
      } else if (this.state.selectedField == "ST") {
        clKey = "st@cl";
      } else if (this.state.selectedField == "CE") {
        clKey = "ce@cl";
      } else if (this.state.selectedField == "-1") {
        clKey = "gt@cl";
      }
    } else {
      if (this.state.selectedField == "GT") {
        clKey = "gt@adv";
      } else if (this.state.selectedField == "CU") {
        clKey = "cu@adv";
      }
    }

    console.log(clKey);

    if (isSearchWithCaseLawsGetData == "1") {
      Hexapi(obj)
        .then((resp) => {
          console.log(resp);
          if (resp[clKey] != undefined && resp[clKey].length > 0) {
            let files_comes_from_paramSearch = {};
            files_comes_from_paramSearch[clKey] = resp[clKey];
            // console.log(files_comes_from_paramSearch)
            if (files_comes_from_paramSearch[clKey].length > 0) {
              let obj = {
                search: `${searchWord}`,
                searchType: `${this.state.searchType}`,
                url: this.props.searchApi,
                searchurl: searchurl,
              };

              Hexapi(obj).then((files) => {
                console.log(files);
                if (files.length > 0) {
                  let files_comes_from_contentSearch = files;

                  let obj = {
                    search: `${this.state.selectedJudge}`,
                    searchType: `2`,
                    url: this.props.searchApi,
                    searchurl: searchurl,
                  };

                  Hexapi(obj)
                    .then((judges) => {
                      console.log(judges);
                      if (judges.length > 0) {
                        let files_comes_from_judgeSearch = judges;

                        let common = files_comes_from_judgeSearch.filter(
                          (obj1) =>
                            files_comes_from_contentSearch.some(
                              (obj2) => obj1.filepath == obj2.filepath
                            )
                        );
                        // console.log(common)
                        let keys = Object.keys(files_comes_from_paramSearch);
                        let input = [];
                        console.log(keys);
                        for (let i = 0; i < keys.length; i++) {
                          let rs = files_comes_from_paramSearch[keys[i]];
                          for (let j = 0; j < rs.length; j++) {
                            rs[j].code = "/" + rs[j].code + ".htm";
                          }
                          console.log(rs);
                          if (rs.length > 0) {
                            let filtered = common
                              .filter((fileObj) => {
                                // let filepath = fileObj.filepath.replaceAll()
                                if (fileObj.filepath.includes("\\")) {
                                  fileObj.filepath = fileObj.filepath
                                    .split("\\Web\\")[1]
                                    .replaceAll("\\", "/");
                                }
                                return rs.some((spliter) =>
                                  fileObj.filepath.includes(spliter.code)
                                );
                              })
                              .map((el) => {
                                console.log(el);
                                let obj = {
                                  filepath: el.filepath + `${keys[i]}`,
                                };
                                return obj;
                              });
                            console.log(filtered);
                            input.push(...filtered);
                          }
                        }

                        if (input.length > 0) {
                          const input1 = input;
                          input1.forEach((data) => {
                            if (data.filepath.includes("\\")) {
                              data.filepath = data.filepath
                                .split("\\Web\\")[1]
                                .replaceAll("\\", "/");
                            }
                          });

                          // this function creating tree structure
                          this.createTree(input1);

                          this.setState({ allData: input, isDataFound: true });
                        } else {
                          this.createTree([]);
                          this.setState({ isDataFound: true });
                        }
                      } else {
                        this.createTree([]);
                        this.setState({ isDataFound: true });
                      }
                    })
                    .then(() =>
                      this.setState({
                        isApiHitComplete: true,
                        isTimerModal: false,
                        paramSearchObj: null,
                        searchPath: "Assets/Data5t/",
                      })
                    )
                    .catch((err) => {
                      console.error(err);
                      document.getElementById("loadingTimer").innerHTML =
                        "Something went wrong. Please try again.";
                    });
                } else {
                  this.createTree([]);
                  this.setState({ isDataFound: true });
                }
              });
            } else {
              this.createTree([]);
              this.setState({ isDataFound: true });
            }
          } else {
            this.createTree([]);
            this.setState({ isDataFound: true });
            this.setState({
              isApiHitComplete: true,
              isTimerModal: false,
              paramSearchObj: null,
              searchPath: "Assets/Data5t/",
            });
          }
        })
        .catch((err) => console.error(err));
    } else {
      let obj = {
        search: `${searchWord}`,
        searchType: `${this.state.searchType}`,
        url: this.props.searchApi,
        searchurl: searchurl,
      };
      Hexapi(obj)
        .then((files) => {
          console.log(files);
          let array_comes_from_contentSearch = files;
          if (array_comes_from_contentSearch.length > 0) {
            let obj = {
              search: `${this.state.selectedJudge}`,
              searchType: `2`,
              url: this.props.searchApi,
              searchurl: searchurl,
            };

            Hexapi(obj).then((judges) => {
              console.log(judges);
              let filterarr = array_comes_from_contentSearch.filter(
                (fileobj1) =>
                  judges.some(
                    (fileobj2) => fileobj1.filepath == fileobj2.filepath
                  )
              );
              console.log(filterarr);
              const input = filterarr;
              input.forEach((data) => {
                if (data.filepath.includes("\\")) {
                  data.filepath =
                    data.filepath.split("\\Web\\")[1].replaceAll("\\", "/") +
                    clKey;
                }
              });

              this.createTree(input);
              this.setState({ allData: filterarr, isDataFound: true });
            });
          } else {
            this.createTree([]);
            this.setState({ isDataFound: true });
          }
        })
        .then(() =>
          this.setState({
            isApiHitComplete: true,
            isTimerModal: false,
            searchPath: "Assets/Data5t/",
            selectedJudge: "",
          })
        )
        .catch((err) => {
          console.error(err);
          document.getElementById("loadingTimer").innerHTML =
            "Something went wrong. Please try again.";
        });
    }
  }

  circular_noti_normal_search(obj, folderpath) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    Hexapi(obj)
      .then((resp) => {
        console.log(resp);
        let files_comes_from_paramSearch = {};
        files_comes_from_paramSearch[""] = resp[""];
        console.log(files_comes_from_paramSearch);
        if (Object.keys(files_comes_from_paramSearch).length > 0) {
          this.onParametricSearch(
            "",
            this.state.ParametericsearchUrl,
            folderpath
          )
            .then((files) => {
              console.log(files);
              if (files.length > 0) {
                let files_comes_from_contentSearch = files;
                console.log(files_comes_from_contentSearch);
                let common = [];
                let withoutCaseLawsFiles =
                  files_comes_from_contentSearch.filter(
                    (fileObj) => !fileObj.filepath.includes("/cl/")
                  );

                let keys = Object.keys(files_comes_from_paramSearch);
                console.log(keys);
                for (let i = 0; i < keys.length; i++) {
                  let rs = files_comes_from_paramSearch[keys[i]];
                  console.log(rs, i);
                  if (rs.length > 0) {
                    let filtered = [];
                    rs.forEach((spliter) => {
                      let obj = {};
                      withoutCaseLawsFiles.forEach((fileObj) => {
                        if (
                          fileObj.filepath.includes(spliter.code) &&
                          !filtered.some((fileobj) =>
                            fileobj.filepath.includes(spliter.code)
                          )
                        ) {
                          obj["filepath"] = fileObj.filepath;
                          console.log(spliter.code, fileObj.filepath);
                          filtered.push(obj);
                        }
                      });
                      // return withoutCaseLawsFiles.some((fileObj) => fileObj.filepath.includes(spliter.code))
                    });
                    // filtered = filtered.map((el) => {
                    //     // console.log(el)
                    //     let obj = { filepath: el.filepath }
                    //     return obj
                    // })
                    console.log(filtered);
                    common.push(...filtered);
                  }
                }
                console.log("withoutCaseLawsFiles=>", withoutCaseLawsFiles);
                console.log("Tree Array => ", common);

                if (common.length > 0) {
                  const input = common;
                  input.forEach((data) => {
                    if (data.filepath.includes("\\")) {
                      data.filepath = data.filepath
                        .split("\\Web\\")[1]
                        .replaceAll("\\", "/");
                    }
                  });

                  // this function creating tree structure
                  this.createTree(input);

                  this.setState({ allData: common, isDataFound: true });
                } else {
                  this.createTree([]);
                  this.setState({ isDataFound: true });
                }
              } else {
                this.createTree([]);
                this.setState({ isDataFound: true });
              }
            })
            .then(() =>
              this.setState({
                isApiHitComplete: true,
                isTimerModal: false,
                paramSearchObj: null,
                searchPath: "Assets/Data5t/",
              })
            )
            .catch((err) => {
              console.error(err);
              document.getElementById("loadingTimer").innerHTML =
                "Something went wrong. Please try again.";
            });
        } else {
          this.createTree([]);
          this.setState({ isDataFound: true });
          this.setState({ isApiHitComplete: true, paramSearchObj: null });
        }
      })
      .catch((err) => console.error(err));
  }

  circular_noti_combine_search(obj, searchWord, searchurl) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    Hexapi(obj)
      .then((resp) => {
        console.log(resp);
        let files_comes_from_paramSearch = {};
        files_comes_from_paramSearch[""] = resp[""];
        console.log(files_comes_from_paramSearch);
        if (Object.keys(files_comes_from_paramSearch).length > 0) {
          let obj = {
            search: `${searchWord}`,
            searchType: `${this.state.searchType}`,
            url: this.props.searchApi,
            searchurl: searchurl,
          };
          Hexapi(obj)
            .then((files) => {
              let files_comes_from_contentSearch = files;
              console.log(files_comes_from_contentSearch);
              let common = [];
              // let caseLawsFiles = files_comes_from_contentSearch.filter((fileObj) => fileObj.filepath.includes('\\cl\\'))
              let withoutCaseLawsFiles = files_comes_from_contentSearch.filter(
                (fileObj) => !fileObj.filepath.includes("\\cl\\")
              );
              // common.push(...withoutCaseLawsFiles)
              // console.log(caseLawsFiles)
              // // if()
              let keys = Object.keys(files_comes_from_paramSearch);
              console.log(keys);
              for (let i = 0; i < keys.length; i++) {
                let rs = files_comes_from_paramSearch[keys[i]];
                for (let j = 0; j < rs.length; j++) {
                  rs[j].code = "/" + rs[j].code + ".htm";
                }
                console.log(rs);
                if (rs.length > 0) {
                  let filtered = withoutCaseLawsFiles
                    .filter((fileObj) => {
                      // let filepath = fileObj.filepath.replaceAll()
                      if (fileObj.filepath.includes("\\")) {
                        fileObj.filepath = fileObj.filepath
                          .split("\\Web\\")[1]
                          .replaceAll("\\", "/");
                      }
                      return rs.some((spliter) =>
                        fileObj.filepath.includes(spliter.code)
                      );
                    })
                    .map((el) => {
                      // console.log(el)
                      let obj = { filepath: el.filepath + `${keys[i]}` };
                      return obj;
                    });
                  console.log(filtered);
                  common.push(...filtered);
                }
              }
              // common.push(...caseLawsFiles.filter((fileObj) => files_comes_from_paramSearch.some((spliter) => fileObj.filepath.toLowerCase().includes(spliter.toLowerCase()))))
              // console.log('CaseLawsfiles=>', caseLawsFiles)
              console.log("withoutCaseLawsFiles=>", withoutCaseLawsFiles);
              console.log("Tree Array => ", common);

              if (common.length > 0) {
                const input = common;
                input.forEach((data) => {
                  if (data.filepath.includes("\\")) {
                    data.filepath = data.filepath
                      .split("\\Web\\")[1]
                      .replaceAll("\\", "/");
                  }
                });

                // this function creating tree structure
                this.createTree(input);

                this.setState({ allData: common, isDataFound: true });
              } else {
                this.createTree([]);
                this.setState({ isDataFound: true });
              }
            })
            .then(() =>
              this.setState({
                isApiHitComplete: true,
                isTimerModal: false,
                paramSearchObj: null,
                searchPath: "Assets/Data5t/",
              })
            )
            .catch((err) => {
              console.error(err);
              document.getElementById("loadingTimer").innerHTML =
                "Something went wrong. Please try again.";
            });
        } else {
          this.createTree([]);
          this.setState({ isDataFound: true });
          this.setState({ isApiHitComplete: true, paramSearchObj: null });
        }
      })
      .catch((err) => console.error(err));
  }

  normal_all_search(obj, searchWord, searchurl) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    Hexapi(obj)
      .then((resp) => {
        console.log(resp);
        let files_comes_from_paramSearch = resp;
        console.log(files_comes_from_paramSearch);
        if (Object.keys(files_comes_from_paramSearch).length > 0) {
          let obj = {
            search: `${searchWord}`,
            searchType: `${this.state.searchType}`,
            url: this.props.searchApi,
            searchurl: searchurl,
          };
          Hexapi(obj)
            .then((files) => {
              let files_comes_from_contentSearch = files;
              console.log(files_comes_from_contentSearch);
              let common = [];
              let caseLawsFiles = files_comes_from_contentSearch.filter(
                (fileObj) => fileObj.filepath.includes("\\cl\\")
              );
              let withoutCaseLawsFiles = files_comes_from_contentSearch.filter(
                (fileObj) => !fileObj.filepath.includes("\\cl\\")
              );
              common.push(...withoutCaseLawsFiles);
              // // if()
              let keys = Object.keys(files_comes_from_paramSearch);
              console.log(keys);
              for (let i = 0; i < keys.length; i++) {
                let rs = resp[keys[i]];
                console.log(rs);
                if (rs.length > 0) {
                  let filtered = caseLawsFiles
                    .filter((fileObj) =>
                      rs.some((spliter) => {
                        return fileObj.filepath.includes(
                          "\\" + spliter.code + ".htm"
                        );
                      })
                    )
                    .map((el) => {
                      let obj = { filepath: el.filepath + `${keys[i]}` };
                      // console.log(obj)
                      return obj;
                    });
                  // console.log(filtered)
                  common.push(...filtered);
                }
              }
              // common.push(...caseLawsFiles.filter((fileObj) => files_comes_from_paramSearch.some((spliter) => fileObj.filepath.toLowerCase().includes(spliter.toLowerCase()))))
              console.log("CaseLawsfiles=>", caseLawsFiles);
              console.log("withoutCaseLawsFiles=>", withoutCaseLawsFiles);
              console.log("Tree Array => ", common);

              if (common.length > 0) {
                const input = common;
                input.forEach((data) => {
                  if (data.filepath.includes("\\")) {
                    data.filepath = data.filepath
                      .split("\\Web\\")[1]
                      .replaceAll("\\", "/");
                  }
                });

                // this function creating tree structure
                this.createTree(input);

                this.setState({ allData: common, isDataFound: true });
              } else {
                this.createTree([]);
                this.setState({ isDataFound: true });
              }
            })
            .then(() =>
              this.setState({
                isApiHitComplete: true,
                isTimerModal: false,
                paramSearchObj: null,
                searchPath: "Assets/Data5t/",
              })
            )
            .catch((err) => {
              console.error(err);
              // document.getElementById('loadingTimer').innerHTML = 'Something went wrong. Please try again.'
            });
        } else {
          this.setState({
            isApiHitComplete: true,
            isTimerModal: false,
            paramSearchObj: null,
            searchPath: "Assets/Data5t/",
          });
        }
      })
      .catch((err) => console.error(err));
  }

  normal_caseLaws_param_search(obj, folderpath) {
    console.log(obj);
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    let clKey = "";
    if (this.state.selectedSubField == "-1") {
      if (this.state.selectedField == "GT") {
        clKey = "gt@cl";
      } else if (this.state.selectedField == "CU") {
        clKey = "cu@cl";
      } else if (this.state.selectedField == "ST") {
        clKey = "st@cl";
      } else if (this.state.selectedField == "CE") {
        clKey = "ce@cl";
      } else if (this.state.selectedField == "-1") {
        clKey = "gt@cl";
      }
    } else {
      if (this.state.selectedField == "GT") {
        clKey = "gt@adv";
      } else if (this.state.selectedField == "CU") {
        clKey = "cu@adv";
      }
    }

    console.log(clKey);
    Hexapi(obj).then((resp) => {
      // console.log(resp[""][0]['courtlevel'])
      var filtered = [];
      if (resp[""].length > 0) {
        for (var i = 0; i < resp[""].length; i++) {
          let file = "";
          console.log(resp[""][i].courtlevel);
          if (resp[""][i].courtlevel == "1") {
            file = `cl/clsupr/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
          } else if (resp[""][i].courtlevel == "2") {
            file = `cl/clhigh/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
          } else if (resp[""][i].courtlevel == "3") {
            if (resp[""][i].courtname.toLowerCase() == "bangalore tribunal") {
              file = `cl/cltban/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
            } else if (
              resp[""][i].courtname.toLowerCase() == "chennai tribunal"
            ) {
              file = `cl/cltche/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
            } else if (
              resp[""][i].courtname.toLowerCase() == "delhi tribunal"
            ) {
              file = `cl/cltdel/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
            } else if (
              resp[""][i].courtname.toLowerCase() == "kolkata tribunal"
            ) {
              file = `cl/cltkol/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
            } else if (resp[""][i].courtname.toLowerCase() == "larger bench") {
              file = `cl/cltlar/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
            } else if (
              resp[""][i].courtname.toLowerCase() == "mumbai tribunal"
            ) {
              file = `cl/cltmum/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
            } else if (resp[""][i].courtname.toLowerCase() == "tribunal") {
              file = `cl/cltrib/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
            } else if (
              resp[""][i].courtname.toLowerCase() ==
              "central administrative tribunal"
            ) {
              file = `cl/cltrib/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
            } else if (
              resp[""][i].courtname.toLowerCase() == "hyderabad tribunal"
            ) {
              file = `cl/clthyd/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
            } else if (
              resp[""][i].courtname.toLowerCase() == "ahmedabad tribunal"
            ) {
              file = `cl/cltahm/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
            } else if (
              resp[""][i].courtname.toLowerCase() == "chandigarh tribunal"
            ) {
              file = `cl/cltchd/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
            } else if (
              resp[""][i].courtname.toLowerCase() == "allahabad tribunal"
            ) {
              file = `cl/cltald/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
            } else {
              file = `cl/cltrib/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
            }
          } else if (resp[""][i].courtlevel == "5") {
            // courttext = "NAA"
            file = `cl/clnapa/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
          } else if (resp[""][i].courtlevel == "6") {
            // courttext = "AAR"
            file = `cl/claarc/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
          } else if (resp[""][i].courtlevel == "7") {
            // courttext = "AAAR"
            file = `cl/claaar/${resp[""][i].field}/${resp[""][i].year}/${resp[""][i].code}.htm`;
          } else if (resp[""][i].courtlevel == "4") {
            // courttext = "O"
          }
          let mainf = file.split("/");

          let path = "Assets/Data5t/" + file;
          var pathobj = { filepath: path };
          filtered.push(pathobj);
        }
        console.log(filtered);
      }

      // this.setState({ allData: filtered, isDataFound: true })
      // let files_comes_from_paramSearch = {};
      // files_comes_from_paramSearch[clKey] = resp['']

      // console.log(files_comes_from_paramSearch)
      // if (files_comes_from_paramSearch[clKey].length > 0) {
      //     this.onParametricSearch('', this.state.ParametericsearchUrl, folderpath).then(files => {
      //         console.log(files)
      //         if (files.length > 0) {
      //             let files_comes_from_contentSearch = files
      //             console.log(files_comes_from_contentSearch)
      //             // let common = files_comes_from_contentSearch.filter(file => files_comes_from_paramSearch.includes(file))
      // let common = []
      //             let caseLawsFiles = files_comes_from_contentSearch.filter((fileObj) => fileObj.filepath.includes('/cl/'))
      //             let withoutCaseLawsFiles = files_comes_from_contentSearch.filter((fileObj) => !fileObj.filepath.includes('/cl/'))
      //             common.push(...withoutCaseLawsFiles)
      //             console.log(caseLawsFiles)
      //             // // if()
      //             let keys = Object.keys(files_comes_from_paramSearch)
      //             console.log(keys)
      //             for (let i = 0; i < keys.length; i++) {
      //                 let rs = files_comes_from_paramSearch[keys[i]];
      //                 for (let j = 0; j < rs.length; j++) {
      //                     rs[j].code = '/' + rs[j].code + '.htm';
      //                 }
      //                 console.log(rs, i)
      //                 if (rs.length > 0) {
      //                     let filtered = caseLawsFiles.filter((fileObj) => {
      //                         // let filepath = fileObj.filepath.replaceAll()
      //                         // if (fileObj.filepath.includes('\\')) {
      //                         //     fileObj.filepath = fileObj.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
      //                         // }
      //                         return rs.some((spliter) => fileObj.filepath.includes(spliter.code))
      //                     }).map((el) => {
      //                         // console.log(el)
      //                         let obj = { filepath: el.filepath + `${keys[i]}` }
      //                         return obj
      //                     })
      //                     console.log(filtered)
      // common.push(...filtered)
      //                 }
      //             }
      //             // common.push(...caseLawsFiles.filter((fileObj) => files_comes_from_paramSearch.some((spliter) => fileObj.filepath.toLowerCase().includes(spliter.toLowerCase()))))
      //             console.log('CaseLawsfiles=>', caseLawsFiles)
      //             console.log('withoutCaseLawsFiles=>', withoutCaseLawsFiles)
      //             console.log('Tree Array => ', common)

      //             if (common.length > 0) {
      //                 const input = common
      //                 input.forEach((data) => {
      //                     if (data.filepath.includes('\\')) {
      //                         data.filepath = data.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
      //                     }
      //                 })

      //                 // this function creating tree structure
      //                 this.createTree(input)

      //                 this.setState({ allData: common, isDataFound: true })
      //             } else {
      //                 this.createTree([])
      //                 this.setState({ isDataFound: true })
      //             }

      //         } else {
      //             this.createTree([])
      //             this.setState({ isDataFound: true })
      //         }
      //     }
      //     ).then(() => this.setState({ isApiHitComplete: true, isTimerModal: false, paramSearchObj: null, searchPath: 'Assets/Data5t/' })).catch(err => {
      //         console.error(err)
      //         document.getElementById('loadingTimer').innerHTML = 'Something went wrong. Please try again.'
      //     })
      // }
      // else {
      this.createTree(filtered);
      this.setState({ isDataFound: true, allData: filtered });
      this.setState({
        isApiHitComplete: true,
        isTimerModal: false,
        paramSearchObj: null,
        searchPath: "Assets/Data5t/",
      });
      // }
    });
  }

  combine_caseLaws_param_search(obj, searchWord, searchurl) {
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    let clKey = "";
    if (this.state.selectedSubField == "-1") {
      if (this.state.selectedField == "GT") {
        clKey = "gt@cl";
      } else if (this.state.selectedField == "CU") {
        clKey = "cu@cl";
      } else if (this.state.selectedField == "ST") {
        clKey = "st@cl";
      } else if (this.state.selectedField == "CE") {
        clKey = "ce@cl";
      } else if (this.state.selectedField == "-1") {
        clKey = "gt@cl";
      }
    } else {
      if (this.state.selectedField == "GT") {
        clKey = "gt@adv";
      } else if (this.state.selectedField == "CU") {
        clKey = "cu@adv";
      }
    }
    console.log(clKey);
    Hexapi(obj)
      .then((resp) => {
        console.log(resp);
        let files_comes_from_paramSearch = {};
        files_comes_from_paramSearch[clKey] = resp[""];
        console.log(files_comes_from_paramSearch);
        if (Object.keys(files_comes_from_paramSearch).length > 0) {
          let obj = {
            search: `${searchWord}`,
            searchType: `${this.state.searchType}`,
            url: this.props.searchApi,
            searchurl: searchurl,
          };
          Hexapi(obj)
            .then((files) => {
              let files_comes_from_contentSearch = files;
              console.log(files_comes_from_contentSearch);
              // let common = files_comes_from_contentSearch.filter(file => files_comes_from_paramSearch.includes(file))
              let common = [];
              let caseLawsFiles = files_comes_from_contentSearch.filter(
                (fileObj) => fileObj.filepath.includes("\\cl\\")
              );
              let withoutCaseLawsFiles = files_comes_from_contentSearch.filter(
                (fileObj) => !fileObj.filepath.includes("\\cl\\")
              );
              common.push(...withoutCaseLawsFiles);
              console.log(caseLawsFiles);
              // // if()
              let keys = Object.keys(files_comes_from_paramSearch);
              console.log(keys);
              for (let i = 0; i < keys.length; i++) {
                let rs = files_comes_from_paramSearch[keys[i]];
                for (let j = 0; j < rs.length; j++) {
                  rs[j].code = "/" + rs[j].code + ".htm";
                }
                console.log(rs);
                if (rs.length > 0) {
                  console.log(rs);
                  let filtered = caseLawsFiles
                    .filter((fileObj) => {
                      // let filepath = fileObj.filepath.replaceAll()
                      if (fileObj.filepath.includes("\\")) {
                        fileObj.filepath = fileObj.filepath
                          .split("\\Web\\")[1]
                          .replaceAll("\\", "/");
                      }
                      // console.log(fileObj.filepath.includes(rs[i].code))
                      return rs.some((spliter) =>
                        fileObj.filepath.includes(spliter.code)
                      );
                    })
                    .map((el) => {
                      // console.log(el)
                      let obj = { filepath: el.filepath + `${keys[i]}` };
                      return obj;
                    });
                  console.log(filtered);
                  common.push(...filtered);
                }
              }
              console.log("CaseLawsfiles=>", caseLawsFiles);
              console.log("withoutCaseLawsFiles=>", withoutCaseLawsFiles);
              console.log("Tree Array => ", common);

              if (common.length > 0) {
                const input = common;
                input.forEach((data) => {
                  if (data.filepath.includes("\\")) {
                    data.filepath = data.filepath
                      .split("\\Web\\")[1]
                      .replaceAll("\\", "/");
                  }
                });

                // this function creating tree structure
                this.createTree(input);

                this.setState({ allData: common, isDataFound: true });
              } else {
                this.createTree([]);
                this.setState({ isDataFound: true });
              }
            })
            .then(() =>
              this.setState({
                isApiHitComplete: true,
                isTimerModal: false,
                paramSearchObj: null,
                searchPath: "Assets/Data5t/",
              })
            )
            .catch((err) => {
              console.error(err);
              document.getElementById("loadingTimer").innerHTML =
                "Something went wrong. Please try again.";
            });
        } else {
          this.createTree([]);
          this.setState({ isDataFound: true });
          this.setState({ isApiHitComplete: true, paramSearchObj: null });
        }
      })
      .catch((err) => console.error(err));
  }

  normal_caseLaws_content_search(
    obj,
    searchWord,
    searchurl,
    isSearchWithCaseLawsGetData
  ) {
    console.log(searchurl);
    this.setState({
      isTimerModal: true,
      isOnlyParamSearch: false,
      isHistoryOpen: false,
      openInitialModal: false,
      isFetchFileApiHitComplete: false,
      isFirstSearchComplete: true,
      isApiHitComplete: false,
      isDataFound: false,
      selectedFile: "",
    });
    let clKey = "";
    // if (this.state.selectedSubField == '-1') {
    //     if (this.state.selectedField == 'GT') {
    //         clKey = 'gt@cl'
    //     } else if (this.state.selectedField == "CU") {
    //         clKey = 'cu@cl'
    //     } else if (this.state.selectedField == 'ST') {
    //         clKey = 'st@cl'
    //     } else if (this.state.selectedField == 'CE') {
    //         clKey = 'ce@cl'
    //     } else if (this.state.selectedField == "-1") {
    //         clKey = 'gt@cl'
    //     }
    // } else {
    //     if (this.state.selectedField == 'GT') {
    //         clKey = 'gt@adv'
    //     } else if (this.state.selectedField == "CU") {
    //         clKey = 'cu@adv'
    //     }
    // }
    // console.log(clKey)

    if (isSearchWithCaseLawsGetData == "1") {
      Hexapi(obj)
        .then((resp) => {
          console.log(resp);
          if (resp[clKey] != undefined && resp[clKey].length > 0) {
            let files_comes_from_paramSearch = {};
            files_comes_from_paramSearch[clKey] = resp[clKey];
            console.log(files_comes_from_paramSearch);
            if (Object.keys(files_comes_from_paramSearch).length > 0) {
              let obj = {
                search: `${searchWord}`,
                searchType: `${this.state.searchType}`,
                url: this.props.searchApi,
                searchurl: searchurl,
              };
              Hexapi(obj)
                .then((files) => {
                  let files_comes_from_contentSearch = files;
                  console.log(files_comes_from_contentSearch);
                  // let common = files_comes_from_contentSearch.filter(file => files_comes_from_paramSearch.includes(file))
                  let common = [];
                  let caseLawsFiles = files_comes_from_contentSearch.filter(
                    (fileObj) => fileObj.filepath.includes("\\cl\\")
                  );
                  let withoutCaseLawsFiles =
                    files_comes_from_contentSearch.filter(
                      (fileObj) => !fileObj.filepath.includes("\\cl\\")
                    );
                  common.push(...withoutCaseLawsFiles);
                  console.log(caseLawsFiles);
                  // // if()
                  let keys = Object.keys(files_comes_from_paramSearch);
                  console.log(keys);
                  for (let i = 0; i < keys.length; i++) {
                    let rs = files_comes_from_paramSearch[keys[i]];
                    console.log(rs);
                    if (rs.length > 0) {
                      let filtered = caseLawsFiles
                        .filter((fileObj) => {
                          // let filepath = fileObj.filepath.replaceAll()
                          if (fileObj.filepath.includes("\\")) {
                            fileObj.filepath = fileObj.filepath
                              .split("\\Web\\")[1]
                              .replaceAll("\\", "/");
                          }
                          return rs.some((spliter) =>
                            fileObj.filepath.includes(spliter.code)
                          );
                        })
                        .map((el) => {
                          // console.log(el)
                          let obj = { filepath: el.filepath + `${keys[i]}` };
                          return obj;
                        });
                      console.log(
                        "=============================these files are not available ==================="
                      );
                      // let f = caseLawsFiles.filter((fileObj) => {
                      //     // let filepath = fileObj.filepath.replaceAll()
                      //     if (fileObj.filepath.includes('\\')) {
                      //         fileObj.filepath = fileObj.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
                      //     }
                      //     return !rs.some((spliter) => fileObj.filepath.includes(spliter.code))
                      // })
                      let f = rs.filter((obj) => {
                        return !caseLawsFiles.some((file) =>
                          file.filepath.includes(obj.code)
                        );
                      });
                      console.log(JSON.stringify(f));
                      console.log(
                        "=============================these files are not available ==================="
                      );
                      console.log(filtered);
                      common.push(...filtered);
                    }
                  }
                  console.log("CaseLawsfiles=>", caseLawsFiles);
                  console.log("withoutCaseLawsFiles=>", withoutCaseLawsFiles);
                  console.log("Tree Array => ", common);

                  if (common.length > 0) {
                    const input = common;
                    input.forEach((data) => {
                      if (data.filepath.includes("\\")) {
                        data.filepath = data.filepath
                          .split("\\Web\\")[1]
                          .replaceAll("\\", "/");
                      }
                    });

                    // this function creating tree structure
                    this.createTree(input);

                    this.setState({ allData: common, isDataFound: true });
                  } else {
                    this.createTree([]);
                    this.setState({ isDataFound: true });
                  }
                })
                .then(() =>
                  this.setState({
                    isApiHitComplete: true,
                    isTimerModal: false,
                    paramSearchObj: null,
                    searchPath: "Assets/Data5t/",
                  })
                )
                .catch((err) => {
                  console.error(err);
                  document.getElementById("loadingTimer").innerHTML =
                    "Something went wrong. Please try again.";
                });
            } else {
              this.createTree([]);
              this.setState({ isDataFound: true });
              this.setState({ isApiHitComplete: true, paramSearchObj: null });
            }
          } else {
            this.createTree([]);
            this.setState({ isDataFound: true });
            this.setState({
              isApiHitComplete: true,
              isTimerModal: false,
              paramSearchObj: null,
              searchPath: "Assets/Data5t/",
            });
          }
        })
        .catch((err) => console.error(err));
    } else {
      let obj = {
        search: `${searchWord}`,
        searchType: `${this.state.searchType}`,
        url: this.props.searchApi,
        searchurl: searchurl,
      };
      Hexapi(obj)
        .then((resp) => {
          console.log(resp);

          if (resp.length > 0) {
            const input = resp;
            input.forEach((data) => {
              if (data.filepath.includes("\\")) {
                data.filepath =
                  data.filepath.split("\\Web\\")[1].replaceAll("\\", "/") +
                  clKey;
              }
            });

            this.createTree(input);
            // console.log(input)
            this.setState({ allData: resp, isDataFound: true });
          } else {
            this.createTree([]);
            this.setState({ isDataFound: true });
          }
        })
        .then(() =>
          this.setState({
            isApiHitComplete: true,
            isTimerModal: false,
            searchPath: "Assets/Data5t/",
          })
        )
        .catch((err) => {
          console.error(err);
          document.getElementById("loadingTimer").innerHTML =
            "Something went wrong. Please try again.";
        });
    }
  }
  handleKeyDown = (event) => {
    // if (event.ctrlKey && (event.key === "h" || event.key === "H")) {
    //     event.preventDefault();
    //     this.openHistoryModal();
    // } else if (event.ctrlKey && (event.key === "b" || event.key === "B")) {
    //     event.preventDefault();
    //     this.openBookmarkModal();
    // }

    if (event.ctrlKey && (event.key === "i" || event.key === "I")) {
      event.preventDefault();
      if (
        sessionStorage.getItem("username").includes("LCX_") &&
        this.state.isFetchFileApiHitComplete &&
        !this.state.isOpenInIframe
      ) {
        this.setState({ isOpenInHouseDevelopment: true });
      }
    }
  };
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }
  searchKeyword(searchWord, searchurl) {
    console.log(searchWord, searchurl);
    console.log(this.state.isRefinedSearch);
    searchWord = searchWord.trim();
    console.log("caseLawAdvObj===>", this.state.caseLawAdvObj);
    console.log("paramSearchObj===>", this.state.paramSearchObj);
    console.log("Searchword===>", searchWord);
    console.log(
      "SelectedType ===>",
      this.state.selectedType,
      this.state.isRefinedSearch
    );
    console.log(
      this.state.selectedField,
      this.state.selectedSubField,
      this.state.targetField,
      this.state.targetSubField
    );
    this.setState({ circular: false, notificationtype: false, advorcl: false });

    // New Code Start

    if (this.state.isRefinedSearch) {
      // This case is Where user clicks on refined search icon in tree view
      // This case is Where user clicks search icon from navbar
      if (
        this.state.selectedType != null &&
        this.state.selectedType != undefined
      ) {
        if (this.state.selectedType == "0") {
          // Here selectedType = 0 , refers that user only searching normal cases like act,rules,forms etc-2.
          if (
            searchWord != "" &&
            this.state.paramSearchObj == null &&
            this.state.selectedJudge == ""
          ) {
            // this case is CONTENT+NORMAL
            console.log("this case is CONTENT+NORMAL in Refined Search");
            let subjects = [
              "CGST",
              "IGST",
              "UGST",
              "GST(Compensation to the States) Act",
            ];
            if (
              subjects.includes(this.state.targetField) &&
              this.state.targetSubField == "All"
            ) {
              if (this.state.targetField == "CGST") {
                searchurl =
                  "Assets/Data5t/gt/cl##Assets/Data5t/gt/gtcgst##Assets/Data5t/gt/gtstrl##Assets/Data5t/gt/ctntra##Assets/Data5t/gt/ctntnr##Assets/Data5t/gt/ctcirc##Assets/Data5t/gt/gtcgcu##Assets/Data5t/gt/gtcggu##Assets/Data5t/gt/ctordr##Assets/Data5t/gt/ctorod##Assets/Data5t/gt/gtpres##Assets/Data5t/gt/gstpru##Assets/Data5t/gt/gtport##Assets/Data5t/gt/gtcfrm##Assets/Data5t/gt/gtfaqb";
                console.log(searchurl);
              } else if (this.state.targetField == "IGST") {
                searchurl =
                  "Assets/Data5t/gt/cl##Assets/Data5t/gt/gtigst##Assets/Data5t/gt/gtistr##Assets/Data5t/gt/igntra##Assets/Data5t/gt/igntnr##Assets/Data5t/gt/gtigcu##Assets/Data5t/gt/gtpres##Assets/Data5t/gt/gstpru##Assets/Data5t/gt/gtport##Assets/Data5t/gt/gtcfrm##Assets/Data5t/gt/gtfaqb";
                console.log(searchurl);
              } else if (this.state.targetField == "UGST") {
                searchurl =
                  "Assets/Data5t/gt/cl##Assets/Data5t/gt/gtugst##Assets/Data5t/gt/gtugru##Assets/Data5t/gt/ugntra##Assets/Data5t/gt/ugntnr##Assets/Data5t/gt/ugordr##Assets/Data5t/gt/gtpres##Assets/Data5t/gt/gstpru##Assets/Data5t/gt/gtport##Assets/Data5t/gt/gtcfrm##Assets/Data5t/gt/gtfaqb";
                console.log(searchurl);
              } else if (
                this.state.targetField == "GST(Compensation to the States) Act"
              ) {
                searchurl =
                  "Assets/Data5t/gt/cl##Assets/Data5t/gt/gtcomp##Assets/Data5t/gt/gtcoru##Assets/Data5t/gt/compra##Assets/Data5t/gt/compnr##Assets/Data5t/gt/gtcoci##Assets/Data5t/gt/gtpres##Assets/Data5t/gt/gstpru##Assets/Data5t/gt/gtport##Assets/Data5t/gt/gtcfrm##Assets/Data5t/gt/gtfaqb";
                console.log(searchurl);
              }
            } else if (
              this.state.targetField == "Custom" &&
              this.state.targetSubField == "Case Law & Advance Ruling"
            ) {
              searchurl = "Assets/Data5t/cu/cl";
            } else if (
              this.state.targetField == "Custom" &&
              this.state.targetSubField == "Notification(Tariff)"
            ) {
              searchurl =
                "Assets/Data5t/cu/cuncuv/ad##Assets/Data5t/cu/cuncuv/cv##Assets/Data5t/cu/cuncuv/ot##Assets/Data5t/cu/cuncuv/sg";
            } else if (
              this.state.targetField == "Custom" &&
              this.state.targetSubField == "Notification(Non-Tariff)"
            ) {
              searchurl = "Assets/Data5t/cu/cuncuv/nt";
            } else if (
              this.state.targetField == "Foreign Trade Policy" &&
              this.state.targetSubField == "Case Law & Advance Ruling"
            ) {
              searchurl = "Assets/Data5t/ft/cl";
            } else if (
              this.state.targetField == "Service Tax" &&
              this.state.targetSubField == "Case Law & Advance Ruling"
            ) {
              searchurl = "Assets/Data5t/st/cl";
            } else if (
              this.state.targetField == "Excise" &&
              this.state.targetSubField == "Case Law & Advance Ruling"
            ) {
              searchurl = "Assets/Data5t/ce/cl";
            } else if (
              this.state.targetField == "Excise" &&
              this.state.targetSubField == "Notification(Tariff)"
            ) {
              searchurl = "Assets/Data5t/ce/cencev/ta";
            } else if (
              this.state.targetField == "Excise" &&
              this.state.targetSubField == "Notification(Non-Tariff)"
            ) {
              searchurl = "Assets/Data5t/ce/cencev/nt";
            }
            this.refined_normal_content_search(searchWord, searchurl);
          }
        } else if (this.state.selectedType == "1") {
          // Here selectedType = 1 , refers that user wants to search in circular/Notification.
          let mainfolder, subfolder;
          if (this.state.circNotiObj.field == "Custom") {
            mainfolder = "cu";
            if (this.state.circNotiObj.subfield.includes("Notification")) {
              // this.setState({notificationtype:"custom NT"})
              subfolder = "cuncuv";
            }
            if (this.state.circNotiObj.subfield.includes("Circular")) {
              subfolder = "cuncuc";
            }
          } else if (this.state.circNotiObj.field == "Foreign Trade Policy") {
            mainfolder = "ft";
            if (this.state.circNotiObj.subfield.includes("Notification")) {
              subfolder = "ftnoti";
            }
            if (this.state.circNotiObj.subfield.includes("Circular")) {
              subfolder = "ftcirc";
            }
          } else if (this.state.circNotiObj.field == "Service Tax") {
            mainfolder = "st";
            if (this.state.circNotiObj.subfield.includes("Notification")) {
              subfolder = "stncsv";
            }
            if (this.state.circNotiObj.subfield.includes("Circular")) {
              subfolder = "stncsc";
            }
          } else if (this.state.circNotiObj.field == "Excise") {
            mainfolder = "ce";
            if (this.state.circNotiObj.subfield.includes("Notification")) {
              subfolder = "cencev";
            }
            if (this.state.circNotiObj.subfield.includes("Circular")) {
              subfolder = "cencec";
            }
          } else if (this.state.circNotiObj.field == "FEMA") {
            mainfolder = "rb";
            if (this.state.circNotiObj.subfield.includes("Notification")) {
              subfolder = "rbnoti";
            }
            if (this.state.circNotiObj.subfield.includes("Circular")) {
              subfolder = "rbcirc";
            }
          } else if (this.state.circNotiObj.field == "SEZ") {
            mainfolder = "sz";
            if (this.state.circNotiObj.subfield.includes("Notification")) {
              subfolder = "sznoti";
            }
            if (this.state.circNotiObj.subfield.includes("Circular")) {
              subfolder = "szcirc";
            }
          } else if (
            this.state.circNotiObj.field ==
              "GST(Compensation to the States) Act" ||
            this.state.circNotiObj.field == "CGST" ||
            this.state.circNotiObj.field == "UGST" ||
            this.state.circNotiObj.field == "IGST"
          ) {
            mainfolder = "gt";
            subfolder = "gtnoti";
          }

          if (searchWord == "") {
            // this case is Circular/Notification+Parametric+NORMAL
            if (this.state.circNotiObj.type.toLowerCase() == "circular") {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_CircularSearch_GetData] @UserID='{0}', @Number='{1}'`,
                queryArr: [
                  `${this.props.userid}`,
                  `${this.state.circNotiObj.number}`,
                ],
              };
              // this.normal_combine_search(obj, searchWord, `Assets/Data5t/${mainfolder}/${subfolder}`)
              console.log("this case is normal circular refined search");
              this.refined_circular_noti_normal_search(
                obj,
                `Assets/Data5t/${mainfolder}/${subfolder}`
              );
              // this.normal_parametric_search(obj, `Assets/Data5t/${mainfolder}/${subfolder}`)
            } else {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_NotificationSearch_GetData]
                                @UserID='{0}',
                                @Number='{1}',
                                @FromDate='{2}',
                                @ToDate='{3}'`,
                queryArr: [
                  `${this.props.userid}`,
                  `${this.state.circNotiObj.number}`,
                  `${this.state.circNotiObj.from}`,
                  `${this.state.circNotiObj.to}`,
                ],
              };
              // this.normal_parametric_search(obj, `Assets/Data5t/${mainfolder}/${subfolder}`)
              // this.normal_combine_search(obj, searchWord, `Assets/Data5t/${mainfolder}/${subfolder}`)
              console.log("this case is normal notification refined search");
              this.refined_circular_noti_normal_search(
                obj,
                `Assets/Data5t/${mainfolder}/${subfolder}`
              );
            }

            console.log(this.state.circNotiObj);
          } else {
            // this case is Circular/Notification+Combine+NORMAL
            if (this.state.circNotiObj.type.toLowerCase() == "circular") {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_CircularSearch_GetData] @UserID='{0}', @Number='{1}'`,
                queryArr: [
                  `${this.props.userid}`,
                  `${this.state.circNotiObj.number}`,
                ],
              };
              // this.normal_parametric_search(obj, `Assets/Data5t/${mainfolder}/${subfolder}`)
              // this.normal_combine_search(obj, searchWord, `Assets/Data5t/${mainfolder}/${subfolder}`)
              console.log("this case is combine circular refined search");
              this.refined_circular_noti_combine_search(
                obj,
                searchWord,
                `Assets/Data5t/${mainfolder}/${subfolder}`
              );
            } else {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_NotificationSearch_GetData]
                                @UserID='{0}',
                                @Number='{1}',
                                @FromDate='{2}',
                                @ToDate='{3}'`,
                queryArr: [
                  `${this.props.userid}`,
                  `${this.state.circNotiObj.number}`,
                  `${this.state.circNotiObj.from}`,
                  `${this.state.circNotiObj.to}`,
                ],
              };
              // this.normal_parametric_search(obj, `Assets/Data5t/${mainfolder}/${subfolder}`)
              // this.normal_combine_search(obj, searchWord, `Assets/Data5t/${mainfolder}/${subfolder}`)
              console.log("this case is combine notification refined search");
              this.refined_circular_noti_combine_search(
                obj,
                searchWord,
                `Assets/Data5t/${mainfolder}/${subfolder}`
              );
            }
            // this.normal_circNoti_param_search()
            console.log(this.state.circNotiObj);
            console.log("this case is Circular/Notification+Combine+NORMAL");
          }
        } else if (this.state.selectedType == "2") {
          console.log(this.state.selectedJudge);
          // Here selectedType = 2 , refers that user wants to search in Case Laws.
          if (
            searchWord == "" &&
            this.state.paramSearchObj == null &&
            this.state.selectedJudge != ""
          ) {
            // this case is ONLY refined JUDGE SEARCH as CONTENT SEARCH
            console.log("this case is refined JUDGE SEARCH as CONTENT SEARCH");

            if (this.state.selectedField == "-1") {
              let obj = {};
              this.refined_normal_judge_search(obj, searchurl, "0");
            } else {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
                queryArr: [`${this.state.selectedField}`, `0`, `-1`],
              };
              this.refined_normal_judge_search(obj, searchurl, "1");
            }
          } else if (searchWord == "" && this.state.paramSearchObj !== null) {
            // this case is case laws + parametric refined search
            let obj = {
              query: `[dbo].[Lawcrux_Web_Proc_ParamtericSearch_GetDataV2] @JudgeID='{0}', @PartyID='{1}', @Landmark='{2}', @Order='{3}', @Citation='{4}', @CitationValue='{5}', @FromDate='{6}', @ToDate='{7}', @Court='{8}', @Favour='{9}',@Field='{10}',@Subfield='{11}'`,
              queryArr: [
                `${this.state.paramSearchObj.judge}`,
                `${this.state.paramSearchObj.party}`,
                `${this.state.paramSearchObj.landmark}`,
                `${this.state.paramSearchObj.order}`,
                `${this.state.paramSearchObj.citation}`,
                `${this.state.paramSearchObj.citationcode}`,
                `${this.state.paramSearchObj.fromdate}`,
                `${this.state.paramSearchObj.todate}`,
                `${this.state.paramSearchObj.court}`,
                `${this.state.paramSearchObj.favour}`,
                `${this.state.selectedField}`,
                `${this.state.selectedSubField}`,
              ],
            };
            console.log("this case is case laws + parametric refined search");
            this.refined_normal_caseLaws_param_search(obj, "Assets/Data5t/cl");
          } else if (searchWord != "" && this.state.paramSearchObj !== null) {
            // this case is case laws combine parametric refined search
            let obj = {
              query: `[dbo].[Lawcrux_Web_Proc_ParamtericSearch_GetDataV2] @JudgeID='{0}', @PartyID='{1}', @Landmark='{2}', @Order='{3}', @Citation='{4}', @CitationValue='{5}', @FromDate='{6}', @ToDate='{7}', @Court='{8}', @Favour='{9}',@Field='{10}',@Subfield='{11}'`,
              queryArr: [
                `${this.state.paramSearchObj.judge}`,
                `${this.state.paramSearchObj.party}`,
                `${this.state.paramSearchObj.landmark}`,
                `${this.state.paramSearchObj.order}`,
                `${this.state.paramSearchObj.citation}`,
                `${this.state.paramSearchObj.citationcode}`,
                `${this.state.paramSearchObj.fromdate}`,
                `${this.state.paramSearchObj.todate}`,
                `${this.state.paramSearchObj.court}`,
                `${this.state.paramSearchObj.favour}`,
                `${this.state.selectedField}`,
                `${this.state.selectedSubField}`,
              ],
            };
            console.log(
              "this case is case laws combine parametric refined search"
            );
            this.refined_combine_caseLaws_param_search(
              obj,
              searchWord,
              "Assets/Data5t/cl"
            );
          } else if (
            searchWord != "" &&
            this.state.paramSearchObj == null &&
            this.state.selectedJudge == ""
          ) {
            // this case is normal case laws content search
            console.log("this case is normal case laws content refined search");
            if (this.state.selectedField == "-1") {
              let obj = {};
              this.refined_normal_caseLaws_content_search(
                obj,
                searchWord,
                "Assets/Data5t/cl",
                "0"
              );
            } else {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
                queryArr: [`${this.state.selectedField}`, `0`, `-1`],
              };
              this.refined_normal_caseLaws_content_search(
                obj,
                searchWord,
                "Assets/Data5t/cl",
                "1"
              );
            }
          } else if (
            searchWord != "" &&
            this.state.paramSearchObj == null &&
            this.state.selectedJudge != ""
          ) {
            // this case is combine case laws judge & content search
            console.log(
              "this case is combine case laws judge & content refined search"
            );
            if (this.state.selectedField == "-1") {
              let obj = {};
              this.refined_normal_judge_and_content_search(
                obj,
                searchWord,
                "Assets/Data5t/cl",
                "0"
              );
            } else {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
                queryArr: [`${this.state.selectedField}`, `0`, `-1`],
              };
              this.refined_normal_judge_and_content_search(
                obj,
                searchWord,
                "Assets/Data5t/cl",
                "1"
              );
            }
          }
        } else if (this.state.selectedType == "3") {
          // Here selectedType = 3 , refers that user wants to search in Advance Ruling.
          if (
            searchWord == "" &&
            this.state.paramSearchObj == null &&
            this.state.selectedJudge != ""
          ) {
            // this case is ONLY advance ruling JUDGE SEARCH as CONTENT SEARCH
            console.log(
              "this case is advance ruling JUDGE SEARCH as CONTENT refined SEARCH"
            );

            if (this.state.selectedField == "-1") {
              let obj = {};
              this.refined_normal_judge_search(obj, searchurl, "0");
            } else {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
                queryArr: [`${this.state.selectedField}`, `0`, `adv`],
              };
              this.refined_normal_judge_search(obj, searchurl, "1");
            }
          } else if (searchWord == "" && this.state.paramSearchObj !== null) {
            // this case is advance ruling + parametric refined search
            let obj = {
              query: `[dbo].[Lawcrux_Web_Proc_ParamtericSearch_GetDataV2] @JudgeID='{0}', @PartyID='{1}', @Landmark='{2}', @Order='{3}', @Citation='{4}', @CitationValue='{5}', @FromDate='{6}', @ToDate='{7}', @Court='{8}', @Favour='{9}',@Field='{10}',@Subfield='{11}'`,
              queryArr: [
                `${this.state.paramSearchObj.judge}`,
                `${this.state.paramSearchObj.party}`,
                `${this.state.paramSearchObj.landmark}`,
                `${this.state.paramSearchObj.order}`,
                `${this.state.paramSearchObj.citation}`,
                `${this.state.paramSearchObj.citationcode}`,
                `${this.state.paramSearchObj.fromdate}`,
                `${this.state.paramSearchObj.todate}`,
                `${this.state.paramSearchObj.court}`,
                `${this.state.paramSearchObj.favour}`,
                `${this.state.selectedField}`,
                `${this.state.selectedSubField}`,
              ],
            };
            console.log(
              "this case is advance ruling + parametric refined search"
            );
            this.refined_normal_caseLaws_param_search(obj, "Assets/Data5t/cl");
          } else if (searchWord != "" && this.state.paramSearchObj !== null) {
            // this case is advance ruling combine parametric refined search
            let obj = {
              query: `[dbo].[Lawcrux_Web_Proc_ParamtericSearch_GetDataV2] @JudgeID='{0}', @PartyID='{1}', @Landmark='{2}', @Order='{3}', @Citation='{4}', @CitationValue='{5}', @FromDate='{6}', @ToDate='{7}', @Court='{8}', @Favour='{9}',@Field='{10}',@Subfield='{11}'`,
              queryArr: [
                `${this.state.paramSearchObj.judge}`,
                `${this.state.paramSearchObj.party}`,
                `${this.state.paramSearchObj.landmark}`,
                `${this.state.paramSearchObj.order}`,
                `${this.state.paramSearchObj.citation}`,
                `${this.state.paramSearchObj.citationcode}`,
                `${this.state.paramSearchObj.fromdate}`,
                `${this.state.paramSearchObj.todate}`,
                `${this.state.paramSearchObj.court}`,
                `${this.state.paramSearchObj.favour}`,
                `${this.state.selectedField}`,
                `${this.state.selectedSubField}`,
              ],
            };
            console.log(
              "this case is advance ruling combine parametric refined search"
            );
            this.refined_combine_caseLaws_param_search(
              obj,
              searchWord,
              "Assets/Data5t/cl"
            );
          } else if (
            searchWord != "" &&
            this.state.paramSearchObj == null &&
            this.state.selectedJudge == ""
          ) {
            // this case is normal advance ruling content refined search
            console.log(
              "this case is normal advance ruling content refined search"
            );
            if (this.state.selectedField == "-1") {
              let obj = {};
              this.refined_normal_caseLaws_content_search(
                obj,
                searchWord,
                "Assets/Data5t/cl",
                "0"
              );
            } else {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
                queryArr: [`${this.state.selectedField}`, `0`, `adv`],
              };
              this.refined_normal_caseLaws_content_search(
                obj,
                searchWord,
                "Assets/Data5t/cl",
                "1"
              );
            }
          } else if (
            searchWord != "" &&
            this.state.paramSearchObj == null &&
            this.state.selectedJudge != ""
          ) {
            // this case is combine advance ruling judge & content refined search
            console.log(
              "this case is combine advance ruling judge & content refined search"
            );
            if (this.state.selectedField == "-1") {
              let obj = {};
              this.refined_normal_judge_and_content_search(
                obj,
                searchWord,
                "Assets/Data5t/cl",
                "0"
              );
            } else {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
                queryArr: [`${this.state.selectedField}`, `0`, `adv`],
              };
              this.refined_normal_judge_and_content_search(
                obj,
                searchWord,
                "Assets/Data5t/cl",
                "1"
              );
            }
          }
        } else if (this.state.selectedType == "4") {
          // Here selectedType = 4 , refers that user wants to search in both case laws & Advance Ruling
          // by selecting ALL from field.
          if (
            searchWord != "" &&
            this.state.paramSearchObj == null &&
            this.state.selectedJudge == ""
          ) {
            // this case is ALL+NORMAL refined search
            let obj = {
              query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
              queryArr: [
                `${this.state.caseLawAdvObj.type}`,
                `0`,
                `${this.state.caseLawAdvObj.value}`,
              ],
            };
            console.log("this case is ALL+NORMAL refined search");
            // this.normal_content_search(searchWord, searchurl)
            this.refined_normal_all_search(obj, searchWord, searchurl);
          }
        }
      } else {
        console.warn(`========================================
                selectedType is undefined or null,Please Verify
                ===================================`);
      }
    } else {
      // This case is Where user clicks search icon from navbar
      if (
        this.state.selectedType != null &&
        this.state.selectedType != undefined
      ) {
        if (this.state.selectedType == "0") {
          // Here selectedType = 0 , refers that user only searching normal cases like act,rules,forms etc-2.
          if (
            searchWord != "" &&
            this.state.paramSearchObj == null &&
            this.state.selectedJudge == ""
          ) {
            // this case is CONTENT+NORMAL
            console.log("this case is CONTENT+NORMAL");
            console.log(this.state.targetField);
            let subjects = [
              "CGST",
              "IGST",
              "UGST",
              "GST(Compensation to the States) Act",
            ];
            if (
              subjects.includes(this.state.targetField) &&
              this.state.targetSubField == "All"
            ) {
              if (this.state.targetField == "CGST") {
                searchurl =
                  "Assets/Data5t/gt/cl##Assets/Data5t/gt/gtcgst##Assets/Data5t/gt/gtstrl##Assets/Data5t/gt/ctntra##Assets/Data5t/gt/ctntnr##Assets/Data5t/gt/ctcirc##Assets/Data5t/gt/gtcgcu##Assets/Data5t/gt/gtcggu##Assets/Data5t/gt/ctordr##Assets/Data5t/gt/ctorod##Assets/Data5t/gt/gtpres##Assets/Data5t/gt/gstpru##Assets/Data5t/gt/gtport##Assets/Data5t/gt/gtcfrm##Assets/Data5t/gt/gtfaqb";
                console.log(searchurl);
              } else if (this.state.targetField == "IGST") {
                searchurl =
                  "Assets/Data5t/gt/cl##Assets/Data5t/gt/gtigst##Assets/Data5t/gt/gtistr##Assets/Data5t/gt/igntra##Assets/Data5t/gt/igntnr##Assets/Data5t/gt/gtigcu##Assets/Data5t/gt/gtpres##Assets/Data5t/gt/gstpru##Assets/Data5t/gt/gtport##Assets/Data5t/gt/gtcfrm##Assets/Data5t/gt/gtfaqb";
                console.log(searchurl);
              } else if (this.state.targetField == "UGST") {
                searchurl =
                  "Assets/Data5t/gt/cl##Assets/Data5t/gt/gtugst##Assets/Data5t/gt/gtugru##Assets/Data5t/gt/ugntra##Assets/Data5t/gt/ugntnr##Assets/Data5t/gt/ugordr##Assets/Data5t/gt/gtpres##Assets/Data5t/gt/gstpru##Assets/Data5t/gt/gtport##Assets/Data5t/gt/gtcfrm##Assets/Data5t/gt/gtfaqb";
                console.log(searchurl);
              } else if (
                this.state.targetField == "GST(Compensation to the States) Act"
              ) {
                searchurl =
                  "Assets/Data5t/gt/cl##Assets/Data5t/gt/gtcomp##Assets/Data5t/gt/gtcoru##Assets/Data5t/gt/compra##Assets/Data5t/gt/compnr##Assets/Data5t/gt/gtcoci##Assets/Data5t/gt/gtpres##Assets/Data5t/gt/gstpru##Assets/Data5t/gt/gtport##Assets/Data5t/gt/gtcfrm##Assets/Data5t/gt/gtfaqb";
                console.log(searchurl);
              }
            } else if (
              this.state.targetField == "Custom" &&
              this.state.targetSubField == "All"
            ) {
              searchurl =
                "Assets/Data5t/cu/cuacts##Assets/Data5t/cu/curule##Assets/Data5t/cu/cuncuv##Assets/Data5t/cu/cuncuc##Assets/Data5t/cu/curegu##Assets/Data5t/cu/cumanu##Assets/Data5t/cu/cutar2##Assets/Data5t/cu/cuform##Assets/Data5t/cu/cuafaqb##Assets/Data5t/cu/cl";
            } else if (
              this.state.targetField == "Custom" &&
              this.state.targetSubField == "Case Law & Advance Ruling"
            ) {
              searchurl = "Assets/Data5t/cu/cl";
            } else if (
              this.state.targetField == "Custom" &&
              this.state.targetSubField == "Notification(Tariff)"
            ) {
              searchurl =
                "Assets/Data5t/cu/cuncuv/ad##Assets/Data5t/cu/cuncuv/cv##Assets/Data5t/cu/cuncuv/ot##Assets/Data5t/cu/cuncuv/sg";
            } else if (
              this.state.targetField == "Custom" &&
              this.state.targetSubField == "Notification(Non-Tariff)"
            ) {
              searchurl = "Assets/Data5t/cu/cuncuv/nt";
            } else if (
              this.state.targetField == "Foreign Trade Policy" &&
              this.state.targetSubField == "Case Law & Advance Ruling"
            ) {
              searchurl = "Assets/Data5t/ft/cl";
            } else if (
              this.state.targetField == "Service Tax" &&
              this.state.targetSubField == "Case Law & Advance Ruling"
            ) {
              searchurl = "Assets/Data5t/st/cl";
            } else if (
              this.state.targetField == "Excise" &&
              this.state.targetSubField == "Case Law & Advance Ruling"
            ) {
              searchurl = "Assets/Data5t/ce/cl";
            } else if (
              this.state.targetField == "Excise" &&
              this.state.targetSubField == "Notification(Tariff)"
            ) {
              searchurl = "Assets/Data5t/ce/cencev/ta";
            } else if (
              this.state.targetField == "Excise" &&
              this.state.targetSubField == "Notification(Non-Tariff)"
            ) {
              searchurl = "Assets/Data5t/ce/cencev/nt";
            }
            this.normal_content_search(searchWord, searchurl);
          }
        } else if (this.state.selectedType == "1") {
          // Here selectedType = 1 , refers that user wants to search in circular/Notification.
          let mainfolder, subfolder;
          console.log(this.state.circNotiObj);
          if (this.state.circNotiObj.field == "Custom") {
            mainfolder = "cu";
            if (this.state.circNotiObj.subfield.includes("Notification")) {
              subfolder = "cuncuv";
            }
            if (this.state.circNotiObj.subfield.includes("Circular")) {
              subfolder = "cuncuc";
            }
          } else if (this.state.circNotiObj.field == "Foreign Trade Policy") {
            mainfolder = "ft";
            if (this.state.circNotiObj.subfield.includes("Notification")) {
              subfolder = "ftnoti";
            }
            if (this.state.circNotiObj.subfield.includes("Circular")) {
              subfolder = "ftcirc";
            }
          } else if (this.state.circNotiObj.field == "Service Tax") {
            mainfolder = "st";
            if (this.state.circNotiObj.subfield.includes("Notification")) {
              subfolder = "stncsv";
            }
            if (this.state.circNotiObj.subfield.includes("Circular")) {
              subfolder = "stncsc";
            }
          } else if (this.state.circNotiObj.field == "Excise") {
            mainfolder = "ce";
            if (this.state.circNotiObj.subfield.includes("Notification")) {
              subfolder = "cencev";
            }
            if (this.state.circNotiObj.subfield.includes("Circular")) {
              subfolder = "cencec";
            }
          } else if (this.state.circNotiObj.field == "FEMA") {
            mainfolder = "rb";
            if (this.state.circNotiObj.subfield.includes("Notification")) {
              subfolder = "rbnoti";
            }
            if (this.state.circNotiObj.subfield.includes("Circular")) {
              subfolder = "rbcirc";
            }
          } else if (this.state.circNotiObj.field == "SEZ") {
            mainfolder = "sz";
            if (this.state.circNotiObj.subfield.includes("Notification")) {
              subfolder = "sznoti";
            }
            if (this.state.circNotiObj.subfield.includes("Circular")) {
              subfolder = "szcirc";
            }
          } else if (
            this.state.circNotiObj.field ==
              "GST(Compensation to the States) Act" ||
            this.state.circNotiObj.field == "CGST" ||
            this.state.circNotiObj.field == "UGST" ||
            this.state.circNotiObj.field == "IGST"
          ) {
            mainfolder = "gt";
            subfolder = "gtnoti";
          }

          if (searchWord == "") {
            // this case is Circular/Notification+Parametric+NORMAL
            if (this.state.circNotiObj.type.toLowerCase() == "circular") {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_CircularSearch_GetData] @UserID='{0}', @Number='{1}'`,
                queryArr: [
                  `${this.props.userid}`,
                  `${this.state.circNotiObj.number}`,
                ],
              };
              // this.normal_combine_search(obj, searchWord, `Assets/Data5t/${mainfolder}/${subfolder}`)
              console.log("this case is normal circular search");
              this.circular_noti_normal_search(
                obj,
                `Assets/Data5t/${mainfolder}/${subfolder}`
              );
              // this.normal_parametric_search(obj, `Assets/Data5t/${mainfolder}/${subfolder}`)
            } else {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_NotificationSearch_GetData]
                                @UserID='{0}',
                                @Number='{1}',
                                @FromDate='{2}',
                                @ToDate='{3}'`,
                queryArr: [
                  `${this.props.userid}`,
                  `${this.state.circNotiObj.number}`,
                  `${this.state.circNotiObj.from}`,
                  `${this.state.circNotiObj.to}`,
                ],
              };
              // this.normal_parametric_search(obj, `Assets/Data5t/${mainfolder}/${subfolder}`)
              // this.normal_combine_search(obj, searchWord, `Assets/Data5t/${mainfolder}/${subfolder}`)
              console.log("this case is normal notification search");
              this.circular_noti_normal_search(
                obj,
                `Assets/Data5t/${mainfolder}/${subfolder}`
              );
            }

            console.log(this.state.circNotiObj);
          } else {
            // this case is Circular/Notification+Combine+NORMAL
            if (this.state.circNotiObj.type.toLowerCase() == "circular") {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_CircularSearch_GetData] @UserID='{0}', @Number='{1}'`,
                queryArr: [
                  `${this.props.userid}`,
                  `${this.state.circNotiObj.number}`,
                ],
              };
              // this.normal_parametric_search(obj, `Assets/Data5t/${mainfolder}/${subfolder}`)
              // this.normal_combine_search(obj, searchWord, `Assets/Data5t/${mainfolder}/${subfolder}`)
              console.log("this case is combine circular search");
              this.circular_noti_combine_search(
                obj,
                searchWord,
                `Assets/Data5t/${mainfolder}/${subfolder}`
              );
            } else {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_NotificationSearch_GetData]
                                @UserID='{0}',
                                @Number='{1}',
                                @FromDate='{2}',
                                @ToDate='{3}'`,
                queryArr: [
                  `${this.props.userid}`,
                  `${this.state.circNotiObj.number}`,
                  `${this.state.circNotiObj.from}`,
                  `${this.state.circNotiObj.to}`,
                ],
              };
              // this.normal_parametric_search(obj, `Assets/Data5t/${mainfolder}/${subfolder}`)
              // this.normal_combine_search(obj, searchWord, `Assets/Data5t/${mainfolder}/${subfolder}`)
              console.log("this case is combine notification search");
              this.circular_noti_combine_search(
                obj,
                searchWord,
                `Assets/Data5t/${mainfolder}/${subfolder}`
              );
            }
            // this.normal_circNoti_param_search()
            console.log(this.state.circNotiObj);
            console.log("this case is Circular/Notification+Combine+NORMAL");
          }
        } else if (this.state.selectedType == "2") {
          console.log(this.state.selectedJudge);
          // Here selectedType = 2 , refers that user wants to search in Case Laws.
          if (
            searchWord == "" &&
            this.state.paramSearchObj == null &&
            this.state.selectedJudge != ""
          ) {
            // this case is ONLY JUDGE SEARCH as CONTENT SEARCH
            console.log("this case is JUDGE SEARCH as CONTENT SEARCH");

            if (this.state.selectedField == "-1") {
              let obj = {};
              this.normal_judge_search(obj, searchurl, "0");
            } else {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
                queryArr: [`${this.state.selectedField}`, `0`, `-1`],
              };
              this.normal_judge_search(obj, searchurl, "1");
            }
          } else if (searchWord == "" && this.state.paramSearchObj !== null) {
            // this case is case laws + parametric search
            let obj = {
              query: `[dbo].[Lawcrux_Web_Proc_ParamtericSearch_GetDataV3] @JudgeID='{0}', @PartyID='{1}', @Landmark='{2}', @Order='{3}', @Citation='{4}', @CitationValue='{5}', @FromDate='{6}', @ToDate='{7}', @Court='{8}', @Favour='{9}',@Field='{10}',@Subfield='{11}'`,
              queryArr: [
                `${this.state.paramSearchObj.judge}`,
                `${this.state.paramSearchObj.party}`,
                `${this.state.paramSearchObj.landmark}`,
                `${this.state.paramSearchObj.order}`,
                `${this.state.paramSearchObj.citation}`,
                `${this.state.paramSearchObj.citationcode}`,
                `${this.state.paramSearchObj.fromdate}`,
                `${this.state.paramSearchObj.todate}`,
                `${this.state.paramSearchObj.court}`,
                `${this.state.paramSearchObj.favour}`,
                `${this.state.selectedField}`,
                `${this.state.selectedSubField}`,
              ],
            };
            console.log("this case is case laws + parametric search");
            this.normal_caseLaws_param_search(obj, searchurl);
          } else if (searchWord != "" && this.state.paramSearchObj !== null) {
            // this case is case laws combine parametric search
            let obj = {
              query: `[dbo].[Lawcrux_Web_Proc_ParamtericSearch_GetDataV2] @JudgeID='{0}', @PartyID='{1}', @Landmark='{2}', @Order='{3}', @Citation='{4}', @CitationValue='{5}', @FromDate='{6}', @ToDate='{7}', @Court='{8}', @Favour='{9}',@Field='{10}',@Subfield='{11}'`,
              queryArr: [
                `${this.state.paramSearchObj.judge}`,
                `${this.state.paramSearchObj.party}`,
                `${this.state.paramSearchObj.landmark}`,
                `${this.state.paramSearchObj.order}`,
                `${this.state.paramSearchObj.citation}`,
                `${this.state.paramSearchObj.citationcode}`,
                `${this.state.paramSearchObj.fromdate}`,
                `${this.state.paramSearchObj.todate}`,
                `${this.state.paramSearchObj.court}`,
                `${this.state.paramSearchObj.favour}`,
                `${this.state.selectedField}`,
                `${this.state.selectedSubField}`,
              ],
            };
            console.log("this case is case laws combine parametric search");
            this.combine_caseLaws_param_search(obj, searchWord, searchurl);
          } else if (
            searchWord != "" &&
            this.state.paramSearchObj == null &&
            this.state.selectedJudge == ""
          ) {
            // this case is normal case laws content search
            console.log("this case is normal case laws content search");
            if (this.state.selectedField == "-1") {
              console.log(
                "this case is normal case laws content search Selected Field -1"
              );
              let obj = {};
              this.normal_caseLaws_content_search(
                obj,
                searchWord,
                searchurl,
                "0"
              );
            } else {
              console.log(
                "this case is normal case laws content search Selected Field"
              );
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
                queryArr: [`${this.state.selectedField}`, `0`, `-1`],
              };
              this.normal_caseLaws_content_search(
                obj,
                searchWord,
                searchurl,
                "0"
              );
            }
          } else if (
            searchWord != "" &&
            this.state.paramSearchObj == null &&
            this.state.selectedJudge != ""
          ) {
            // this case is combine case laws judge & content search
            console.log(
              "this case is combine case laws judge & content search"
            );
            if (this.state.selectedField == "-1") {
              let obj = {};
              this.normal_judge_and_content_search(
                obj,
                searchWord,
                "Assets/Data5t/cl",
                "0"
              );
            } else {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
                queryArr: [`${this.state.selectedField}`, `0`, `-1`],
              };
              this.normal_judge_and_content_search(
                obj,
                searchWord,
                "Assets/Data5t/cl",
                "1"
              );
            }
          }
        } else if (this.state.selectedType == "3") {
          // Here selectedType = 3 , refers that user wants to search in Advance Ruling.
          if (
            searchWord == "" &&
            this.state.paramSearchObj == null &&
            this.state.selectedJudge != ""
          ) {
            // this case is ONLY advance ruling JUDGE SEARCH as CONTENT SEARCH
            console.log(
              "this case is advance ruling JUDGE SEARCH as CONTENT SEARCH"
            );

            if (this.state.selectedField == "-1") {
              let obj = {};
              this.normal_judge_search(obj, searchurl, "0");
            } else {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
                queryArr: [`${this.state.selectedField}`, `0`, `adv`],
              };
              this.normal_judge_search(obj, searchurl, "1");
            }
          } else if (searchWord == "" && this.state.paramSearchObj !== null) {
            // this case is advance ruling + parametric search
            let obj = {
              query: `[dbo].[Lawcrux_Web_Proc_ParamtericSearch_GetDataV2] @JudgeID='{0}', @PartyID='{1}', @Landmark='{2}', @Order='{3}', @Citation='{4}', @CitationValue='{5}', @FromDate='{6}', @ToDate='{7}', @Court='{8}', @Favour='{9}',@Field='{10}',@Subfield='{11}'`,
              queryArr: [
                `${this.state.paramSearchObj.judge}`,
                `${this.state.paramSearchObj.party}`,
                `${this.state.paramSearchObj.landmark}`,
                `${this.state.paramSearchObj.order}`,
                `${this.state.paramSearchObj.citation}`,
                `${this.state.paramSearchObj.citationcode}`,
                `${this.state.paramSearchObj.fromdate}`,
                `${this.state.paramSearchObj.todate}`,
                `${this.state.paramSearchObj.court}`,
                `${this.state.paramSearchObj.favour}`,
                `${this.state.selectedField}`,
                `${this.state.selectedSubField}`,
              ],
            };
            console.log("this case is advance ruling + parametric search");
            this.normal_caseLaws_param_search(obj, "Assets/Data5t/cl");
          } else if (searchWord != "" && this.state.paramSearchObj !== null) {
            // this case is advance ruling combine parametric search
            let obj = {
              query: `[dbo].[Lawcrux_Web_Proc_ParamtericSearch_GetDataV2] @JudgeID='{0}', @PartyID='{1}', @Landmark='{2}', @Order='{3}', @Citation='{4}', @CitationValue='{5}', @FromDate='{6}', @ToDate='{7}', @Court='{8}', @Favour='{9}',@Field='{10}',@Subfield='{11}'`,
              queryArr: [
                `${this.state.paramSearchObj.judge}`,
                `${this.state.paramSearchObj.party}`,
                `${this.state.paramSearchObj.landmark}`,
                `${this.state.paramSearchObj.order}`,
                `${this.state.paramSearchObj.citation}`,
                `${this.state.paramSearchObj.citationcode}`,
                `${this.state.paramSearchObj.fromdate}`,
                `${this.state.paramSearchObj.todate}`,
                `${this.state.paramSearchObj.court}`,
                `${this.state.paramSearchObj.favour}`,
                `${this.state.selectedField}`,
                `${this.state.selectedSubField}`,
              ],
            };
            console.log(
              "this case is advance ruling combine parametric search"
            );
            this.combine_caseLaws_param_search(
              obj,
              searchWord,
              "Assets/Data5t/cl"
            );
          } else if (
            searchWord != "" &&
            this.state.paramSearchObj == null &&
            this.state.selectedJudge == ""
          ) {
            // this case is normal advance ruling content search
            console.log("this case is normal advance ruling content search");
            if (this.state.selectedField == "-1") {
              let obj = {};
              this.normal_caseLaws_content_search(
                obj,
                searchWord,
                "Assets/Data5t/cl",
                "0"
              );
            } else {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
                queryArr: [`${this.state.selectedField}`, `0`, `adv`],
              };
              this.normal_caseLaws_content_search(
                obj,
                searchWord,
                "Assets/Data5t/cl",
                "0"
              );
            }
          } else if (
            searchWord != "" &&
            this.state.paramSearchObj == null &&
            this.state.selectedJudge != ""
          ) {
            // this case is combine advance ruling judge & content search
            console.log(
              "this case is combine advance ruling judge & content search"
            );
            if (this.state.selectedField == "-1") {
              let obj = {};
              this.normal_judge_and_content_search(
                obj,
                searchWord,
                "Assets/Data5t/cl",
                "0"
              );
            } else {
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
                queryArr: [`${this.state.selectedField}`, `0`, `adv`],
              };
              this.normal_judge_and_content_search(
                obj,
                searchWord,
                "Assets/Data5t/cl",
                "1"
              );
            }
          }
        } else if (this.state.selectedType == "4") {
          // Here selectedType = 4 , refers that user wants to search in both case laws & Advance Ruling
          // by selecting ALL from field.
          if (
            searchWord != "" &&
            this.state.paramSearchObj == null &&
            this.state.selectedJudge == ""
          ) {
            // this case is ALL+NORMAL
            let obj = {
              query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
              queryArr: [
                `${this.state.caseLawAdvObj.type}`,
                `0`,
                `${this.state.caseLawAdvObj.value}`,
              ],
            };
            console.log("this case is ALL+NORMAL");
            // this.normal_content_search(searchWord, searchurl)
            this.normal_all_search(obj, searchWord, searchurl);
          }
        }
      } else {
        console.warn(`========================================
                selectedType is undefined or null,Please Verify
                ===================================`);
      }
    }

    // New Code End

    // if (this.state.isRefinedSearch) {
    //     if (searchWord == "" && this.state.paramSearchObj == null && this.state.selectedJudge != "") {
    //         // this case is ONLY JUDGE SEARCH as CONTENT SEARCH in refined
    //         console.log("this case is JUDGE SEARCH as CONTENT SEARCH in refined")
    //         // this.normal_judge_search(searchurl)
    //         this.refined_judge_search(searchurl)
    //     } else if (searchWord == "" && this.state.paramSearchObj != null) {
    //         // this case is PARAMETRIC+REFINED
    //         console.log("this case is PARAMETRIC+REFINED")
    //         // this.normal_parametric_search()
    //         this.refined_parametric_search()
    //     } else if (searchWord != "" && this.state.paramSearchObj == null && this.state.selectedJudge != "") {
    //         // this case is CONTENT+JUDGE+REFINED
    //         console.log('this case is CONTENT+JUDGE+REFINED')
    //         this.refined_judge_and_content_search(searchWord, searchurl)
    //         // this.normal_content_search(searchWord, searchurl)
    //     } else if (searchWord != "" && this.state.paramSearchObj == null && this.state.selectedJudge == "") {
    //         // this case is CONTENT+REFINED
    //         console.log('this case is CONTENT+REFINED')
    //         // this.normal_content_search(searchWord, searchurl)
    //         this.refined_content_search(searchWord, searchurl)
    //     } else if (searchWord != "" && this.state.paramSearchObj != null) {
    //         // this case is COMBINE+REFINED
    //         console.log('this case is COMBINE+REFINED')
    //         // this.normal_combine_search(searchWord, searchurl)
    //         this.refined_combine_search(searchWord, searchurl)
    //     }

    // } else {
    //     if (this.state.circNotiObj == null) {
    //         if (this.state.caseLawAdvObj == null) {
    //             if (searchWord == "" && this.state.paramSearchObj == null && this.state.selectedJudge != "") {
    //                 // this case is ONLY JUDGE SEARCH as CONTENT SEARCH
    //                 console.log("this case is JUDGE SEARCH as CONTENT SEARCH")
    //                 this.normal_judge_search(searchurl)
    //             } else if (searchWord == "" && this.state.paramSearchObj != null) {
    //                 // this case is PARAMETRIC+NORMAL
    //                 console.log("this case is PARAMETRIC+NORMAL")
    //                 let obj = {
    //                     query: `[dbo].[Lawcrux_Web_Proc_ParamtericSearch_GetDataV2] @JudgeID='{0}', @PartyID='{1}', @Landmark='{2}', @Order='{3}', @Citation='{4}', @CitationValue='{5}', @FromDate='{6}', @ToDate='{7}', @Court='{8}', @Favour='{9}',@Field='{10}',@Subfield='{11}'`,
    //                     queryArr: [`${this.state.paramSearchObj.judge}`, `${this.state.paramSearchObj.party}`, `${this.state.paramSearchObj.landmark}`, `${this.state.paramSearchObj.order}`, `${this.state.paramSearchObj.citation}`, `${this.state.paramSearchObj.citationcode}`, `${this.state.paramSearchObj.fromdate}`, `${this.state.paramSearchObj.todate}`, `${this.state.paramSearchObj.court}`, `${this.state.paramSearchObj.favour}`, `${this.state.selectedField}`, `${this.state.selectedSubField}`]
    //                 }
    //                 this.normal_parametric_search(obj, 'Assets/Data5t/cl')
    //             } else if (searchWord != "" && this.state.paramSearchObj == null && this.state.selectedJudge != "") {
    //                 // this case is CONTENT+JUDGE+NORMAL
    //                 console.log('this case is CONTENT+JUDGE+NORMAL')
    //                 this.normal_judge_and_content_search(searchWord, searchurl)
    //                 // this.normal_content_search(searchWord, searchurl)
    //             } else if (searchWord != "" && this.state.paramSearchObj == null && this.state.selectedJudge == "") {
    //                 // this case is CONTENT+NORMAL
    //                 console.log('this case is CONTENT+NORMAL')
    //                 this.normal_content_search(searchWord, searchurl)
    //             } else if (searchWord != "" && this.state.paramSearchObj != null) {
    //                 // this case is COMBINE+NORMAL
    //                 console.log('this case is COMBINE+NORMAL')
    //                 let obj = {
    //                     query: `[dbo].[Lawcrux_Web_Proc_ParamtericSearch_GetDataV2] @JudgeID='{0}', @PartyID='{1}', @Landmark='{2}', @Order='{3}', @Citation='{4}', @CitationValue='{5}', @FromDate='{6}', @ToDate='{7}', @Court='{8}', @Favour='{9}',@Field='{10}',@Subfield='{11}'`,
    //                     queryArr: [`${this.state.paramSearchObj.judge}`, `${this.state.paramSearchObj.party}`, `${this.state.paramSearchObj.landmark}`, `${this.state.paramSearchObj.order}`, `${this.state.paramSearchObj.citation}`, `${this.state.paramSearchObj.citationcode}`, `${this.state.paramSearchObj.fromdate}`, `${this.state.paramSearchObj.todate}`, `${this.state.paramSearchObj.court}`, `${this.state.paramSearchObj.favour}`, `${this.state.selectedField}`, `${this.state.selectedSubField}`]
    //                 }
    //                 this.normal_combine_search(obj, searchWord, searchurl)
    //             }
    //         } else {
    //             let obj = {
    //                 query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
    //                 queryArr: [`${this.state.caseLawAdvObj.type}`, `0`, `${this.state.caseLawAdvObj.value}`]
    //             }
    //             this.normal_combine_search(obj, searchWord, searchurl, '')
    //             // this.normal_parametric_search(obj, 'Assets/Data5t/cl')
    //         }

    //     } else {
    //         if (this.state.caseLawAdvObj == null) {
    //             let mainfolder, subfolder;
    //             if (this.state.circNotiObj.field == 'Custom') {
    //                 mainfolder = 'cu'
    //                 if (this.state.circNotiObj.subfield == 'Notification') {
    //                     subfolder = 'cuncuv'
    //                 }
    //                 if (this.state.circNotiObj.subfield == 'Circular') {
    //                     subfolder = 'cuncuc'
    //                 }
    //             } else if (this.state.circNotiObj.field == 'Foreign Trade Policy') {
    //                 mainfolder = 'f'
    //                 if (this.state.circNotiObj.subfield == 'Notification') {
    //                     subfolder = 'ftnoti'
    //                 }
    //                 if (this.state.circNotiObj.subfield == 'Circular') {
    //                     subfolder = 'ftcirc'
    //                 }
    //             } else if (this.state.circNotiObj.field == 'Service Tax') {
    //                 mainfolder = 'st'
    //                 if (this.state.circNotiObj.subfield == 'Notification') {
    //                     subfolder = 'stncsv'
    //                 }
    //                 if (this.state.circNotiObj.subfield == 'Circular') {
    //                     subfolder = 'stncsc'
    //                 }
    //             } else if (this.state.circNotiObj.field == 'Excise') {
    //                 mainfolder = 'ce'
    //                 if (this.state.circNotiObj.subfield == 'Notification') {
    //                     subfolder = 'cencev'
    //                 }
    //                 if (this.state.circNotiObj.subfield == 'Circular') {
    //                     subfolder = 'cencec'
    //                 }
    //             } else if (this.state.circNotiObj.field == 'FEMA') {
    //                 mainfolder = 'rb'
    //                 if (this.state.circNotiObj.subfield == 'Notification') {
    //                     subfolder = 'rbnoti'
    //                 }
    //                 if (this.state.circNotiObj.subfield == 'Circular') {
    //                     subfolder = 'rbcirc'
    //                 }
    //             } else if (this.state.circNotiObj.field == 'SEZ') {
    //                 mainfolder = 'sz'
    //                 if (this.state.circNotiObj.subfield == 'Notification') {
    //                     subfolder = 'sznoti'
    //                 }
    //                 if (this.state.circNotiObj.subfield == 'Circular') {
    //                     subfolder = 'szcirc'
    //                 }
    //             } else if (this.state.circNotiObj.field == 'GST(Compensation to the States) Act' || this.state.circNotiObj.field == 'CGST' || this.state.circNotiObj.field == 'UGST' || this.state.circNotiObj.field == 'IGST') {
    //                 mainfolder = 'gt'
    //                 subfolder = 'gtnoti'
    //             }

    //             if (searchWord == "") {
    //                 // this case is Circular/Notification+Parametric+NORMAL
    //                 if (this.state.circNotiObj.type.toLowerCase() == 'circular') {
    //                     let obj = {
    //                         query: `[dbo].[Lawcrux_Web_Proc_CircularSearch_GetData] @UserID='{0}', @Number='{1}'`,
    //                         queryArr: [`${this.props.userid}`, `${this.state.circNotiObj.number}`]
    //                     }
    //                     this.normal_combine_search(obj, searchWord, `Assets/Data5t/${mainfolder}/${subfolder}`)
    //                     // this.normal_parametric_search(obj, `Assets/Data5t/${mainfolder}/${subfolder}`)
    //                 } else {
    //                     let obj = {
    //                         query: `[dbo].[Lawcrux_Web_Proc_NotificationSearch_GetData]
    //                         @UserID='{0}',
    //                         @Number='{1}',
    //                         @FromDate='{2}',
    //                         @ToDate='{3}'`,
    //                         queryArr: [`${this.props.userid}`, `${this.state.circNotiObj.number}`, `${this.state.circNotiObj.from}`, `${this.state.circNotiObj.to}`]
    //                     }
    //                     // this.normal_parametric_search(obj, `Assets/Data5t/${mainfolder}/${subfolder}`)
    //                     this.normal_combine_search(obj, searchWord, `Assets/Data5t/${mainfolder}/${subfolder}`)
    //                 }

    //                 console.log(this.state.circNotiObj)
    //                 console.log("this case is Circular/Notification+Parametric+NORMAL")
    //             } else {
    //                 // this case is Circular/Notification+Combine+NORMAL
    //                 if (this.state.circNotiObj.type.toLowerCase() == 'circular') {
    //                     let obj = {
    //                         query: `[dbo].[Lawcrux_Web_Proc_CircularSearch_GetData] @UserID='{0}', @Number='{1}'`,
    //                         queryArr: [`${this.props.userid}`, `${this.state.circNotiObj.number}`]
    //                     }
    //                     // this.normal_parametric_search(obj, `Assets/Data5t/${mainfolder}/${subfolder}`)
    //                     this.normal_combine_search(obj, searchWord, `Assets/Data5t/${mainfolder}/${subfolder}`)
    //                 } else {
    //                     let obj = {
    //                         query: `[dbo].[Lawcrux_Web_Proc_NotificationSearch_GetData]
    //                         @UserID='{0}',
    //                         @Number='{1}',
    //                         @FromDate='{2}',
    //                         @ToDate='{3}'`,
    //                         queryArr: [`${this.props.userid}`, `${this.state.circNotiObj.number}`, `${this.state.circNotiObj.from}`, `${this.state.circNotiObj.to}`]
    //                     }
    //                     // this.normal_parametric_search(obj, `Assets/Data5t/${mainfolder}/${subfolder}`)
    //                     this.normal_combine_search(obj, searchWord, `Assets/Data5t/${mainfolder}/${subfolder}`)
    //                 }
    //                 // this.normal_circNoti_param_search()
    //                 console.log(this.state.circNotiObj)
    //                 console.log("this case is Circular/Notification+Combine+NORMAL")
    //             }
    //         } else {
    //             if (searchWord == "") {

    //                 // let obj = {
    //                 //     query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
    //                 //     queryArr: [`${this.state.caseLawAdvObj.type}`, `0`, `${this.state.caseLawAdvObj.value}`]
    //                 // }
    //                 // this.normal_parametric_search(obj, 'Assets/Data5t/cl')

    //                 // console.log(this.state.caseLawAdvObj)
    //                 console.log("this case is Parametric+NORMAL")
    //             } else {
    //                 // this case is Circular/Notification+Combine+NORMAL
    //                 // if (this.state.circNotiObj.type.toLowerCase() == 'circular') {
    //                 //     let obj = {
    //                 //         query: `[dbo].[Lawcrux_Web_Proc_CircularSearch_GetData] @UserID='{0}', @Number='{1}'`,
    //                 //         queryArr: [`${this.props.userid}`, `${this.state.circNotiObj.number}`]
    //                 //     }
    //                 //     // this.normal_parametric_search(obj, `Assets/Data5t/${mainfolder}/${subfolder}`)
    //                 //     this.normal_combine_search(obj,this.state.search, `Assets/Data5t/${mainfolder}/${subfolder}`)
    //                 // } else {
    //                 //     let obj = {
    //                 //         query: `[dbo].[Lawcrux_Web_Proc_NotificationSearch_GetData]
    //                 //         @UserID='{0}',
    //                 //         @Number='{1}',
    //                 //         @FromDate='{2}',
    //                 //         @ToDate='{3}'`,
    //                 //         queryArr: [`${this.props.userid}`, `${this.state.circNotiObj.number}`, `${this.state.circNotiObj.from}`, `${this.state.circNotiObj.to}`]
    //                 //     }
    //                 //     // this.normal_parametric_search(obj, `Assets/Data5t/${mainfolder}/${subfolder}`)
    //                 //     this.normal_combine_search(obj,this.state.search, `Assets/Data5t/${mainfolder}/${subfolder}`)
    //                 // }
    //                 let obj = {
    //                     query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
    //                     queryArr: [`${this.state.caseLawAdvObj.type}`, `0`, `${this.state.caseLawAdvObj.value}`, '']
    //                 }
    //                 // this.normal_combine_search(obj, this.state.search, `Assets/Data5t/cl`)
    //                 this.normal_combine_search(obj, searchWord, `Assets/Data5t/cl`)

    //                 // this.normal_circNoti_param_search()
    //                 console.log(this.state.circNotiObj)
    //                 console.log("this case is Combine+NORMAL")
    //             }
    //         }

    //     }

    // }
  }
  storeSearch(e) {
    this.setState({ search: e.target.value });
  }
  handleKeyPress(e) {
    if (e.keyCode === 13) {
      this.searchKeyword(this.state.search, this.state.searchPath);
    }
  }

  setRightBoxContent(data, isLoad, nodeid) {
    this.setState({ isUpdation: true });
    console.log(nodeid);
    var obj1 = "";
    const codevalue = data.map((item) => {
      let value = item.split("/");
      let code = value[value.length - 1].split(".");
      // let codeno = code[0].toString()
      // obj1 += codeno + ","
      if (/[a-zA-Z]/.test(code[0].toString())) {
      } else {
        let codeno = code[0].toString();
        obj1 += codeno + ",";
      }
    });
    let no = obj1.slice(0, obj1.length - 1);
    this.state.allcodeno = no;
    console.log(this.state.allcodeno);

    var newPromise = new Promise((resolve, reject) => {
      if (isLoad) {
        this.setState({
          allFiles: [],
          isFileTitleFetched: false,
          isFileTitleFound: false,
        });
        this.setState({ isHistoryTrackBtn: false });
      }

      if (
        nodeid == "1.10" ||
        nodeid == "2.8" ||
        nodeid == "3.9" ||
        nodeid == "2.8" ||
        nodeid == "4.6" ||
        nodeid == "12.1" ||
        nodeid == "12" ||
        nodeid == "12.3" ||
        nodeid == "12.4" ||
        nodeid == "5.14"
      ) {
        // if (this.state.nodeid != nodeid) {
        console.log(nodeid);
        this.setState({ showHighlightttext: true });
        this.setState({ advorcl: true });
        this.setState({ filetype: "cl" });
        this.setState({ notificationtype: false });
        this.setState({ circular: false });
        this.setState({ prevnodeid: nodeid });
        // }
      } else if (
        nodeid == "1.11" ||
        nodeid == "2.10" ||
        nodeid == "13" ||
        nodeid == "13.2"
      ) {
        this.setState({ showHighlightttext: true });

        this.setState({ advorcl: true });
        this.setState({ filetype: "adv" });
        this.setState({ notificationtype: false });
        this.setState({ circular: false });
      } else if (nodeid == "2.3.2") {
        this.setState({ notificationfield: "custom NT" });
        this.setState({ showHighlightttext: true });

        this.setState({ advorcl: false });
        this.setState({ circular: false });
        this.setState({ notificationtype: true });
        this.setState({ foldpath: "cu" });
      } else if (
        nodeid == "2.3.1.1" ||
        nodeid == "2.3.1.2" ||
        nodeid == "2.3.1.3" ||
        nodeid == "2.3.1.4"
      ) {
        this.setState({ notificationfield: "custom tariff" });
        this.setState({ showHighlightttext: true });

        this.setState({ advorcl: false });
        this.setState({ circular: false });
        this.setState({ notificationtype: true });
        this.setState({ foldpath: "cu" });
      } else if (nodeid == "3.4.2") {
        this.setState({ showHighlightttext: true });

        this.setState({ notificationfield: "excise NT" });
        this.setState({ advorcl: false });
        this.setState({ circular: false });
        this.setState({ notificationtype: true });
        this.setState({ foldpath: "ce" });
      } else if (nodeid == "3.4.1") {
        this.setState({ showHighlightttext: true });

        this.setState({ notificationfield: "excise tariff" });
        this.setState({ advorcl: false });
        this.setState({ circular: false });
        this.setState({ notificationtype: true });
        this.setState({ foldpath: "ce" });
      } else if (nodeid == "4.4") {
        this.setState({ showHighlightttext: true });

        this.setState({ notificationfield: "service tax" });
        this.setState({ notificationtype: true });
        this.setState({ advorcl: false });
        this.setState({ circular: false });
        this.setState({ foldpath: "st" });
      } else if (nodeid == "2.3") {
        this.setState({ showHighlightttext: true });

        this.setState({ circular: true });
        this.setState({ circulartype: "customs" });
        this.setState({ notificationtype: false });
        this.setState({ advorcl: false });
        this.setState({ foldpath: "cu" });
      } else if (nodeid == "3.3") {
        this.setState({ showHighlightttext: true });

        this.setState({ circular: true });
        this.setState({ circulartype: "excise" });
        this.setState({ notificationtype: false });
        this.setState({ advorcl: false });
        this.setState({ foldpath: "ce" });
      } else if (nodeid == "4.3") {
        this.setState({ showHighlightttext: true });

        this.setState({ circular: true });
        this.setState({ circulartype: "service tax" });
        this.setState({ notificationtype: false });
        this.setState({ advorcl: false });
        this.setState({ foldpath: "st" });
      } else {
        this.setState({ circular: false });
        this.setState({ notificationtype: false });
        this.setState({ advorcl: false });
        const codevalue = data.map((item) => {
          let value = item.split("/");
          let code = value[value.length - 1].split(".");
          let codeno = code[0].toString();
          obj1 += codeno + ",";
        });
        // console.log(this.state.selectedFile,this.state.isFetchFileApiHitComplete)
        let no = obj1.slice(0, obj1.length - 1);
        this.state.allcodeno = no;
        console.log(this.state.allcodeno);
      }

      // else if (mainfile[2] == "gt" && mainfile[3] == "gtnoti") {
      //     this.setState({ advorcl: false })
      //     this.setState({ notificationtype: true })
      //     this.setState({ notificationfield: "custom NT" });
      //     this.setState({ foldpath: "cu" })

      // }
      // else if (mainfile[2] == "ce") {
      //     this.setState({ circulartype: true })
      // }

      let fdata = [];

      let obj = {
        allfiles: data.join("##12##"),
        url: sessionStorage.getItem("gettitleurl"),
      };
      Hexapi(obj)
        .then((resp) => {
          data.forEach((path, i) => {
            let obj = {
              path: path,
              title: resp[i]
                .toLowerCase()
                .replaceAll("(m/s)", "")
                .replaceAll("m/s", "")
                .replaceAll("<", " ")
                .replaceAll(">", " "),
            };
            fdata.push(obj);
          });
          // fdata.forEach((el, i) => el.title = resp[i])
          console.log(data, fdata, resp);
          resolve(fdata);
        })
        .catch((err) => reject(err));
    });
    newPromise
      .then((fileData) => {
        if (fileData.length > 0) {
          // d.push(fileData)
          // isFetchFileApiHitComplete
          console.log(
            this.state.isFileTitleFetched,
            this.state.isFileTitleFound
          );
          if (fileData.length < this.state.titlesLength) {
            this.setState({ isLastList: true });
          } else {
            this.setState({ isLastList: false, titlesLength: fileData.length });
          }
          let mainBox = document.getElementById("mainBoxContent");
          // let rightBox = document.getElementById("rightBox")
          this.setState(
            {
              allStarts: "",
              allEnds: "",
              isShowReadWith: false,
              isFileTitleFound: true,
              htmlFileContent: "",
              allFiles: this.state.allFiles.concat(fileData),
              isShowRightBox: true,
              isFileTitleFetched: true,
            },
            () => {
              mainBox.style.minWidth = "calc(100% - 300px)";
            }
          );
        } else {
          if (fileData.length < this.state.titlesLength) {
            this.setState({ isLastList: true, isFileTitleFound: true });
          } else {
            this.setState({ isLastList: false, isFileTitleFound: false });
          }
        }
      })
      .catch((err) => console.error(err));
    console.log(this.state.selectedFile);
  }

  getLeafCount(data) {
    let count = 0;
    // console.log(data)
    function getfiles(d) {
      // console.log(d)
      if (d.length > 0) {
        if (
          d.some((value) => {
            return typeof value == "object";
          })
        ) {
          d.forEach((el) => {
            // console.log(el)
            if (el.children.length > 0) {
              getfiles(el.children);
            }
            // else {
            //     count++
            // }
          });
        } else {
          count += d.length;
        }
      }
    }
    getfiles(data.children);
    return count;
  }
  setLeftBoxTree(nodes) {
    return nodes.map((node, index) => {
      let totalFound = this.getLeafCount(node);
      return (
        <TreeItem
          key={node.nodeid}
          nodeId={node.nodeid}
          label={node.name + ":" + totalFound}
          onClick={() => {
            this.state.isFileTitleFetched &&
              node.children.some((value) => {
                return typeof value !== "object";
              }) &&
              this.setState(
                { listOfAllFilePaths: node.children, isUpdation: false },
                () => {
                  if (
                    node.nodeid == "1.10" ||
                    node.nodeid == "2.8" ||
                    node.nodeid == "3.9" ||
                    node.nodeid == "2.8" ||
                    node.nodeid == "4.6" ||
                    node.nodeid == "12.1" ||
                    node.nodeid == "12" ||
                    node.nodeid == "12.3" ||
                    node.nodeid == "12.4" ||
                    node.nodeid == "1.11" ||
                    node.nodeid == "2.10" ||
                    node.nodeid == "13" ||
                    node.nodeid == "13.2" ||
                    node.nodeid == "2.3.2" ||
                    node.nodeid == "2.3.1.1" ||
                    node.nodeid == "2.3.1.2" ||
                    node.nodeid == "2.3.1.3" ||
                    node.nodeid == "2.3.1.4" ||
                    node.nodeid == "3.4.2" ||
                    node.nodeid == "3.4.1" ||
                    node.nodeid == "4.4" ||
                    node.nodeid == "2.3" ||
                    node.nodeid == "3.3" ||
                    node.nodeid == "4.3"
                  ) {
                    this.setRightBoxContent(
                      this.state.listOfAllFilePaths,
                      1,
                      node.nodeid
                    );
                  } else {
                    this.setRightBoxContent(
                      this.state.listOfAllFilePaths.filter((el, i) => i < 100),
                      1,
                      node.nodeid
                    );
                  }
                  console.log(node);
                }
              );
          }}
        >
          {node.children.length > 0 &&
          node.children.some((value) => {
            return typeof value == "object";
          })
            ? this.setLeftBoxTree(node.children)
            : null}
        </TreeItem>
      );
    });

    // if (data.type == 'folder') {
    //     return data.children.map((child, index) => {
    //         if (child.type == 'folder') {
    //             let totalFound = this.getLeafCount(child)
    //             return (
    //                 <TreeItem key={child.name + index} nodeId={`${"childFolder" + child.name}`} label={rename(child.name) + ' - ' + totalFound} onClick={() => {
    //                     // child.children.some((el) => el.children == undefined) ? this.setRightBoxContent(child.children) : this.setState({ allFiles: [] })
    //                 }}>
    //                     {this.setLeftBoxTree(child)}
    //                 </TreeItem>
    //             )
    //         }
    //     })
    // }
  }
  onIframeLoad() {
    console.log("============OriginalIframe");
    this.markInstance = new Mark(document.getElementById("originalIframe"));
    this.markInstance.unmark({
      iframes: true,
      done: () => {
        this.markInstance.mark(this.state.search, {
          accuracy:
            this.state.search.includes(" ") && this.state.searchType == "2"
              ? "partially"
              : "exactly",
          separateWordSearch: this.state.searchType == 2 ? false : true,
          caseSensitive: false,
          iframes: true,
        });
      },
    });
  }
  autoHighlight() {
    let scope = this;
    const restoreSelection = function (containerEl, savedSel) {
      var charIndex = 0,
        range = document.createRange();
      range.setStart(containerEl, 0);
      range.collapse(true);
      var nodeStack = [containerEl],
        node,
        foundStart = false,
        stop = false;

      while (!stop && (node = nodeStack.pop())) {
        if (node.nodeType == 3) {
          var nextCharIndex = charIndex + node.length;
          if (
            !foundStart &&
            savedSel.start >= charIndex &&
            savedSel.start <= nextCharIndex
          ) {
            range.setStart(node, savedSel.start - charIndex);
            foundStart = true;
          }
          if (
            foundStart &&
            savedSel.end >= charIndex &&
            savedSel.end <= nextCharIndex
          ) {
            range.setEnd(node, savedSel.end - charIndex);
            stop = true;
          }
          charIndex = nextCharIndex;
        } else {
          var i = node.childNodes.length;
          while (i--) {
            nodeStack.push(node.childNodes[i]);
          }
        }
      }

      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      document.designMode = "on";
      if (
        !document.execCommand("HiliteColor", false, scope.state.highlightColor)
      ) {
        document.execCommand("BackColor", false, scope.state.highlightColor);
      }
      document.designMode = "off";
      if (scope.state.isDarkMode) {
        document.getElementById("customIframe").style.backgroundColor = "black";
      }
      // console.log(range)
    };

    // if (this.state.savedSelection != null) {
    //     restoreSelection(document.getElementById("customIframe"), this.state.savedSelection);
    // }

    let obj = {
      query: `[dbo].[Lawcrux_Web_Proc_Highlight_GetValue] @UserID='{0}', @filepath='{1}'`,
      queryArr: [
        `${this.state.selectedFileDetails.userid}`,
        `${this.state.selectedFileDetails.filepath}`,
      ],
    };

    Hexapi(obj)
      .then((resp) => {
        console.log(resp);
        if (resp.highlight != undefined && resp.highlight.length > 0) {
          let start = resp.highlight[0].start;
          let end = resp.highlight[0].end;
          this.setState({ allStarts: start, allEnds: end });
          let newStart = [],
            newEnd = [];
          if (start.toString().indexOf("##12##") > -1) {
            newStart = start.split("##12##");
          } else {
            newStart.push(start);
          }
          if (end.toString().indexOf("##12##") > -1) {
            newEnd = end.split("##12##");
          } else {
            newEnd.push(end);
          }
          let arr = [];
          for (let i = 0; i < newStart.length; i++) {
            let obj = {
              start: parseInt(newStart[i]),
              end: parseInt(newEnd[i]),
            };
            arr.push(obj);
            console.log(obj);
            restoreSelection(document.getElementById("customIframe"), obj);
          }
          this.setState({ startEndArray: arr });
          console.log(newStart, newEnd, this.state.startEndArray);
        }
      })
      .then((re) => {
        console.log(this.state.search, this.state.isOpenInIframe);
        // Only highlighing searched word
        this.markInstance = new Mark(document.getElementById("customIframe"));
        this.markInstance.unmark({
          done: () => {
            this.markInstance.mark(this.state.search, {
              accuracy:
                this.state.search.includes(" ") && this.state.searchType == "2"
                  ? "partially"
                  : "exactly",
              separateWordSearch: this.state.searchType == 2 ? false : true,
              caseSensitive: false,
            });
          },
        });
      })
      .then(() => {
        let scope = this;
        let filepath = this.state.selectedFileDetails.filepath;
        let mainfolder = filepath.split("/")[2] + "/";
        let subfolder = filepath.split("/")[3] + "/";
        console.table(mainfolder, subfolder);
        !this.state.isHistoryTrackBtn && this.trackHistory(filepath);

        // ========================================================================================================
        // This code used to replace the anchor tag href attribute url
        // And also open then in new tab
        $("#customIframe")
          .find("a")
          .each(function () {
            let href = $(this).attr("href");
            let isJsFun = false,
              isSamePageLink = false;
            if (
              href != undefined &&
              href.toString().toLowerCase().includes("javascript:void(0)")
            ) {
              isJsFun = true;
            }
            if (href != undefined && href.includes("#")) {
              isSamePageLink = true;
            }
            let file_n = href;
            // console.log(isJsFun)
            if (href != undefined && !isJsFun) {
              $(this).mousedown(function (e) {
                if (e.button == 2) {
                  let r_href = href;
                  if (r_href.includes("../../")) {
                    r_href = r_href.replace("../../", "");
                  } else if (r_href.includes("../")) {
                    r_href = r_href.replace("../", mainfolder);
                    subfolder =
                      r_href
                        .split(mainfolder)[1]
                        .substring(
                          0,
                          r_href.split(mainfolder)[1].indexOf("/")
                        ) + "/";
                  } else {
                    r_href = mainfolder + subfolder + r_href;
                  }
                  let temp_url = "Assets/data5t/" + r_href;
                  console.log(mainfolder, subfolder, temp_url);
                  const isPPT = [".pptx", ".ppt"].some((el) =>
                    temp_url.toLowerCase().includes(el)
                  );
                  if (!isPPT) {
                    $(this).attr(
                      "href",
                      sessionStorage.getItem("url") + temp_url
                    );
                  }
                }
              });
              $(this).on("click", () => {
                let l_href = href;
                if (l_href.includes("../../")) {
                  l_href = l_href.replace("../../", "");
                } else if (l_href.includes("../")) {
                  l_href = l_href.replace("../", mainfolder);
                  subfolder =
                    l_href
                      .split(mainfolder)[1]
                      .substring(0, l_href.split(mainfolder)[1].indexOf("/")) +
                    "/";
                } else {
                  l_href = mainfolder + subfolder + l_href;
                }
                let temp_url = "Assets/data5t/" + l_href;
                console.log(mainfolder, subfolder, temp_url);
                const isPPT = [".pptx", ".ppt"].some((el) =>
                  temp_url.toLowerCase().includes(el)
                );
                // $(this).attr('href', '#')
                if (!isSamePageLink) {
                  $(this).attr("href", "#");
                }
                if (!temp_url.includes("#")) {
                  if (isPPT) {
                    console.log(temp_url);
                    // scope.setState({ isOpenSummaryModal: true, summaryData: { foldername: mainfolder + subfolder, filename: file_n } })
                  } else {
                    // scope.setState({ filepath: temp_url, allEnds: "0", allStarts: '0', startEndArray: [] }, () => {
                    //     anchorTags(temp_url)
                    // })
                    const hist = () => {
                      scope.history = scope.history.filter(
                        (el, i) => i <= scope.currentPagePosition - 1
                      );
                      console.log(scope.history);
                    };

                    scope.state.isHistoryTrackBtn && hist();

                    scope.setState({ isHistoryTrackBtn: false });
                    const obj = {
                      filepath: temp_url,
                      description: scope.state.selectedFileDetails.description,
                      userid: scope.state.selectedFileDetails.userid,
                    };
                    console.log(obj);
                    scope.createIframe(obj);
                  }
                }
              });
            }
          });
        // ========================================================================================================

        // ========================================================================================================
        // This code used to correct the object tag data attribute url
        // In frames
        // $('#customIframe').find('object').each(function () {
        //     let href = $(this).attr('data')
        //     let r_href = href
        //     if (r_href.includes(sessionStorage.getItem('url'))) {
        //         $(this).attr('data', r_href)
        //     } else {
        //         if (r_href.includes('../../')) {
        //             r_href = r_href.replace('../../', '')
        //         } else if (r_href.includes('../')) {
        //             r_href = r_href.replace('../', mainfolder)
        //             subfolder = r_href.split(mainfolder)[1].substring(0, r_href.split(mainfolder)[1].indexOf('/')) + '/'
        //         } else {
        //             r_href = mainfolder + subfolder + r_href
        //         }
        //         let temp_url = 'Assets/data5t/' + r_href
        //         $(this).attr('data', sessionStorage.getItem('url') + temp_url)
        //     }
        // });
        // ========================================================================================================
      });
  }
  getSelectedText() {
    if (this.state.selectedFile != "") {
      let scope = this;

      function makeEditableAndHighlight(colour) {
        var range,
          sel = window.getSelection();
        if (sel.rangeCount && sel.getRangeAt) {
          range = sel.getRangeAt(0);
        }

        document.designMode = "on";
        if (range) {
          sel.removeAllRanges();
          sel.addRange(range);
        }
        // Use HiliteColor since some browsers apply BackColor to the whole block
        if (!document.execCommand("HiliteColor", false, colour)) {
          console.log("1");
          document.execCommand("BackColor", false, colour);
        }
        document.designMode = "off";
        if (scope.state.isDarkMode) {
          document.getElementById("customIframe").style.background = "black";
        }
      }
      // function makeEditableAndHighlight(colour) {
      //     var range, sel = window.getSelection();
      //     if (sel.rangeCount && sel.getRangeAt) {
      //         range = sel.getRangeAt(0);
      //     }

      //     document.designMode = "on";
      //     if (range) {
      //         sel.removeAllRanges();
      //         sel.addRange(range);
      //     }
      //     // Use HiliteColor since some browsers apply BackColor to the whole block
      //     if (!document.execCommand("HiliteColor", false, colour)) {
      //         document.execCommand("BackColor", false, colour);
      //     }
      //     document.designMode = "off";
      //     if (scope.state.isDarkMode) {
      //         document.getElementById('customIframe').style.background = 'black'
      //     } else {
      //         document.getElementById('customIframe').style.background = 'transparent'
      //     }
      // }
      function highlight(colour) {
        var range;
        console.log("hightlight call");
        if (window.getSelection) {
          // IE9 and non-IE
          try {
            if (!document.execCommand("BackColor", false, colour)) {
              console.log("backColor working");
              makeEditableAndHighlight(colour);
            }
          } catch (ex) {
            console.log("backColor not working");
            makeEditableAndHighlight(colour);
          }
        } else if (document.selection && document.selection.createRange) {
          // IE <= 8 case
          console.log("window getselectiong not working");
          range = document.selection.createRange();
          range.execCommand("BackColor", false, colour);
        }
      }
      // function highlight(colour) {
      //     var range;
      //     if (window.getSelection) {
      //         // IE9 and non-IE
      //         try {
      //             if (!document.execCommand("BackColor", false, colour)) {
      //                 makeEditableAndHighlight(colour);
      //             }
      //         } catch (ex) {
      //             makeEditableAndHighlight(colour)
      //         }
      //     } else if (document.selection && document.selection.createRange) {
      //         // IE <= 8 case
      //         range = document.selection.createRange();
      //         range.execCommand("BackColor", false, colour);
      //     }
      // }
      const saveSelection = function (containerEl) {
        if (window.getSelection().getRangeAt(0) != undefined) {
          var range = window.getSelection().getRangeAt(0);
          var preSelectionRange = range.cloneRange();
          preSelectionRange.selectNodeContents(containerEl);
          preSelectionRange.setEnd(range.startContainer, range.startOffset);
          var start = preSelectionRange.toString().length;

          return {
            start: start,
            end: start + range.toString().length,
          };
        } else {
          alert("Select text first");
        }
      };

      // const saveSelection = function (containerEl) {
      //     var range = window.getSelection().getRangeAt(0);
      //     var preSelectionRange = range.cloneRange();
      //     preSelectionRange.selectNodeContents(containerEl);
      //     preSelectionRange.setEnd(range.startContainer, range.startOffset);
      //     var start = preSelectionRange.toString().length;

      //     return {
      //         start: start,
      //         end: start + range.toString().length
      //     }
      // };

      let selectedIndexObj = saveSelection(
        document.getElementById("customIframe")
      );
      if (this.state.allStarts != "" && this.state.allEnds != "") {
        let newStarts = "";
        let newEnds = "";
        this.setState(
          { startEndArray: this.state.startEndArray.concat(selectedIndexObj) },
          () => {
            let updatedObj = this.correctHighlight(this.state.startEndArray);
            console.log(updatedObj);
            let start = [],
              end = [];
            for (let i = 0; i < updatedObj.length; i++) {
              start.push(updatedObj[i].start);
              end.push(updatedObj[i].end);
            }

            newStarts = start.join("##12##");
            newEnds = end.join("##12##");

            // Updating file highlights
            this.setState({ allStarts: newStarts, allEnds: newEnds }, () => {
              console.log(this.state.allStarts, this.state.allEnds);
              highlight(scope.state.highlightColor);
              let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_UpdateHighlight_Submit] @UserID='{0}', @Filepath='{1}', @Start='{2}', @End='{3}'`,
                queryArr: [
                  `${this.state.selectedFileDetails.userid}`,
                  `${this.state.selectedFileDetails.filepath}`,
                  `${newStarts}`,
                  `${newEnds}`,
                ],
              };

              Hexapi(obj).then((resp) => {
                console.log(resp);
              });
            });
          }
        );
      } else {
        //Inserting very first highlight
        this.setState(
          { allStarts: selectedIndexObj.start, allEnds: selectedIndexObj.end },
          () => {
            highlight(scope.state.highlightColor);
            let obj = {
              query: `[dbo].[Lawcrux_Web_Proc_AddHighlighter_Submit] @UserID='{0}', @Filepath='{1}', @Start='{2}', @End='{3}'`,
              queryArr: [
                `${this.state.selectedFileDetails.userid}`,
                `${this.state.selectedFileDetails.filepath}`,
                `${selectedIndexObj.start}`,
                `${selectedIndexObj.end}`,
              ],
            };

            Hexapi(obj).then((resp) => {
              console.log(resp);
            });
          }
        );
      }
    }
  }
  removeSelectedText() {
    if (this.state.selectedFile != "") {
      let scope = this;
      function makeEditableAndHighlight(colour) {
        var range,
          sel = window.getSelection();
        if (sel.rangeCount && sel.getRangeAt) {
          range = sel.getRangeAt(0);
        }
        // console.log(range)
        document.designMode = "on";
        if (range) {
          sel.removeAllRanges();
          sel.addRange(range);
        }
        // Use HiliteColor since some browsers apply BackColor to the whole block
        if (!document.execCommand("HiliteColor", false, colour)) {
          document.execCommand("BackColor", false, colour);
        }
        document.designMode = "off";
        if (scope.state.isDarkMode) {
          document.getElementById("customIframe").style.backgroundColor =
            "black";
        }
      }

      // function makeEditableAndHighlight(colour) {
      //     var range, sel = window.getSelection();
      //     if (sel.rangeCount && sel.getRangeAt) {
      //         range = sel.getRangeAt(0);
      //     }
      //     // console.log(range)
      //     document.designMode = "on";
      //     if (range) {
      //         sel.removeAllRanges();
      //         sel.addRange(range);
      //     }
      //     // Use HiliteColor since some browsers apply BackColor to the whole block
      //     if (!document.execCommand("HiliteColor", false, colour)) {
      //         document.execCommand("BackColor", false, colour);
      //     }

      //     document.designMode = "off";
      // }

      function highlight(colour) {
        var range;
        if (window.getSelection) {
          // IE9 and non-IE
          try {
            if (!document.execCommand("BackColor", false, colour)) {
              makeEditableAndHighlight(colour);
            }
          } catch (ex) {
            makeEditableAndHighlight(colour);
          }
        } else if (document.selection && document.selection.createRange) {
          // IE <= 8 case
          range = document.selection.createRange();
          range.execCommand("BackColor", false, colour);
        }
      }

      const saveSelection = function (containerEl) {
        var range = window.getSelection().getRangeAt(0);
        var preSelectionRange = range.cloneRange();
        preSelectionRange.selectNodeContents(containerEl);
        preSelectionRange.setEnd(range.startContainer, range.startOffset);
        var start = preSelectionRange.toString().length;

        return {
          start: start,
          end: start + range.toString().length,
        };
      };

      let removeCoordinates = saveSelection(
        document.getElementById("customIframe")
      );
      if (this.state.allStarts != "" && this.state.allEnds != "") {
        this.setState(
          { startEndArray: this.state.startEndArray.concat(removeCoordinates) },
          () => {
            console.log(removeCoordinates);
            let rStart = removeCoordinates.start,
              rEnd = removeCoordinates.end;
            let Arr = this.correctHighlight(this.state.startEndArray);
            let newArr = [],
              newStarts = "",
              newEnds = "",
              scope = this;
            for (let i = 0; i < Arr.length; i++) {
              if (Arr[i].start != rStart && Arr[i].end != rEnd) {
                if (
                  rStart < Arr[i].start &&
                  rEnd > Arr[i].start &&
                  rEnd < Arr[i].end
                ) {
                  newArr.push({
                    start: min(rEnd, Arr[i].end),
                    end: max(rEnd, Arr[i].end),
                  });
                } else if (
                  rStart > Arr[i].start &&
                  rStart < Arr[i].end &&
                  rEnd > Arr[i].start &&
                  rEnd < Arr[i].end
                ) {
                  newArr.push({
                    start: min(rStart, Arr[i].start),
                    end: max(rStart, Arr[i].start),
                  });
                  newArr.push({
                    start: min(rEnd, Arr[i].end),
                    end: max(rEnd, Arr[i].end),
                  });
                } else if (
                  rStart > Arr[i].start &&
                  rStart < Arr[i].end &&
                  rEnd > Arr[i].end
                ) {
                  newArr.push({
                    start: min(rStart, Arr[i].start),
                    end: max(rStart, Arr[i].start),
                  });
                } else {
                  newArr.push(Arr[i]);
                }
              }
            }
            console.log(newArr);

            let start = [],
              end = [];
            for (let i = 0; i < newArr.length; i++) {
              start.push(newArr[i].start);
              end.push(newArr[i].end);
            }

            newStarts = start.join("##12##");
            newEnds = end.join("##12##");

            // Updating file highlights
            this.setState(
              { allStarts: newStarts, allEnds: newEnds, startEndArray: newArr },
              () => {
                console.log(this.state.allStarts, this.state.allEnds);
                highlight("transparent");
                let obj = {
                  query: `[dbo].[Lawcrux_Web_Proc_UpdateHighlight_Submit] @UserID='{0}', @Filepath='{1}', @Start='{2}', @End='{3}'`,
                  queryArr: [
                    `${this.state.selectedFileDetails.userid}`,
                    `${this.state.selectedFileDetails.filepath}`,
                    `${newStarts}`,
                    `${newEnds}`,
                  ],
                };

                Hexapi(obj).then((resp) => {
                  console.log(resp);
                });
              }
            );
          }
        );
      }
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
    console.log(this.props);
    if (this.props.isReferedByHsn) {
      this.setState({ search: this.props.hsnSearchWord }, () => {
        this.searchKeyword(this.props.hsnSearchWord, this.props.hsnPath);
      });
    } else if (this.props.isReferedByHistory) {
      this.openHistoryFile(this.props.historyFileData);
      console.log(this.props.historyFileData);
      // this.setState({ search: this.props.hsnSearchWord }, () => {
      //     this.searchKeyword(this.props.hsnSearchWord, this.props.hsnPath)
      // })
    } else if (this.props.isReferedByCaseStatus) {
      console.log(this.props.caseStatusData.filepath);
      this.openHistoryFile(this.props.caseStatusData);
    } else if (this.props.isReferedByCaseLaws) {
      if (this.props.isCourt) {
        // console.log(this.props.path)
        this.normal_folder_search(this.props.path);
        // this.normal_parametric_search()
      } else {
        let obj = {
          query: `[dbo].[Lawcrux_Web_Proc_CaseLaw_GetData] @Field='{0}', @Landmark='{1}', @Subfield='{2}'`,
          queryArr: [`${this.props.field}`, `${this.props.isLandMark}`, `-1`],
        };
        // this.normal_combine_search(obj, '', 'Assets/Data5t/cl', '')
        this.normal_home_case_laws_search(
          obj,
          "Assets/Data5t/cl",
          this.props.field
        );
        console.log(this.props);
      }
    } else {
      this.setState({
        search: "",
        searchType: "2",
        openInitialModal: true,
      });
    }
    console.log(this.props);
  }

  xWithRef(text, mode) {
    if (mode === 1) {
      let newText = text.replaceAll("�", ' " ');
      const removeHelldodv2 = (string, removeWord) => {
        let updatedString = string;
        while (
          updatedString.indexOf(`${removeWord}`) > -1 &&
          updatedString.indexOf(
            "helldod]",
            updatedString.indexOf(`${removeWord}`)
          )
        ) {
          let endIndex = updatedString.indexOf(
            "helldod]",
            updatedString.indexOf(`${removeWord}`)
          );
          let openAfterOprn = updatedString.indexOf(
            `${removeWord}`,
            updatedString.indexOf(`${removeWord}`) + 8
          );
          while (true) {
            if (endIndex > openAfterOprn && openAfterOprn > -1) {
              endIndex = updatedString.indexOf("helldod]", endIndex + 8);
              openAfterOprn = updatedString.indexOf(
                `${removeWord}`,
                openAfterOprn + 8
              );
              //console.log(`OpenAfterOpen = ${openAfterOprn} || endIndex = ${endIndex}`)
            } else {
              break;
            }
          }
          if (endIndex == -1) {
            console.log("null found");
            endIndex = updatedString.indexOf(
              "helldod]",
              updatedString.indexOf(`${removeWord}`)
            );
          }
          //console.log(endIndex)
          let helldodPart = updatedString.substring(
            updatedString.indexOf(`${removeWord}`),
            endIndex + 8
          );
          console.log(helldodPart);
          updatedString = updatedString.replace(helldodPart, "");
        }
        //updatedString = updatedString.replace(`[helldod<SUP>old</SUP></FONT></SUP></B><font face="Arial" size="2">[Articles of apparel and clothing accessories, knitted or crocheted, of sale value not exceeding Rs. 1000 per piece]<B><SUP>helldod]`,'shubhamsharma')

        return updatedString;
      };

      newText = removeHelldodv2(newText, "[helldod");

      this.setState({
        htmlFileShowContent: newText,
        htmlFileShowContentBackup: newText,
      });
    } else {
      this.setState({
        htmlFileShowContent: this.state.htmlFileShowContentBackup,
      });
    }
  }

  xWithoutRef(text) {
    let newText = text
      .replaceAll("�", '"')
      .replaceAll("[helldod old", "")
      .replaceAll("[helldod", "")
      .replaceAll("helldod]", "")
      .replaceAll("<sup>old</sup>", "");
    this.setState({
      htmlFileShowContent: newText,
    });
  }

  storeHistory(filedata) {
    console.log(filedata);
    this.setState({ isOpenCustomFrame: true });
    this.setState({ showHighlightttext: true });
    this.setState({ targetFile: filedata.filepath });
    let mainfile = filedata.filepath.split("/");
    this.setState({ mainFolder: mainfile[2] });
    this.setState({ subFolder: mainfile[3] });
    console.log(filedata.filepath);
    let filepath = "";
    if (filedata.filepath.includes("Web\\")) {
      console.log(filedata.filepath);
      filepath = filedata.filepath.split("Web\\")[1];
      console.log(filepath);
    } else {
      filepath = filedata.filepath;
    }
    let obj = {
      query: `[dbo].[Lawcrux_Web_Proc_HistoryLog_Submit] @UserID='{0}', @filepath='{1}', @Description='{2}'`,
      queryArr: [`${filedata.userid}`, `${filepath}`, `${filedata.title}`],
    };
    Hexapi(obj)
      .then((resp) => {
        console.log(resp);
      })
      .then(() => {
        this.createIframe(filedata);
      });
  }

  getpagedata = (file) =>
    new Promise((resolve, reject) => {
      let filepath = "";
      if (file.filepath.includes("Web\\")) {
        console.log(file.filepath);
        filepath = file.filepath.split("Web\\")[1];
        console.log(filepath);
      } else {
        filepath = file.filepath;
      }
      let userid = sessionStorage.getItem("userid");
      let obj = {
        query: `[dbo].[Lawcrux_Web_Proc_Page_GetDataV2] @UserID='{0}', @filepath='{1}'`,
        queryArr: [`${userid}`, `${filepath}`],
      };
      // let obj = {
      //     query: `[dbo].[Lawcrux_Web_Proc_Page_GetData] @UserID='{0}', @filepath='{1}'`,
      //     queryArr: [`${file.userid}`, `${file.filepath}`]
      // }
      Hexapi(obj)
        .then((resp) => {
          console.log(resp);
          this.setState({ isBookmarkAddComplete: false });
          if (resp[""][0].bookmark == 1) {
            this.setState({ isBookmarkAdded: true });
          } else {
            this.setState({ isBookmarkAdded: false });
          }
          if (resp["note"][0].command == 1) {
            this.setState({ usernotes: resp.note[0].note });
          } else {
            this.setState({ usernotes: "" });
          }
          if (resp["summary"] != undefined) {
            this.setState({ summaryData: resp.summary[0] });
          } else {
            this.setState({ summaryData: null });
          }
          this.setState({ pageData: resp });
        })
        .then(() => this.setState({ isBookmarkAddComplete: true }))
        .then(() => resolve())
        .catch((err) => reject(err));
    });

  createIframe(fileData) {
    let filepath = fileData.filepath;
    console.log(fileData);
    this.setState({
      isOpenInIframe: false,
      allStarts: "",
      allEnds: "",
      isShowReadWith: false,
      selectedFileDetails: fileData,
      selectedFile: filepath,
      isFetchFileApiHitComplete: false,
      htmlFileContent: "",
    });
    // let url = `${sessionStorage.getItem('Apipathurl')}fetchfile.php`; //this.props.rootUrl+filepath;
    let url = sessionStorage.getItem("url") + "fetchfile.php";

    var fd = new FormData();
    fd.append(Aesencryption("filename"), Aesencryption(filepath));
    axios({
      mode: "cors",
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      url: `${url}`,
      data: fd,
    })
      .then((resp) => {
        let respData = resp.data;
        // if (respData.toLowerCase().includes('<object')) {
        //     respData = respData.replaceAll(`data="../../`, `data="${sessionStorage.getItem('url')}Assets/Data5t/`)
        // }

        let pageTitle = respData.substring(
          respData.indexOf("<title>") + 7,
          respData.indexOf("</title>")
        );
        let headContent = respData
          .substring(
            respData.toLowerCase().indexOf("<head>") + 6,
            respData.toLowerCase().indexOf("</head>")
          )
          .replace("<title>" + pageTitle + "</title>", "");

        console.log(headContent, headContent.toLowerCase().includes("<script"));
        let isIframe = false;
        // if (headContent.toLowerCase().includes('<script') || headContent.toLowerCase().includes('<link') || headContent.toLowerCase().includes('<style>')) {
        // if (headContent.toLowerCase().includes('<script') || respData.toLowerCase().includes('<img')) {
        //     this.setState({ isOpenInIframe: true })
        //     isIframe = true
        // }
        // const parser = new DOMParser();
        // var newNode = parser.parseFromString( headContent,"text/html")
        // document.head.appendChild(newNode)
        // let r_href = href
        // if (r_href.includes('../../')) {
        //     r_href = r_href.replace('../../', '')
        // } else if (r_href.includes('../')) {
        //     r_href = r_href.replace('../', mainfolder)
        //     subfolder = r_href.split(mainfolder)[1].substring(0, r_href.split(mainfolder)[1].indexOf('/')) + '/'
        // } else {
        //     r_href = mainfolder + subfolder + r_href
        // }
        // function checkIfIncluded(file) {
        //     var links = document.getElementsByTagName("link");
        //     for (var i = 0; i < links.length; i++) {
        //         console.log(links[i].href,file)
        //         if (links[i].href.toLowerCase().includes(file.toLowerCase()))
        //             return true;
        //     }
        //     // var scripts = document.getElementsByTagName("script");
        //     // for(var i = 0; i < scripts.length; i++) {
        //     //     if (scripts[i].src.substr(-file.length) == file)
        //     //         return true;
        //     // }

        //     return false;
        // }
        // let href = headContent.toLowerCase().includes('<link') && headContent.substring(headContent.indexOf('href=') + 6, headContent.indexOf('.css"', headContent.indexOf('href=')))
        // if (href) {
        //     if (!checkIfIncluded(href.split('/')[href.split('/').length-1]+'.css')) {
        //         let newHeadContent = headContent.replace(href, 'https://hexbss.xyz/lawcrux/lawcrux/web/Assets/Data5t/pi/gCL')
        //         document.head.insertAdjacentHTML('beforeend', newHeadContent);
        //     }
        // }

        this.setState({ htmlFileOriginalContent: respData }, () => {
          let copyText = this.state.htmlFileOriginalContent;
          this.xWithRef(copyText, 1);
        });
        this.getpagedata(this.state.selectedFileDetails)
          .then((res) => !isIframe && this.autoHighlight())
          .then((re) => this.setState({ isFetchFileApiHitComplete: true }))
          .then(() => {
            // if (document.getElementById('mainboxdiv') && document.getElementById('mainnavbar')) {
            //     let mainnvabarHeight = document.getElementById('mainnavbar').scrollHeight
            //     let o = document.getElementById('mainboxdiv').clientHeight
            //     console.log(mainnvabarHeight, o)
            //     document.getElementById("mainboxdiv").style.height = `calc(100% - 200px)`
            // }
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  }
  removeBookmark() {
    this.setState({ isBookmarkAddComplete: false });
    let obj = {
      query: `[dbo].[Lawcrux_Web_Proc_RemoveBookmark_Submit] @UserID='{0}', @filepath='{1}'`,
      queryArr: [
        `${this.state.selectedFileDetails.userid}`,
        `${this.state.selectedFileDetails.filepath}`,
      ],
    };
    Hexapi(obj)
      .then((resp) => {
        console.log(resp);
        if (resp[""][0].command == 1) {
          this.setState({ isBookmarkAdded: false });
        }
      })
      .then((re) => this.setState({ isBookmarkAddComplete: true }));
  }
  addBookmark() {
    console.log(this.state.selectedFileDetails);
    this.setState({ isBookmarkAddComplete: false });
    let obj = {
      query: `[dbo].[Lawcrux_Web_Proc_AddBookmark_Submit] @UserID='{0}', @filepath='{1}',@Description='{2}'`,
      queryArr: [
        `${this.state.selectedFileDetails.userid}`,
        `${this.state.selectedFileDetails.filepath}`,
        `${this.state.selectedFileDetails.description}`,
      ],
    };
    Hexapi(obj)
      .then((resp) => {
        console.log(resp);
        if (resp[""][0].command == 1) {
          this.setState({ isBookmarkAdded: true });
        }
      })
      .then((re) => this.setState({ isBookmarkAddComplete: true }));
  }
  openHistoryFile(fileData) {
    console.log(fileData);
    const obj = {
      filepath: fileData.filepath,
      description: fileData.description,
      userid: fileData.userid,
    };
    console.log(obj);
    this.setState({
      search: "",
      totalFilesCount: 0,
      allFiles: [],
      isFirstSearchComplete: true,
      isApiHitComplete: true,
      isDataFound: true,
      isOpenHistoryModal: false,
      isOpenBookmarkModal: false,
      isHistoryOpen: true,
    });
    this.createIframe(obj);
  }
  getVerificationStatus(verified) {
    if (verified) {
      const printDiv = () => {
        var divContents = document.getElementById("SinglePageIframe").innerHTML;
        console.log(divContents);
        divContents = divContents.replaceAll("href", "data-href");
        var style =
          '<div style= "position: absolute;top: 0px;left : 0px ;right: 440px;bottom: 600px;background-image: url(lawcrux.png); background-repeat: no-repeat; background-positon: center;background-size: 164px 67px;  width: 159.875 ;height: 72.111; "></div>';
        style +=
          '<div style= "position: absolute;top: 9px;left : 160px ;right: 0px;bottom: 600px; width: 188.875 ;height: 56.111;border-left: 1px solid #000; "></div>';

        style +=
          '<div style= "position: absolute;top: 9px;left : 170px ;right: 0px;bottom: 600px; width: 188.875 ;height: 87.111; ">Smartbook <br> For <br> Indirect Tax</div>';
        style +=
          '<div style="position: absolute; top: 6px; right: 17px; padding: 0px; color: white; background-color: white; color: black ;font-size: 15px ;border-bottom: 1px solid black;word-spacing: 0.5rem;padding-bottom: 0.2rem;height: 18.313 ; width: 178.698; text-align: center;">www.lawcrux.com</div>';
        style += "</div>";
        style +=
          '<div style="position: absolute; top: 32px; right: 16px; padding: 0px; color: white; background-color: white; color: black ;font-size: 9px ;word-spacing: 0.3rem">• GST • Custom • FTP • FEMA • SEZ</div>';
        style += "</div>";
        style +=
          '<div style="position: absolute; top: 47px; right: 16px; padding: 0px; color: white; background-color: white; color: black ;font-size: 9px ;word-spacing: 0.2rem">• Central Excise • Service Tax • Allied Acts </div>';
        style += "</div>";
        var a = window.open("", "", "height=500, width=500");
        a.document.write(style);
        a.document.write("<html>");
        a.document.write("<body>");
        a.document.write("<br>");
        a.document.write("<br>");
        a.document.write("<br>");
        a.document.write(
          '<hr style="border: none; border-top: 1px solid black; font-weight: bold;">'
        );
        a.document.write("</body></html>");
        a.document.write("<html>");
        a.document.write(divContents);
        // var divContents = this.state.htmlFileOriginalContent
        // var a = window.open('', '', 'height=500, width=500');
        // a.document.write('<html>');
        // // a.document.write('<body > <h1>Div contents are <br>');
        // a.document.write(divContents);
        // a.document.write('</body></html>');
        setTimeout(() => {
          a.document.close();
          a.print();
        }, 1000);
      };
      printDiv();
    }
  }

  setLawAsOnDate(date) {
    console.log(date);

    this.setState({ isOpenLawAsOnDateModal: false });
    date = date.split("-");
    let user_selected_date = new Date(date[1] + "/" + date[1] + "/" + date[0]);
    let showing_content = this.state.htmlFileShowContentBackup;

    const checkDates = (string) => {
      let updatedString = string;
      let lastIndex = 0;
      while (true) {
        let xstart_index = updatedString.indexOf("<!--zisqxyx", lastIndex);
        let xend_index = updatedString.indexOf("<!--zieqxyx", lastIndex);
        let dateintext = updatedString.substring(
          xstart_index + 11,
          xstart_index + 19
        );
        console.log(xstart_index, xend_index, lastIndex);

        if (xstart_index < 0 || xstart_index > xend_index) {
          break;
        }

        let mentioned_date = new Date(
          `${dateintext.substring(2, 4)}/${dateintext.substring(
            0,
            2
          )}/${dateintext.substring(4)}`
        );
        const diffTime = mentioned_date - user_selected_date;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays > 0) {
          /// we have to replace the text
          // add replace logic in updatedstring
          let middle_part = updatedString.substring(
            xstart_index,
            xend_index + 22
          );
          console.log("================middle====================");
          console.log(middle_part);
          console.log("================middle _END====================");
          updatedString = updatedString.replace(middle_part, " ");
        } else {
          lastIndex = xend_index + 1;
        }
      }

      lastIndex = 0;
      while (true) {
        let xstart_index = updatedString.indexOf("<!--zosqxyx", lastIndex);
        let xend_index = updatedString.indexOf("<!--zoeqxyx", lastIndex);
        let dateintext = updatedString.substring(
          xstart_index + 11,
          xstart_index + 19
        );

        if (xstart_index < 0 || xstart_index > xend_index) {
          break;
        }
        console.log(xstart_index, xend_index);

        let mentioned_date = new Date(
          `${dateintext.substring(2, 4)}/${dateintext.substring(
            0,
            2
          )}/${dateintext.substring(4)}`
        );
        const diffTime = mentioned_date - user_selected_date;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays > 0) {
          lastIndex = xend_index + 1;
        } else {
          /// we have to replace the text
          // add replace logic in updatedstring
          let middle_part = updatedString.substring(
            xstart_index,
            xend_index + 22
          );
          updatedString = updatedString.replace(middle_part, " ");

          console.log("================middle====================");
          console.log(middle_part);
          console.log("================middle _END====================");
        }
      }

      for (let i = 0; i < 200; i++) {
        try {
          let found = "N";
          if (updatedString.includes('<a href="#' + i + '">')) {
            found = "Y";
          }
          if (updatedString.includes('<a HREF="#' + i + '">')) {
            found = "Y";
          }
          if (updatedString.includes('<A HREF="#' + i + "'\">")) {
            found = "Y";
          }

          if (found == "N") {
            let xsearch = `<a name=${i}`,
              start_index = updatedString.indexOf(xsearch);

            if (start_index < 0) {
              xsearch = `<a name="${i}"`;
              start_index = updatedString.indexOf(xsearch);
            }

            let xsearch_next = `<a name=${i + 1}`,
              xend_index = updatedString.indexOf(xsearch_next);

            if (xend_index < 0) {
              xsearch_next = `<a name="${i + 1}"`;
              xend_index = updatedString.indexOf(xsearch_next);
            }

            if (start_index > 1 && xend_index > start_index + 4) {
              let middle_part = updatedString.substring(
                start_index,
                xend_index
              );
              updatedString = updatedString.replace(middle_part, " ");
              // xfirst_part = xbalance_content.Substring(0, xstart_index);
              // xsecond_part = xbalance_content.Substring(xend_index, xbalance_content.Length - xend_index);
              // xcut_content = xbalance_content.Substring(xstart_index, xend_index - xstart_index);
              // xbalance_content = xfirst_part + " " + xsecond_part;
            }

            if (start_index > 1 && xend_index < 0) {
              let xfirst_part = updatedString.substring(0, start_index);
              updatedString = xfirst_part + "</body> ";
            }
          }
        } catch {
          console.error("some error in law as on date");
        }
      }

      let newText = updatedString
        .replaceAll("�", '"')
        .replaceAll("[helldod old", "")
        .replaceAll("[helldod", "")
        .replaceAll("helldod]", "")
        .replaceAll("<sup>old</sup>", "");
      return newText;
    };
    let newText = checkDates(showing_content);

    this.setState({
      htmlFileShowContent: newText,
      // htmlFileShowContentBackup: newText
    });
  }

  // rerender=()=>{
  //     this.setState({advorcl:false,notificationtype:false,circulartype:false})
  // }

  renderer = ({ hours, minutes, seconds, completed }) => {
    console.log(seconds, this.state.skipCount);
    if (completed) {
      if (this.state.isOnline) {
        // Render a completed state
        return (
          <>
            <span>Server is busy...Please wait</span>;
            <br />
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() =>
                this.setState({ isRenderAgaing: true }, () =>
                  this.searchKeyword(this.state.search, this.state.searchPath)
                )
              }
            >
              Retry
            </span>
            &nbsp;Or&nbsp;
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() =>
                this.setState({
                  isDataFound: false,
                  isTimerModal: false,
                  firstTimeClick: true,
                  openInitialModal: true,
                  isRefinedSearch: false,
                  targetField: "",
                  targetSubField: "",
                })
              }
            >
              Cancel
            </span>
          </>
        );
        // return <span>Server is busy...Please wait</span>;
        // return <span>Server is busy...Please wait &nbsp;Or&nbsp; <span style={{color:'blue',cursor:"pointer"}} onClick={() => this.setState({isTimerModal:false})}>Exit</span></span>;
      } else {
        return <span>Internet Connetion Lost.</span>;
      }
    } else {
      if (this.state.isOnline) {
        // Render a countdown
        return (
          <span>
            Loading...{`${minutes > 0 ? minutes + "min" : ""}`} {seconds}sec
          </span>
        );
      } else {
        return <span>Internet Connetion Lost.</span>;
      }
    }
  };
  setMainBoxWidth() {
    let mainbox = document.getElementById("mainBoxContent");
    if (this.state.isShowLeftBox == true && this.state.isShowRightBox == true) {
      mainbox.style.minWidth = "calc(100% - 300px)";
    } else if (
      this.state.isShowLeftBox == true &&
      this.state.isShowRightBox == false
    ) {
      mainbox.style.minWidth = "calc(100% - 300px)";
    } else if (
      this.state.isShowLeftBox == false &&
      this.state.isShowRightBox == true
    ) {
      mainbox.style.minWidth = "calc(100% - 400px)";
    } else if (
      this.state.isShowLeftBox == false &&
      this.state.isShowRightBox == false
    ) {
      mainbox.style.minWidth = "calc(100%)";
    }
    // mainbox.style.transition = '0.2s all'
  }
  handleStatusChange = () => {
    this.setState({ isOnline: navigator.onLine });
  };
  setLegalDictLinks(words) {
    console.log(words);
    let content = this.state.htmlFileShowContent;
    // console.log(content.indexOf('abet'))
    words.forEach((file) => {
      content = content
        .toLowerCase()
        .replace(
          " " + file.word.toLowerCase() + " ",
          `<a href="${file.filepath.replace(
            "Assets/Data5t/",
            ""
          )}" onclick="return false"> <span style="background-color: #00FF99">${
            file.word
          }</span> </a>`
        );
    });
    this.setState({ htmlFileShowContent: content }, () => {
      let scope = this;
      let filepath = this.state.selectedFileDetails.filepath;
      let mainfolder = filepath.split("/")[2] + "/";
      let subfolder = filepath.split("/")[3] + "/";
      console.table(mainfolder, subfolder);
      !this.state.isHistoryTrackBtn && this.trackHistory(filepath);

      // ========================================================================================================
      // This code used to replace the anchor tag href attribute url
      // And also open then in new tab
      $("#customIframe")
        .find("a")
        .each(function () {
          let href = $(this).attr("href");
          let isJsFun = false,
            isSamePageLink = false;
          if (
            href != undefined &&
            href.toString().toLowerCase().includes("javascript:void(0)")
          ) {
            isJsFun = true;
          }
          if (href != undefined && href.includes("#")) {
            isSamePageLink = true;
          }
          let file_n = href;
          // console.log(isJsFun)
          if (href != undefined && !isJsFun) {
            $(this).mousedown(function (e) {
              if (e.button == 2) {
                let r_href = href;
                if (!r_href.includes("gtdifi")) {
                  if (r_href.includes("../../")) {
                    r_href = r_href.replace("../../", "");
                  } else if (r_href.includes("../")) {
                    r_href = r_href.replace("../", mainfolder);
                    subfolder =
                      r_href
                        .split(mainfolder)[1]
                        .substring(
                          0,
                          r_href.split(mainfolder)[1].indexOf("/")
                        ) + "/";
                  } else {
                    r_href = mainfolder + subfolder + r_href;
                  }
                  let temp_url = "Assets/data5t/" + r_href;
                  console.log(mainfolder, subfolder, temp_url);
                  const isPPT = [".pptx", ".ppt"].some((el) =>
                    temp_url.toLowerCase().includes(el)
                  );
                  if (!isPPT) {
                    $(this).attr(
                      "href",
                      sessionStorage.getItem("url") + temp_url
                    );
                  }
                } else {
                  $(this).attr(
                    "href",
                    sessionStorage.getItem("url") + "Assets/data5t/" + r_href
                  );
                }
              }
            });
            $(this).on("click", () => {
              let l_href = href;
              if (!l_href.includes("gtdifi")) {
                if (l_href.includes("../../")) {
                  l_href = l_href.replace("../../", "");
                } else if (l_href.includes("../")) {
                  l_href = l_href.replace("../", mainfolder);
                  subfolder =
                    l_href
                      .split(mainfolder)[1]
                      .substring(0, l_href.split(mainfolder)[1].indexOf("/")) +
                    "/";
                } else {
                  l_href = mainfolder + subfolder + l_href;
                }
                let temp_url = "Assets/data5t/" + l_href;
                console.log(mainfolder, subfolder, temp_url);
                const isPPT = [".pptx", ".ppt"].some((el) =>
                  temp_url.toLowerCase().includes(el)
                );
                // $(this).attr('href', '#')
                if (!isSamePageLink) {
                  $(this).attr("href", "#");
                }
                if (!temp_url.includes("#")) {
                  if (isPPT) {
                    console.log(temp_url);
                    // scope.setState({ isOpenSummaryModal: true, summaryData: { foldername: mainfolder + subfolder, filename: file_n } })
                  } else {
                    // scope.setState({ filepath: temp_url, allEnds: "0", allStarts: '0', startEndArray: [] }, () => {
                    //     anchorTags(temp_url)
                    // })
                    const hist = () => {
                      scope.history = scope.history.filter(
                        (el, i) => i <= scope.currentPagePosition - 1
                      );
                      console.log(scope.history);
                    };

                    scope.state.isHistoryTrackBtn && hist();

                    scope.setState({ isHistoryTrackBtn: false });
                    const obj = {
                      filepath: temp_url,
                      description: scope.state.selectedFileDetails.description,
                      userid: scope.state.selectedFileDetails.userid,
                    };
                    console.log(obj);
                    scope.createIframe(obj);
                  }
                }
              } else {
                const hist = () => {
                  scope.history = scope.history.filter(
                    (el, i) => i <= scope.currentPagePosition - 1
                  );
                  console.log(scope.history);
                };

                scope.state.isHistoryTrackBtn && hist();

                scope.setState({ isHistoryTrackBtn: false });
                const obj = {
                  filepath: "Assets/data5t/" + l_href,
                  description: scope.state.selectedFileDetails.description,
                  userid: scope.state.selectedFileDetails.userid,
                };
                console.log(obj);
                scope.createIframe(obj);
              }
            });
          }
        });
    });
  }

  goForwardsearch = () => {
    // this.setState({ openInitialModal: true })
    console.log(this.state.internalsearch.length);

    if (
      this.state.internalsearch.length > 0 &&
      this.state.internalfilepath.length > 0
    ) {
      var len = this.state.internalsearch.length;
      if (this.state.searchcount < len && this.state.searchcount >= 0) {
        console.log(this.state.searchcount);
        const value =
          this.state.internalsearch[len - this.state.searchcount - 1];
        const path =
          this.state.internalfilepath[len - this.state.searchcount - 1];
        const file =
          this.state.targetfieldarray[len - this.state.searchcount - 1];
        const settypeno =
          this.state.searchtypeValue[len - this.state.searchcount - 1];
        const subfile =
          this.state.targetsubfieldarray[len - this.state.searchcount - 1];
        this.setState(
          {
            search: value,
            searchType: settypeno,
            targetField: file,
            targetSubField: subfile,
            arrowsearch: true,
          },
          () => {
            this.searchKeyword(value, path);
          }
        );
        console.log(value);
      }
    }
  };

  goBackwardsearch = () => {
    // this.setState({ openInitialModal: true })
    console.log(this.state.internalsearch.length);
    if (this.state.internalsearch.length > 0) {
      var len = this.state.internalsearch.length;
      if (this.state.searchcount > 0 && this.state.searchcount < len) {
        const value =
          this.state.internalsearch[len - this.state.searchcount - 1];
        const path =
          this.state.internalfilepath[len - this.state.searchcount - 1];
        const file =
          this.state.targetfieldarray[len - this.state.searchcount - 1];
        const settypeno =
          this.state.searchtypeValue[len - this.state.searchcount - 1];
        const subfile =
          this.state.targetsubfieldarray[len - this.state.searchcount - 1];
        console.log(value);
        this.setState(
          {
            search: value,
            searchType: settypeno,
            targetField: file,
            targetSubField: subfile,
            arrowsearch: true,
          },
          () => {
            this.searchKeyword(value, path);
          }
        );
      }
    }
  };
  getUtilityFile = (obj) => {
    console.log(obj);
    let keys = Object.keys(obj);
    let url = [];
    let mainfolder = "";
    let subfolder = "";
    let filename = "";
    let filepath = "Assets/Data5t/";
    console.log(obj[keys[0]].foldername.substring(0, 2));
    for (let i = 0; i < keys.length; i++) {
      if (obj[keys[i]].filename != "") {
        filename = obj[keys[i]].filename;
        mainfolder = obj[keys[i]].foldername.substring(0, 2);
        subfolder = obj[keys[i]].foldername.substring(2);
        if (filename != "") {
          let path =
            "Assets/Data5t/" + mainfolder + "/" + subfolder + "/" + filename;
          var fd = new FormData();
          fd.append(Aesencryption("filename"), Aesencryption(path));
          axios({
            mode: "cors",
            method: "POST",
            headers: { "Content-Type": "multipart/form-data" },
            url: `${sessionStorage.getItem("url")}fetchfile.php`,
            data: fd,
          })
            .then((resp) => {
              console.log(resp);
              if (resp.data != "") {
                url.push(path);
              } else {
                let path =
                  "Assets/Data5t/" +
                  mainfolder +
                  "/" +
                  subfolder +
                  "/" +
                  filename.replaceAll("_", "z");
                url.push(path);
              }
              // console.log(url.length, url)
            })
            .then(() => {
              this.setState({ utilityFilename: url[0] });
              this.setState({
                utilityMainfolder: mainfolder,
                utilitySubfolder: subfolder,
                // utilityFilename:filename,
                // utilityFilename: filepath + mainfolder + '/' + subfolder + '/' + obj[keys[i]].filename.replaceAll('_', 'z')
              });
              this.setState({ utilityOpen: true });
            });
        }
      }
    }
  };
  openRespectiveScreen = () => {
    console.log(this.state.pageData);
    if (this.state.utilfaq == true) {
      let obj = {};
      obj["faq"] = this.state.pageData["faq"][0];
      console.log(obj);
      this.setState({
        /*isOpenMagicwondFrame: true,*/ header: "FAQ",
        utilfaq: false,
      });
      this.setState({ frameData: obj }, () => {
        this.getUtilityFile(obj);
      });
    } else if (this.state.utilInterpretation == true) {
      let obj = {};
      obj["comentry"] = this.state.pageData["comentry"][0];
      this.setState(
        {
          /*isOpenMagicwondFrame: true,*/ header: "Interpretation",
          frameData: obj,
          utilInterpretation: false,
        },
        () => {
          this.getUtilityFile(obj);
        }
      );
    } else if (this.state.utilIllustration == true) {
      // let obj={description:'testing',filepath:this.state.filepath,userid:sessionStorage.getItem('userid')}
      // this.setState({fileData:obj})
      // this.setState({OpenSearchModal:true});
      let obj = {};
      obj["illustration"] = this.state.pageData["illustration"][0];
      this.setState(
        { header: "Illustration", frameData: obj, utilIllustration: false },
        () => {
          this.getUtilityFile(obj);
        }
      );
    } else if (this.state.utilFlowchar == true) {
      let data = this.state.pageData["ppt"][0];
      // console.log(data)
      if (data.filename != undefined && data.filename.includes(".htm")) {
        let multipleframes = {};
        multipleframes["ppt"] = data;
        this.setState(
          {
            /*isOpenMagicwondFrame: true,*/ header: "ppt",
            frameData: multipleframes,
            utilFlowchar: false,
          },
          () => {
            this.getUtilityFile();
          }
        );
      } else {
        this.setState({
          summaryData: data,
          header: "Flow Chart",
          isOpenSummary: true,
        });
      }
    } else if (this.state.isOpenSummaryModal == true) {
      let data = this.state.pageData["summary"][0];
      if (data.filename != undefined && data.filename.includes(".htm")) {
        let multipleframes = {};
        multipleframes["summary"] = data;
        // if (keys.includes('summary')) {
        //     multipleframes['summary'] = data
        // }
        this.setState(
          {
            header: "summary",
            frameData: multipleframes,
            isOpenSummaryModal: false,
          },
          () => {
            this.getUtilityFile();
          }
        );
      } else {
        this.setState({
          summaryData: data,
          header: "Summary",
          isOpenSummary: true,
        });
      }
    }
  };
  render() {
    // Listen to the online status
    window.addEventListener("online", this.handleStatusChange);
    // Listen to the offline status
    window.addEventListener("offline", this.handleStatusChange);
    // console.log(this.state.isUpdation)
    // console.log(this.state.isOnline)
    // console.log(this.state.pageData)
    // console.log(this.state.htmlFileShowContent)
    return (
      <>
        <div style={{ backgroundColor: "#f8f9fb" }}>
          <div className="navbar" style={{ height: "10px" }}>
            <div className="row leftdiv">
              {/* <div className="col-1">
                                <Tooltip title="Go back">
                                    <IconButton onClick={() => this.props.goBack()}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Tooltip>
                            </div> */}
              <div className="col-1">
                <Tooltip title="Go Home">
                  <IconButton onClick={() => this.props.goHome()}>
                    <HomeIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="col-1">
                <Tooltip title="Go back">
                  {/* <IconButton onClick={() => this.props.goBack()}> */}
                  <IconButton
                    onClick={() => {
                      // if (this.currentPagePosition > 1) {
                      //     this.goBackward_CustomHistory()
                      // } else {
                      this.props.goBack();
                      // }
                    }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="col-1">
                <Tooltip title="Go Forward">
                  {/* <IconButton onClick={() => this.props.goBack()}> */}
                  <IconButton onClick={() => this.goForward_CustomHistory()}>
                    <ArrowForwardIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="col-1">
                <Tooltip title="Search keyword">
                  <IconButton
                    onClick={() =>
                      this.setState({
                        firstTimeClick: false,
                        openInitialModal: true,
                        isRefinedSearch: false,
                        isShowRightBox: false,
                      })
                    }
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
              </div>
              {/* <div className="col-1">
                                <Tooltip title="HSN Search">
                                    <IconButton onClick={() => this.setState({ isOpenHSNSearch: true })}>
                                        <QueryStatsOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </div> */}
              <div className="col-1">
                <Tooltip title="Dictionary">
                  <IconButton
                    onClick={() => this.setState({ isOpenDictModal: true })}
                  >
                    <LibraryBooksIcon />
                  </IconButton>
                </Tooltip>
              </div>
              {/* <div className="col-1">
                                <Tooltip title="History">
                                    <IconButton onClick={() => this.setState({ isOpenHistoryModal: true })}>
                                        <HistoryIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className="col-1">
                                <Tooltip title="Bookmark List">
                                    <IconButton onClick={() => this.setState({ isOpenBookmarkModal: true })}>
                                        <BookmarksIcon />
                                    </IconButton>
                                </Tooltip>
                            </div> */}
              {/* <div className="col-1">
                                <Tooltip title="Print">
                                    <IconButton onClick={() => { this.setState({ Printmodal: true }) }}>
                                        <PrintIcon />
                                    </IconButton>
                                </Tooltip>
                            </div> */}
              {/* {
                                !this.state.isOpenInIframe ?
                                    this.state.showdisplayicons ?
                                        <div className="col-1">
                                            <Tooltip title="Display Features">
                                                <IconButton onClick={() => this.setState({ showdisplayicons: false })}>
                                                    <span style={{ fontSize: "14px", fontWeight: "600" }}><RemoveRedEyeIcon /> </span>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        :
                                        <div className="col-1">
                                            <Tooltip title="Off Display Features">
                                                <IconButton onClick={() => this.setState({ showdisplayicons: true })}>
                                                    <span style={{ fontSize: "14px", fontWeight: "600" }}><VisibilityOffIcon /> </span>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    :
                                    null

                            } */}
              {!this.state.isOpenInIframe &&
              this.state.isFetchFileApiHitComplete ? (
                <>
                  {/* {this.props.MainFolder == "cl" ?
                                            <div className="col-1">
                                                <Tooltip title="Case Status">
                                                    <IconButton onClick={() => this.setState({ isOpenClExclamation: true })}>
                                                        <ReportProblemIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            :
                                            <div className="col-1">
                                                <Tooltip title="Utilities">
                                                    <IconButton onClick={() => this.setState({ isMagicWondOpen: true })}>
                                                        <AutoFixHighIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        } */}
                  {this.state.selectedFileDetails.filepath != undefined &&
                  this.state.selectedFileDetails.filepath.includes(
                    "data5t/gt/"
                  ) ? // <div className="col-1">
                  //     <Tooltip title="Case Status">
                  //         <IconButton onClick={() => this.setState({ isOpenClExclamation: true })}>
                  //             <ReportProblemIcon />
                  //         </IconButton>
                  //     </Tooltip>
                  // </div>
                  null : (
                    <div className="col-1">
                      <Tooltip title="Utilities">
                        <IconButton
                          onClick={() =>
                            this.setState({
                              openutilities: true,
                              showdisplayicons: true,
                            })
                          }
                        >
                          <AutoFixHighIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}

                  {/* <div className="col-1">
                                            <Tooltip title="Utilities">
                                                <IconButton onClick={() => this.setState({ isMagicWondOpen: true })}>
                                                    <AutoFixHighIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div> */}
                  {/* <div className="col-1">
                                            <Tooltip title={this.state.isDarkMode ? "Light Mode" : "Dark Mode"}>
                                                <IconButton onClick={() => this.setState({ isDarkMode: !this.state.isDarkMode }, () => {
                                                    if (!this.state.isDarkMode) {
                                                        document.getElementById('customIframe').innerHTML = document.getElementById('customIframe').innerHTML.replaceAll('background-color: rgb(0, 0, 0);', 'background-color: transparent;')
                                                        this.setState({ highlightColor: 'lightgreen' }, () => {
                                                            this.autoHighlight()
                                                            console.log(this.state.highlightColor)
                                                        })
                                                    } else {
                                                        this.setState({ highlightColor: 'red' }, () => {
                                                            this.autoHighlight()
                                                            console.log(this.state.highlightColor)
                                                        })
                                                    }
                                                })}>
                                                    {
                                                        this.state.isDarkMode ?
                                                            <Brightness4Icon />
                                                            :
                                                            <Brightness4OutlinedIcon />
                                                    }
                                                </IconButton>
                                            </Tooltip>
                                        </div> */}
                </>
              ) : // :
              // <div className="col-1">
              //     <Tooltip title={this.state.isIframeDarkMode ? "Light Mode" : "Dark Mode"}>
              //         <IconButton onClick={() => this.setState({ isIframeDarkMode: !this.state.isIframeDarkMode }, () => {
              //             const iframes = document.querySelectorAll('iframe');
              //             function toggleTheme() {
              //                 for (let i = 0; i < iframes.length; i++) {
              //                     iframes[i].classList.toggle('is-dark');
              //                 }
              //             }
              //             toggleTheme()
              //         })}>
              //             {
              //                 this.state.isIframeDarkMode ?
              //                     <Brightness4Icon />
              //                     :
              //                     <Brightness4OutlinedIcon />
              //             }
              //         </IconButton>
              //     </Tooltip>
              // </div>
              null}

              {/* {
                                !this.state.isOpenInIframe &&
                                this.state.isFetchFileApiHitComplete &&
                                //  (this.props.userid == "1" || this.props.userid == "2") 
                                (sessionStorage.getItem('username').includes('LCX_'))
                                &&
                                <div className="col-1">
                                    <span className='read_with' onClick={() => this.setState({ isOpenInHouseDevelopment: true })}>
                                        In House Development
                                    </span>
                                    <Tooltip title="In House Development">
                                        <IconButton onClick={() => this.setState({ isOpenInHouseDevelopment: true })}>
                                            <span style={{ fontSize: "14px", fontWeight: "600" }}>IHD</span>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            } */}

              {!this.state.isOpenInIframe ? (
                this.state.isFetchFileApiHitComplete ? (
                  <>
                    <div className="col-1">
                      <Tooltip title="Highlight text">
                        <IconButton onClick={() => this.getSelectedText()}>
                          <HighlightIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                    {/* <div className="col-1">
                                                <Tooltip title="Zoom In">
                                                    <IconButton onClick={() => {
                                                        if (this.state.zoomFrame < 6) {
                                                            this.setState({ zoomFrame: this.state.zoomFrame + 0.4 })
                                                        }
                                                    }}>
                                                        <AddIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div className="col-1">
                                                <Tooltip title="Zoom Out">
                                                    <IconButton onClick={() => {
                                                        if (this.state.zoomFrame > 1) {
                                                            this.setState({ zoomFrame: this.state.zoomFrame - 0.4 })
                                                        }
                                                    }}>
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div> */}
                    {this.state.allStarts != "" && this.state.allEnds != "" ? (
                      <div className="col-1">
                        <Tooltip title="Remove Highlight">
                          <IconButton onClick={() => this.removeSelectedText()}>
                            <HighlightOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : null}
                    {/* <div className="col-1">
                                                <Tooltip title="Law as on date">
                                                    <IconButton onClick={() => this.setState({ isOpenLawAsOnDateModal: true })}>
                                                        <CalendarMonthIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div> */}
                    {this.state.isBookmarkAddComplete ? (
                      this.state.isBookmarkAdded ? (
                        <div className="col-1">
                          <Tooltip title="Remove Bookmark">
                            <IconButton onClick={() => this.removeBookmark()}>
                              <BookmarkIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : (
                        <div className="col-1">
                          <Tooltip title="Add Bookmark">
                            <IconButton onClick={() => this.addBookmark()}>
                              <BookmarkBorderRoundedIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )
                    ) : (
                      <div className="col-1">
                        <Spinner variant="dark" animation="border" />
                      </div>
                    )}
                    <div className="col-1">
                      {this.props.mainFolder != "cl" ? (
                        this.state.isShowReadWith ? (
                          <Tooltip title="Read Current Law">
                            <IconButton
                              onClick={() =>
                                this.setState(
                                  {
                                    isShowReadWith: !this.state.isShowReadWith,
                                  },
                                  () =>
                                    this.xWithRef(
                                      this.state.htmlFileOriginalContent,
                                      0
                                    )
                                )
                              }
                            >
                              {/* <AbcIcon /> */}
                              <span
                                style={{ fontSize: "14px", fontWeight: "600" }}
                              >
                                ABC
                              </span>
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Read With Old Law Reference">
                            <IconButton
                              onClick={() =>
                                this.setState(
                                  {
                                    isShowReadWith: !this.state.isShowReadWith,
                                  },
                                  () =>
                                    this.xWithoutRef(
                                      this.state.htmlFileOriginalContent
                                    )
                                )
                              }
                            >
                              {/* <BookmarkBorderRoundedIcon /> */}
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textDecoration: "line-through",
                                }}
                              >
                                ABC
                              </span>
                            </IconButton>
                          </Tooltip>
                        )
                      ) : null}
                    </div>
                  </>
                ) : null
              ) : (
                <></>
              )}
            </div>
          </div>
          {this.state.isOpenCustomFrame == true ? (
            <ContentFrame
              // style={{backgroundImage:'',opacity:'0.2',backgroundRepeat:' no-repeat', backgroundPosition: 'center center',}}
              goBack={() =>
                this.setState({
                  isOpenCustomFrame: false,
                  showHighlightttext: false,
                  isOpenInIframe: true,
                })
              }
              filepath={this.state.targetFile}
              searchApi={this.props.searchApi}
              rootUrl={this.props.rootUrl}
              userid={this.props.userid}
              MainFolder={this.props.mainFolder}
              SubFolder={this.props.subFolder}
              goHome={() => this.props.goHome()}
              onLegalDictClick={(dictWords) =>
                this.setLegalDictLinks(dictWords)
              }
              showHighlightttext={this.state.showHighlightttext}
              searchitem={this.state.search}
              SearchType={this.state.searchType}
            />
          ) : null}
          {this.state.isFirstSearchComplete ? (
            this.state.isApiHitComplete ? (
              this.state.isDataFound ? (
                <div className="Search_contentBox">
                  {!this.state.isHistoryOpen && this.state.isShowLeftBox ? (
                    <div className="leftBox noselect">
                      <div className="totalFileCount">
                        <span>
                          Total Count -{" "}
                          {this.state.totalFilesCount - this.state.skipCount}
                        </span>
                        {
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {this.state.internalsearch.length > 1 ? (
                              <>
                                {/* {
                                                                                this.state.searchcount < this.state.internalsearch.length - 1 ? */}

                                <div className="col-1">
                                  <Tooltip title="Go back">
                                    <IconButton
                                      onClick={() => {
                                        console.log(
                                          typeof this.state.searchcount
                                        );
                                        if (
                                          this.state.searchcount <
                                          this.state.internalsearch.length - 1
                                        ) {
                                          this.setState(
                                            (prevState) => ({
                                              searchcount:
                                                prevState.searchcount + 1,
                                            }),
                                            () => {
                                              console.log(
                                                this.state.searchcount
                                              );
                                              this.goBackwardsearch();
                                              this.setState({
                                                isShowRightBox: false,
                                              });
                                            }
                                          );
                                        }
                                      }}
                                      style={
                                        this.state.searchcount <
                                        this.state.internalsearch.length - 1
                                          ? { color: "black" }
                                          : { color: "#c5c5c5" }
                                      }
                                    >
                                      <ArrowBackIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                                {/* :
                                                                                    null
                                                                            }
                                                                            {
                                                                                this.state.searchcount > 0 && this.state.internalsearch.length >= this.state.searchcount ? */}

                                <div className="col-1">
                                  <Tooltip title="Go Forward">
                                    <IconButton
                                      onClick={() => {
                                        if (
                                          this.state.searchcount > 0 &&
                                          this.state.internalsearch.length >=
                                            this.state.searchcount
                                        ) {
                                          this.setState(
                                            (prevState) => ({
                                              searchcount:
                                                prevState.searchcount - 1,
                                            }),
                                            () => {
                                              console.log(
                                                this.state.searchcount
                                              );
                                              this.goForwardsearch();
                                              this.setState({
                                                isShowRightBox: false,
                                              });
                                            }
                                          );
                                        }
                                      }}
                                      style={
                                        this.state.searchcount > 0 &&
                                        this.state.internalsearch.length >=
                                          this.state.searchcount
                                          ? { color: "black" }
                                          : { color: "#c5c5c5" }
                                      }
                                    >
                                      <ArrowForwardIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                                {/* : null
                                                                            } */}
                              </>
                            ) : null}
                            {
                              // this.state.allData.length > 0 &&
                              <div>
                                <Tooltip title="Refined Search">
                                  <IconButton
                                    onClick={() => {
                                      this.setState({
                                        firstTimeClick: false,
                                        openInitialModal: true,
                                        isRefinedSearch: true,
                                        isShowRightBox: false,
                                        refinedsearch: true,
                                      });
                                      // console.log(this.state.targetField, this.state.targetSubField)
                                    }}
                                  >
                                    <FilterAltIcon />
                                  </IconButton>
                                </Tooltip>
                                {/* <Tooltip title="Collapse Listview">
                                                                            <IconButton onClick={() => {
                                                                                this.setState({ isShowLeftBox: false }, () => this.setMainBoxWidth())
                                                                                // document.getElementById("mainBoxContent").style.transition = '0.5s all'
                                                                                // document.getElementById("mainBoxContent").style.minWidth = 'calc(100% - 300px)'
                                                                            }}>
                                                                                <CloseIcon />
                                                                            </IconButton>
                                                                        </Tooltip> */}
                              </div>
                            }
                          </div>
                        }
                      </div>
                      {!this.state.isHistoryOpen && (
                        // this.state.treeData[0].children.map((data, index) => {
                        //     return (
                        //         <TreeView
                        //             key={data.name}
                        //             aria-label="file system navigator"
                        //             defaultCollapseIcon={<ExpandMoreIcon />}
                        //             defaultExpandIcon={<ChevronRightIcon />}
                        //             sx={{ height: "inherit", flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                        //         >
                        //             {data.children != undefined ? this.setLeftBoxTree(data) : ""}
                        //         </TreeView>
                        //     )
                        // })
                        <TreeView
                          aria-label="file system navigator"
                          defaultCollapseIcon={<ExpandMoreIcon />}
                          defaultExpandIcon={<ChevronRightIcon />}
                          sx={{
                            height: "inherit",
                            flexGrow: 1,
                            maxWidth: 400,
                            overflowY: "auto",
                          }}
                        >
                          {this.setLeftBoxTree(this.state.newTreeData)}
                        </TreeView>
                      )}
                    </div>
                  ) : (
                    !this.state.isHistoryOpen && (
                      <div className="closedSideMenu_left">
                        <Tooltip title="Expand Listview">
                          <IconButton
                            onClick={() => {
                              this.setState({ isShowLeftBox: true }, () =>
                                this.setMainBoxWidth()
                              );
                              // if(this.state.isShowRightBox){
                              //     document.getElementById("mainBoxContent").style.minWidth = 'calc(100% - 300px)'
                              // }else{
                              //     document.getElementById("mainBoxContent").style.minWidth = 'calc(100% - 700px)'
                              // }
                              // document.getElementById("mainBoxContent").style.transition = '0.2s all'
                            }}
                          >
                            <ArrowForwardIcon style={{ color: "#fff" }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )
                  )}

                  <div
                    className="mainBox"
                    id="mainBoxContent"
                    style={{
                      // minWidth: this.state.isHistoryOpen ? '100%' : '100%'
                      overflow: "auto",
                      //  height: this.state.advorcl || this.state.notificationtype == false ? "96.5%" : ""
                    }}
                  >
                    {/* {
                                                this.state.selectedFileDetails.filepath != undefined && this.state.selectedFileDetails.filepath.includes('data5t/cl/') ?
                                                <>
                                                    <CaselawExclamation
                                                        status={this.state.pageData}
                                                        onCloseModal={() => this.setState({ isOpenClExclamation: false })}
                                                        userid={this.props.userid}
                                                        openFrame={(li) => this.setState({ isReferedByCaseStatus: true, fileData: li, isOpenSearchModal: true })}
                                                    />
                                                </>
                                                :null                                                    
                                            } */}
                    {this.state.advorcl == false &&
                    this.state.isUpdation == false ? (
                      this.state.notificationtype == false ? (
                        this.state.circular == false ? (
                          this.state.selectedFile != "" ? (
                            this.state.isFetchFileApiHitComplete ? (
                              this.state.isOpenInIframe ? (
                                this.state.isHistoryOpen &&
                                this.state.isShowRightBox ? (
                                  <></>
                                ) : (
                                  <>
                                    <iframe
                                      onLoad={() => this.onIframeLoad()}
                                      id="originalIframe"
                                      src={`${sessionStorage.getItem("url")}${
                                        this.state.selectedFileDetails.filepath
                                      }`}
                                      frameborder="0"
                                      width={"100%"}
                                      height={"480px"}
                                    ></iframe>
                                  </>
                                )
                              ) : (
                                <>
                                  <div
                                    id="customIframe"
                                    dangerouslySetInnerHTML={{
                                      __html: this.state.htmlFileShowContent,
                                    }}
                                    style={{
                                      background: this.state.isDarkMode
                                        ? "#000"
                                        : "transparent",
                                      color: this.state.isDarkMode
                                        ? "#fff"
                                        : "#000",
                                      zoom: this.state.zoomFrame,
                                    }}
                                  ></div>
                                  {/* {
                                                                                this.state.isMagicWondOpen &&
                                                                                <Magicwond
                                                                                    pageData={this.state.pageData}
                                                                                    onCloseModal={() => this.setState({ isMagicWondOpen: false })}
                                                                                    userid={this.state.selectedFileDetails.userid}
                                                                                    filepath={this.state.selectedFileDetails.filepath}
                                                                                    fetchPageData={() => this.setState({ isMagicWondOpen: false }, () => this.getpagedata(this.state.selectedFileDetails))}
                                                                                    openHistoryFile={(fileData) => this.openHistoryFile(fileData)}
                                                                                    goBack={() => this.props.goHome()}
                                                                                    onLegalDictClick={(dictWords) => this.setLegalDictLinks(dictWords)}
                                                                                />
                                                                            } */}
                                </>
                              )
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                  height: "inherit",
                                }}
                              >
                                <Spinner animation="border" variant="dark" />
                              </div>
                            )
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "inherit",
                              }}
                            >
                              <h5 style={{ color: "var(--norecord)" }}>
                                No file selected
                              </h5>
                            </div>
                          )
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                    {this.state.advorcl == true &&
                    this.state.isUpdation == true ? (
                      <IIADVCL
                        field={this.state.filetype}
                        folderpath={"cl"}
                        codeno={this.state.allcodeno}
                        showHighlightttext={this.state.showHighlightttext}
                        searchitem={this.state.search}
                      />
                    ) : (
                      <></>
                    )}

                    {this.state.notificationtype == true &&
                    this.state.isUpdation == true ? (
                      <Notificationtable
                        field={this.state.notificationfield}
                        folderpath={this.state.foldpath}
                        codeno={this.state.allcodeno}
                        showHighlightttext={this.state.showHighlightttext}
                        searchitem={this.state.search}
                      />
                    ) : (
                      <></>
                    )}

                    {this.state.circular == true &&
                    this.state.isUpdation == true ? (
                      <Circular
                        field={this.state.circulartype}
                        folderpath={this.state.foldpath}
                        codeno={this.state.allcodeno}
                        showHighlightttext={this.state.showHighlightttext}
                        searchitem={this.state.search}
                      />
                    ) : (
                      <></>
                    )}

                    {this.state.advorcl == false ? (
                      this.state.notificationtype == false ? (
                        this.state.circulartype == false ? (
                          !this.state.isHistoryOpen &&
                          this.state.isShowRightBox ? (
                            <div
                              className="rightBox noselect"
                              id="rightBoxFiles"
                            >
                              <div
                                className="toprow"
                                style={{ backgroundColor: "#303531" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "white",
                                      display: "flex",
                                      justifyContent: "center",
                                      marginLeft: "50%",
                                    }}
                                  >
                                    Title
                                  </span>
                                  {/* Total Files - {this.state.tableData.length} */}
                                  {/* {
                                                                                this.state.isSortEnable ?
                                                                                    <Tooltip title="Sort descending">
                                                                                        <IconButton onClick={() => {
                                                                                            this.setState({ isSortEnable: false })
                                                                                            this.state.allFiles.sort((a, b) => {
                                                                                                if (b.title.toLowerCase() > a.title.toLowerCase()) {
                                                                                                    return 1
                                                                                                } else {
                                                                                                    return -1
                                                                                                }
                                                                                            })
                                                                                        }}>
                                                                                            <ArrowDropUpIcon style={{ color: 'white' }} />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    :
                                                                                    <Tooltip title="Sort ascending">
                                                                                        <IconButton onClick={() => {
                                                                                            this.setState({ isSortEnable: true })
                                                                                            this.state.allFiles.sort((a, b) => {
                                                                                                if (a.title.toLowerCase() > b.title.toLowerCase()) {
                                                                                                    return 1
                                                                                                } else {
                                                                                                    return -1
                                                                                                }
                                                                                            })
                                                                                        }}>
                                                                                            <ArrowDropDownIcon style={{ color: 'white' }} />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                            } */}
                                </div>
                                {/* <Tooltip title="Collapse Listview">
                                                                            <IconButton onClick={() => {
                                                                                this.setState({ isShowRightBox: false }, () => this.setMainBoxWidth())
                                                                                // document.getElementById("mainBoxContent").style.transition = '0.5s all'
                                                                                // document.getElementById("mainBoxContent").style.minWidth = 'calc(100% - 300px)'
                                                                            }}>
                                                                                <CloseIcon style={{ color: 'white' }} />
                                                                            </IconButton>
                                                                        </Tooltip> */}
                              </div>
                              <div className="tree">
                                {this.state.isFileTitleFetched ? (
                                  this.state.isFileTitleFound ? (
                                    this.state.allFiles.map((child, index) => {
                                      // console.log(this.state.isSortEnable)
                                      let fileData = {
                                        title: child.title,
                                        filepath: child.path.replace(
                                          "C:/htdocs/Lawcrux/Lawcrux/Web/",
                                          ""
                                        ),
                                        userid: this.props.userid,
                                      };
                                      return (
                                        <>
                                          <div
                                            className="fileRow"
                                            style={{
                                              marginBottom:
                                                index ==
                                                this.state.allFiles.length - 1
                                                  ? "30px"
                                                  : "0px",
                                              backgroundColor:
                                                this.state.selectedFile ==
                                                child.path
                                                  ? "rgba(25, 118, 210, 0.08)"
                                                  : "transparent",
                                              borderBottom:
                                                "1px solid rgba(0,0,0,0.1)",
                                            }}
                                            onClick={() =>
                                              this.storeHistory(fileData)
                                            }
                                          >
                                            <VisibilitySensor
                                              onChange={(isVisible) => {
                                                // console.log(index, isVisible, this.state.allFiles.length - 1)
                                                if (
                                                  index ==
                                                    this.state.allFiles.length -
                                                      1 &&
                                                  isVisible
                                                ) {
                                                  if (!this.state.isLastList) {
                                                    // console.log(this.state.listOfAllFilePaths,this.state.allFiles)
                                                    // console.log(this.state.listOfAllFilePaths.filter((el, i) => i > this.state.allFiles.length - 1 && i < this.state.allFiles.length + 100))
                                                    this.setRightBoxContent(
                                                      this.state.listOfAllFilePaths.filter(
                                                        (el, i) =>
                                                          i >
                                                            this.state.allFiles
                                                              .length -
                                                              1 &&
                                                          i <
                                                            this.state.allFiles
                                                              .length +
                                                              100
                                                      ),
                                                      0
                                                    );
                                                  }
                                                  // console.log('hdsfs')
                                                }
                                                // this.getUserImage(isVisible, false)
                                              }}
                                            >
                                              <div className="fileName">
                                                {child.title}
                                              </div>
                                            </VisibilitySensor>
                                          </div>
                                          {index ==
                                          Object.keys(this.state.allFiles)
                                            .length -
                                            1 ? (
                                            !this.state.isLastList ? (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  width: "100%",
                                                  margin: "30px 0px",
                                                }}
                                              >
                                                <Spinner
                                                  animation="border"
                                                  variant="dark"
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      );
                                    })
                                  ) : (
                                    // <List
                                    //     allFiles={this.state.allFiles}
                                    //     isSortEnable={this.state.isSortEnable}
                                    //     userid={this.props.userid}
                                    //     selectedField={this.state.selectedFile}
                                    //     storeHistory={(fileData) => this.storeHistory(fileData)}
                                    // />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                        height: "inherit",
                                      }}
                                    >
                                      <h5 style={{ color: "var(--norecord)" }}>
                                        No record
                                      </h5>
                                    </div>
                                  )
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                      height: "inherit",
                                    }}
                                  >
                                    <Spinner
                                      animation="border"
                                      variant="dark"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null
                        ) : (
                          // !this.state.isHistoryOpen &&
                          // <div className="closedSideMenu">
                          //     <Tooltip title="Expand Listview">
                          //         <IconButton onClick={() => {
                          //             this.setState({ isShowRightBox: true }, () => this.setMainBoxWidth())
                          //             // document.getElementById("mainBoxContent").style.minWidth = 'calc(100% - 700px)'
                          //             // document.getElementById("mainBoxContent").style.transition = '0.2s all'
                          //         }}>
                          //             <ArrowBackIcon style={{ color: '#fff' }} />
                          //         </IconButton>
                          //     </Tooltip>
                          // </div>
                          <></>
                        )
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "91vh",
                  }}
                >
                  <h5 style={{ color: "var(--norecord)" }}>No Record!</h5>
                </div>
              )
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "91vh",
                }}
              >
                <Spinner animation="border" variant="dark" />
              </div>
            )
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "91vh",
              }}
            >
              <h5 style={{ color: "var(--norecord)" }}>Please search first!</h5>
            </div>
          )}
        </div>
        <Modal
          id={"search_options_modal"}
          size="md"
          centered
          style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
          show={this.state.isOpenInHouseDevelopment}
          onHide={() => this.setState({ isOpenInHouseDevelopment: false })}
        >
          <Modal.Body style={{ padding: "10px" }}>
            <div className="InhouseDevelopment">
              <b>Location:</b>&nbsp;&nbsp;
              <span>{this.state.selectedFileDetails.filepath}</span>
            </div>
          </Modal.Body>
        </Modal>
        {this.state.isOpenHSNSearch && (
          <Hsnsearch
            onCloseModal={() => this.setState({ isOpenHSNSearch: false })}
            userid={this.props.userid}
          />
        )}
        {this.state.isOpenDictModal && (
          <Dictionary
            rootUrl={this.props.rootUrl}
            userid={this.props.userid}
            onCloseModal={() => this.setState({ isOpenDictModal: false })}
          />
        )}
        {this.state.isOpenHistoryModal && (
          <HistoryKeeping
            rootUrl={this.props.rootUrl}
            userid={this.props.userid}
            onCloseModal={() => this.setState({ isOpenHistoryModal: false })}
            openHistoryFile={(fileData) => this.openHistoryFile(fileData)}
          />
        )}
        {this.state.isOpenBookmarkModal && (
          <BookmarkKeeping
            rootUrl={this.props.rootUrl}
            userid={this.props.userid}
            onCloseModal={() => this.setState({ isOpenBookmarkModal: false })}
            openHistoryFile={(fileData) => this.openHistoryFile(fileData)}
          />
        )}
        {/* {
                    this.state.isOpenNotesModal &&
                    <Notes
                        onCloseModal={() => this.setState({ isOpenNotesModal: false })}
                        filedetails={this.state.selectedFileDetails}
                        oldNote={this.state.usernotes}
                        onSaveNote={() => this.setState({ isOpenNotesModal: false }, () => this.getpagedata(this.state.selectedFileDetails))}
                    />
                } */}
        {/* {
                    this.state.isOpenSummaryModal &&
                    <Summary
                        onCloseModal={() => this.setState({ isOpenSummaryModal: false })}
                        summaryData={this.state.summaryData}
                    />
                } */}
        {this.state.isOpenLawAsOnDateModal && (
          <Lawasondate
            onCloseModal={() =>
              this.setState({ isOpenLawAsOnDateModal: false })
            }
            onSubmit={(date) => this.setLawAsOnDate(date)}
          />
        )}
        {this.state.Printmodal && (
          <Captcha
            onCloseModal={() => this.setState({ Printmodal: false })}
            Verification={(val) => this.getVerificationStatus(val)}
          ></Captcha>
        )}
        {/* {
                    this.state.isOpenCompareLaws &&
                    <Comparelaws
                        onCloseModal={() => this.setState({ isOpenCompareLaws: false })}
                    />
                } */}
        <Modal
          size="sm"
          style={{ marginTop: "4%" }}
          show={!this.state.showdisplayicons}
          onHide={() => this.setState({ showdisplayicons: true })}
        >
          <Modal.Body
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            {!this.state.isOpenInIframe &&
            this.state.isFetchFileApiHitComplete ? (
              <div className="col-1">
                <Tooltip
                  title={this.state.isDarkMode ? "Light Mode" : "Dark Mode"}
                >
                  <IconButton
                    onClick={() =>
                      this.setState(
                        { isDarkMode: !this.state.isDarkMode },
                        () => {
                          if (!this.state.isDarkMode) {
                            document.getElementById("customIframe").innerHTML =
                              document
                                .getElementById("customIframe")
                                .innerHTML.replaceAll(
                                  "background-color: rgb(0, 0, 0);",
                                  "background-color: transparent;"
                                );
                            this.setState(
                              { highlightColor: "lightgreen" },
                              () => {
                                this.autoHighlight();
                                console.log(this.state.highlightColor);
                              }
                            );
                          } else {
                            this.setState({ highlightColor: "red" }, () => {
                              this.autoHighlight();
                              console.log(this.state.highlightColor);
                            });
                          }
                        }
                      )
                    }
                  >
                    {this.state.isDarkMode ? (
                      <Brightness4Icon />
                    ) : (
                      <Brightness4OutlinedIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div className="col-1">
                <Tooltip
                  title={
                    this.state.isIframeDarkMode ? "Light Mode" : "Dark Mode"
                  }
                >
                  <IconButton
                    onClick={() =>
                      this.setState(
                        { isIframeDarkMode: !this.state.isIframeDarkMode },
                        () => {
                          const iframes = document.querySelectorAll("iframe");
                          function toggleTheme() {
                            for (let i = 0; i < iframes.length; i++) {
                              iframes[i].classList.toggle("is-dark");
                            }
                          }
                          toggleTheme();
                        }
                      )
                    }
                  >
                    {this.state.isIframeDarkMode ? (
                      <Brightness4Icon />
                    ) : (
                      <Brightness4OutlinedIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
            )}
            {!this.state.isOpenInIframe ? (
              this.state.isFetchFileApiHitComplete ? (
                <>
                  <div className="col-1">
                    <Tooltip title="Zoom In">
                      <IconButton
                        onClick={() => {
                          if (this.state.zoomFrame < 6) {
                            this.setState({
                              zoomFrame: this.state.zoomFrame + 0.4,
                            });
                          }
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className="col-1">
                    <Tooltip title="Zoom Out">
                      <IconButton
                        onClick={() => {
                          if (this.state.zoomFrame > 1) {
                            this.setState({
                              zoomFrame: this.state.zoomFrame - 0.4,
                            });
                          }
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  {/* <div className="col-1">
                                            <Tooltip title="Zoom In">
                                                <IconButton onClick={() => {
                                                    if (this.state.zoomFrame < 6) {
                                                        this.setState({ zoomFrame: this.state.zoomFrame + 0.4 }, () => {
                                                            document.getElementById('SinglePageIframe').style.zoom = this.state.zoomFrame
                                                        })
                                                    }
                                                }}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className="col-1">
                                            <Tooltip title="Zoom Out">
                                                <IconButton onClick={() => {
                                                    if (this.state.zoomFrame > 1) {
                                                        this.setState({ zoomFrame: this.state.zoomFrame - 0.4 }, () => {
                                                            document.getElementById('SinglePageIframe').style.zoom = this.state.zoomFrame
                                                        })
                                                    }
                                                }}>
                                                    <RemoveIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div> */}
                </>
              ) : null
            ) : null}
          </Modal.Body>
        </Modal>
        <Modal
          id={"search_options_modal"}
          scrollable={true}
          size="lg"
          centered
          style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
          animation={true}
          show={this.state.openInitialModal}
          onHide={() =>
            this.setState({ openInitialModal: false, firstTimeClick: true })
          }
          keyboard={false}
          backdrop="static"
        >
          <Modal.Header>
            <div className="header">
              <span>Search</span>
              <IconButton
                onClick={() =>
                  this.state.firstTimeClick
                    ? this.props.goBack()
                    : this.setState({
                        openInitialModal: false,
                        isRefinedSearch: false,
                      })
                }
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "white" }}>
            <div className="modal_search">
              <input
                style={{ marginRight: "10px" }}
                type="text"
                placeholder="Search..."
                value={this.state.search}
                id="todolistsearchbox"
                className="form-control"
                onChange={(e) => this.storeSearch(e)}
                onKeyUp={(e) => this.handleKeyPress(e)}
                autoFocus={true}
              />
              <Tooltip title="Search keyword">
                <IconButton
                  onClick={() => {
                    if (this.state.refinedsearch == true) {
                      console.log("refined");
                      let obj = this.state.internalsearch;
                      obj.push(this.state.search);

                      let path = this.state.internalfilepath;
                      path.push(this.state.searchPath);

                      let mainFile = this.state.targetfieldarray;
                      mainFile.push(this.state.targetField);

                      let fileType = this.state.searchtypeValue;
                      fileType.push(this.state.searchType);

                      let subField = this.state.targetsubfieldarray;
                      subField.push(this.state.targetSubField);

                      setTimeout(() => {
                        this.setState({ internalsearch: obj });
                        this.setState({ internalfilepath: path });
                        this.setState({ targetfieldarray: mainFile });
                        this.setState({ searchtypeValue: fileType });
                        this.setState({
                          targetsubfieldarray: subField,
                          setsearchcount: this.state.setsearchcount + 1,
                        });
                      }, 100);
                      this.setState({ refinedsearch: false });
                    } else {
                      let obj = [];
                      obj.push(this.state.search);

                      let path = [];
                      path.push(this.state.searchPath);

                      let mainFile = [];
                      mainFile.push(this.state.targetField);

                      let fileType = [];
                      fileType.push(this.state.searchType);

                      let subField = [];
                      subField.push(this.state.targetSubField);

                      setTimeout(() => {
                        this.setState({
                          internalsearch: obj,
                          internalfilepath: path,
                          firstsearchresult: [],
                          targetfieldarray: mainFile,
                          searchtypeValue: fileType,
                          targetsubfieldarray: subField,
                          setsearchcount: 1,
                        });
                      }, 100);
                      // this.setState({setsearchcount:1,searchtypeValue:[],targetfieldarray:[],internalfilepath:[],internalsearch:[],targetsubfieldarray:[]})
                    }

                    // console.log(this.state.targetField,this.state.targetSubField,)
                    console.log(this.state.internalsearch);
                    if (
                      this.state.search == "" &&
                      this.state.paramSearchObj == null &&
                      this.state.selectedJudge == "" &&
                      this.state.circNotiObj == null
                    ) {
                      Swal.fire({
                        text: "Please input something to search",
                      });
                    } else {
                      this.setState({ skipCount: 0 });
                      this.searchKeyword(
                        this.state.search,
                        this.state.searchPath
                      );
                      console.log(this.state.searchPath);
                    }
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div className="inputBoxes">
              <div className="topBox">
                <div className="main_rightBox">
                  <Parametericsearch
                    searchCategories={this.state.searchCategories}
                    searchType={this.state.searchType}
                    onRadioChange={(type) =>
                      this.setState({ searchType: type })
                    }
                    userid={this.props.userid}
                    // onSearch={(allfiles, url) => this.onParametricSearch(allfiles, url)}
                    onCloseModal={() =>
                      this.setState({ isOpenParamSearch: false })
                    }
                    onCircNotiChange={(data, type) =>
                      data == null
                        ? this.setState({
                            caseLawAdvObj: null,
                            circNotiObj: data,
                            paramSearchObj: null,
                            selectedJudge: "",
                            selectedType: type,
                          })
                        : this.setState({
                            circNotiObj: data,
                            paramSearchObj: null,
                            selectedType: type,
                          })
                    }
                    onInputsChange={(data) =>
                      data == null
                        ? this.setState({
                            caseLawAdvObj: null,
                            paramSearchObj: data,
                            selectedJudge: "",
                            circNotiObj: null,
                          })
                        : this.setState({
                            paramSearchObj: data,
                            circNotiObj: null,
                          })
                    }
                    onFieldsChange={(obj, normalCl, type, changes) => {
                      // if(changes==false){
                      //     this.setState({}, () => normalCl == "-1" ? this.setState({ caseLawAdvObj: null }) : "")
                      // }  else{
                      this.setState(
                        {
                          searchPath: obj.fullPath,
                          targetField: obj.selectedField,
                          targetSubField: obj.selectedSubField,
                          selectedField: obj?.field,
                          selectedSubField: obj?.subfield,
                          selectedType: type,
                        },
                        () =>
                          normalCl == "-1"
                            ? this.setState({ caseLawAdvObj: null })
                            : ""
                      );

                      // }
                    }}
                    onJudgeChange={(obj, judgeName, type) =>
                      this.setState({
                        selectedJudge: judgeName,
                        caseLawAdvObj: obj,
                        selectedType: type,
                      })
                    }
                    onCaseLawAdvanceRulingChange={(obj, type) =>
                      this.setState({
                        caseLawAdvObj: obj,
                        paramSearchObj: null,
                        circNotiObj: null,
                        selectedJudge: "",
                        selectedType: type,
                      })
                    }
                    // onCircNotiChange={(obj) => this.onCircNotiChange(obj)}
                    // paramSearchObj={this.state.paramSearchObj}
                    // isRefinedSearch={this.state.isRefinedSearch}
                    targetField={this.state.targetField}
                    targetSubField={this.state.targetSubField}
                    searchpath={this.state.searchPath}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          size="md"
          id={"search_options_modal"}
          style={{ top: "4%" }}
          show={this.state.openutilities}
          onHide={() => this.setState({ openutilities: false })}
        >
          <Modal.Body
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <div className="col-1">
              <Tooltip title="FAQ">
                <span>
                  <IconButton
                    disabled={this.state.pageData["faq"] == null ? true : false}
                    onClick={() => {
                      this.setState({ utilfaq: true }, () => {
                        this.openRespectiveScreen();
                        this.setState({ utilityheading: "FAQ" });
                      });
                    }}
                  >
                    <QuestionMarkIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
            <div className="col-1">
              <Tooltip title="Interpretation">
                <span>
                  <IconButton
                    disabled={
                      this.state.pageData["comentry"] == null ? true : false
                    }
                    onClick={() => {
                      this.setState({ utilInterpretation: true }, () => {
                        this.openRespectiveScreen();
                        this.setState({ utilityheading: "Interpretation" });
                      });
                    }}
                  >
                    <PsychologySharpIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
            <div className="col-1">
              <Tooltip title="Summary">
                <IconButton
                  disabled={
                    this.state.pageData["summary"] == null ? true : false
                  }
                  onClick={() => {
                    this.setState({ isOpenSummaryModal: true }, () => {
                      this.openRespectiveScreen();
                      this.setState({ utilityheading: "Summary" });
                    });
                  }}
                >
                  <SummarizeSharpIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div className="col-1">
              <Tooltip title="Illustration">
                <span>
                  <IconButton
                    disabled={
                      this.state.pageData["illustration"] == null ? true : false
                    }
                    onClick={() => {
                      this.setState({ utilIllustration: true }, () => {
                        this.openRespectiveScreen();
                        this.setState({ utilityheading: "Illustration" });
                      });
                      // let obj={description:'testing',filepath:this.state.filepath,userid:sessionStorage.getItem('userid')}
                      // this.setState({fileData:obj})
                      // this.setState({OpenSearchModal:true});
                    }}
                  >
                    <CalculateIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
            <div className="col-1">
              <Tooltip title="Flowchart">
                <span>
                  <IconButton
                    disabled={this.state.pageData["ppt"] == null ? true : false}
                    onClick={() => {
                      this.setState({ utilFlowchar: true }, () => {
                        this.openRespectiveScreen();
                        this.setState({ utilityheading: "Flowchart" });
                      });
                    }}
                  >
                    <AccountTreeSharpIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
            <div className="col-1">
              <Tooltip title="Laws as on date">
                <IconButton
                  onClick={() => {
                    this.setState({ isOpenLawAsOnDateModal: true });
                    this.setState({ utilityheading: "Laws as on date" });
                  }}
                >
                  <CalendarMonthIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Modal.Body>
        </Modal>
        {/* Timer Modal */}
        <Modal
          scrollable={true}
          size="sm"
          centered
          style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
          animation={true}
          show={this.state.isTimerModal}
          onHide={() => this.setState({ isTimerModal: false })}
          keyboard={false}
          backdrop="static"
        >
          <Modal.Body>
            <div
              className="timer"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                minHeight: "15vh",
              }}
            >
              <Spinner animation="border" variant="dark" />
              {/* <span id="loadingTimer">
                                <Countdown
                                    date={Date.now() + 90000}
                                    // intervalDelay={0}
                                    // precision={3}
                                    renderer={(hrs, mins, secs, status) => this.renderer(hrs, mins, secs, status)}
                                />
                            </span> */}
            </div>
          </Modal.Body>
        </Modal>
        {/* {this.state.isTimerModal &&
                    <Hexcountdown 
                        onHide={()=>this.setState({isTimerModal:false})}
                    />} */}
        {this.state.utilityOpen ? (
          <UtilityFrame
            goBack={() => {
              this.setState({ utilityOpen: false });
            }}
            utilityheading={this.state.utilityheading}
            filepath={this.state.utilityFilename}
            searchApi={sessionStorage.getItem("searchapi")}
            rootUrl={sessionStorage.getItem("url")}
            userid={sessionStorage.getItem("userid")}
            MainFolder={this.state.utilityMainfolder}
            SubFolder={this.state.utilitySubfolder}
            goHome={() => {
              this.setState({ utilityOpen: false });
              this.props.goHome();
            }}
            LightMode={this.state.isDarkMode}
            ScreenMode={() => {
              this.setState({ isDarkMode: !this.state.isDarkMode }, () => {
                this.ScreenMode();
              });
            }}
          />
        ) : null}
      </>
    );
  }
}
